// @flow

import React, { useReducer, useEffect, useCallback } from "react";
import * as R from "ramda";

import {
  PromptRules as StyledPrompts,
  PromptsContainer,
  NewRule
} from "./styles";
import Label from "./Label";
import Item from "./Item";
import Icon from "src/icons";
import type { PromptRule, PromptRules as PromptRulesType } from "src/types";

type Props = {
  promptRules: PromptRulesType,
  saveSettings: Function
};

function promptRulesReducer(
  state: Array<PromptRule>,
  action: { type: string, payload?: any }
) {
  const { type, payload } = action;
  switch (type) {
    case "set":
      return [...(payload || [])];
    case "add":
      return [...state, { role: "", ruleId: 1 }];
    case "remove":
      return R.remove(payload || 0, 1, state);
    case "update":
      return [...state, ...(payload || [])];
    case "reset":
      return [];
    default:
      throw new Error("Invalid action dispatched in prompt rules reducer");
  }
}

const PromptRules = ({ promptRules, saveSettings }: Props) => {
  const [state, dispatch] = useReducer(promptRulesReducer, []);

  useEffect(() => {
    const { roles, users } = promptRules || { roles: [], users: [] };
    if (roles.length && roles.length > 0) {
      dispatch({
        type: "update",
        payload: roles
      });
    }
    if (users.length && users.length > 0) {
      dispatch({
        type: "update",
        payload: users
      });
    }
  }, []);

  useEffect(() => {
    const roles = [];
    const users = [];

    R.map(field => {
      if (R.has("role")(field)) {
        roles.push(field);
      } else {
        users.push(field);
      }
      return null;
    }, state);

    const promptRules = { roles, users };
    saveSettings(promptRules);
  }, [state]);

  const updateItem = useCallback(
    (val, pos) => {
      const newState = R.update(pos, val, state);
      dispatch({ type: "set", payload: newState });
    },
    [state]
  );

  return (
    <StyledPrompts id="prompt-rules">
      <PromptsContainer>
        <h4 className="prompt-heading">
          Create rules to prompt users to complete this step. Specified users
          will view these fields inside the conversations.
        </h4>
        {state.length > 0 ? <Label /> : null}
        {state.map((item, id) => (
          <Item
            item={item}
            // eslint-disable-next-line react/no-array-index-key
            key={id}
            itemId={id}
            removeItem={val => dispatch({ type: "remove", payload: val })}
            updateItem={updateItem}
          />
        ))}
        <NewRule onClick={() => dispatch({ type: "add" })}>
          <Icon type="hollowPlus" />
          <div>Create new rule</div>
        </NewRule>
      </PromptsContainer>
    </StyledPrompts>
  );
};

export default PromptRules;
