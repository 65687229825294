// @flow

import React, { useEffect, useState, useCallback, useRef } from "react";
import { connect, useSelector } from "react-redux";

import useBoolean from "src/hooks/useBoolean";
import { Current as StyledCurrent } from "src/styles";
import Tooltip from "src/components/Tooltip";
import Title from "./Title";
import Favourite from "./Favourite";
import UrlCopy from "./UrlCopy";
import ApprovalBar from "./ApprovalBar";
import BreadCrumbs from "src/containers/BreadCrumbs";
import Status from "src/components/chatroom/Status";
import Priority from "src/components/chatroom/Priority";
import Owner from "src/containers/chatroom/Owner";
import Related from "src/containers/chatroom/Related";
import Checklist from "src/components/chatroom/Checklist";
import Files from "src/containers/chatroom/Files";
import DueDate from "src/components/chatroom/DueDate";
import Privacy from "src/components/chatroom/Privacy";
import Members from "src/components/chatroom/Members";
import Revisions from "./Revisions";
import MoreMenu from "./MoreMenu";
import PrivacyModal from "./MoreMenu/Privacy/PrivacyModal";
import appLocation from "src/constants/location";

import { showDock, showSrwDock, setHeaderHeight } from "src/actions/dock";
import {
  getChatroomPrivacy,
  getChatroomMembership,
  isStatusDisabled,
  getIsSrwMobile,
  getCurrentRoomId,
  getChatroomType,
  getChatroomAddress
} from "src/reducers";
import type { AppState, RoomId } from "src/types";

import {
  Header as StyledHeader,
  BreadCrumbWrapper,
  TitleRow,
  ActionIconsWrapper,
  InfoRow,
  PrivacyWrapper,
  PrivacyText
} from "./styles";

type Props = {
  roomId: RoomId,
  _setHeaderHeight: Function,
  privacy: Privacy,
  membership: boolean,
  isSrw?: boolean,
  statusDisabled: boolean,
  currentRef: null | React$ElementRef<StyledCurrent>
};

const Header = ({
  roomId,
  _setHeaderHeight,
  privacy,
  membership,
  isSrw = false,
  statusDisabled,
  currentRef
}: Props) => {
  const [node, setNode] = useState(null);
  const {
    value: isPrivacyOpen,
    setTrue: openPrivacyModal,
    setFalse: closePrivacyModal
  } = useBoolean();
  const moreMenuRef = useRef(null);

  const measuredRef = useCallback(ref => {
    if (ref !== null) {
      setNode(ref);
    }
  }, []);

  useEffect(() => {
    if (node !== null) {
      const { height } = node.getBoundingClientRect();
      if (height) _setHeaderHeight(height);
    }
  }, [node]);

  const isSrwMobile = useSelector(({ app }) => getIsSrwMobile(app));
  const roomType = useSelector(({ app }) => getChatroomType(app, roomId));
  const roomAddress = useSelector(({ app }) => getChatroomAddress(app, roomId));

  if (roomId)
    return (
      <>
        <StyledHeader ref={measuredRef}>
          {!isSrw && (
            <BreadCrumbWrapper>
              <BreadCrumbs roomId={roomId} />
            </BreadCrumbWrapper>
          )}
          <TitleRow>
            <div
              style={{
                display: "flex",
                gap: "0.5rem",
                alignItems: "center"
              }}
            >
              {!isSrwMobile && privacy !== "none" && (
                <Tooltip title="privacy">
                  <PrivacyWrapper
                    active={
                      (privacy === "full" || privacy === "content") &&
                      roomType !== "direct"
                    }
                    location={appLocation.chatroomHeader}
                    onClick={openPrivacyModal}
                    tabIndex={0}
                  >
                    <Privacy roomId={roomId} />
                  </PrivacyWrapper>
                </Tooltip>
              )}
              <PrivacyModal
                roomId={roomId}
                isSrw={isSrw}
                closeModal={closePrivacyModal}
                isOpen={isPrivacyOpen}
              />
              <Title roomId={roomId} />
              {!isSrw && (
                <ActionIconsWrapper>
                  <Favourite roomId={roomId} />
                  {roomType !== "direct" && isSrwMobile && (
                    <UrlCopy roomId={roomId} />
                  )}
                </ActionIconsWrapper>
              )}
            </div>
          </TitleRow>
          {roomType !== "direct" && (
            <InfoRow>
              {!isSrwMobile && (
                <Status roomId={roomId} location={appLocation.chatroomHeader} />
              )}
              {isSrwMobile && (
                <PrivacyWrapper
                  active={
                    (privacy === "full" || privacy === "content") &&
                    roomType !== "direct"
                  }
                >
                  <Privacy roomId={roomId} />
                  <PrivacyText>Private</PrivacyText>
                </PrivacyWrapper>
              )}

              <Owner roomId={roomId} location={appLocation.chatroomHeader} />
              <Members
                roomId={roomId}
                location={appLocation.chatroomHeader}
                address={roomAddress}
              />
              {isSrwMobile && (
                <Checklist
                  roomId={roomId}
                  isSrw={isSrw}
                  showPanel={!(privacy === "content" && !membership)}
                />
              )}

              {roomType !== "direct" && roomType !== "group" ? (
                <DueDate
                  roomId={roomId}
                  location={appLocation.chatroomHeader}
                />
              ) : null}
              {isSrwMobile && (
                <Files
                  roomId={roomId}
                  isSrw={isSrw}
                  showPanel={!(privacy === "content" && !membership)}
                />
              )}

              {isSrwMobile && !isSrw && <Related roomId={roomId} />}
              {roomType === "workflow" && !statusDisabled ? (
                <Priority
                  roomId={roomId}
                  location={appLocation.chatroomHeader}
                />
              ) : null}
              {roomType === "workflow" && isSrwMobile && (
                <Revisions roomId={roomId} />
              )}

              <MoreMenu
                outerRef={moreMenuRef}
                roomId={roomId}
                roomType={roomType}
                isSrwMobile={isSrwMobile}
                isSrw={isSrw}
                currentRef={currentRef}
              />

              {!isSrwMobile && (
                <Checklist
                  roomId={roomId}
                  isSrw={isSrw}
                  showPanel={!(privacy === "content" && !membership)}
                />
              )}
            </InfoRow>
          )}
        </StyledHeader>
        <ApprovalBar roomId={roomId} />
      </>
    );
  return null;
};

const mapStateToProps = (
  { app }: { app: AppState },
  { isSrw }: { isSrw: boolean }
) => {
  const roomId = getCurrentRoomId(app);
  return {
    isSrw,
    roomId,
    privacy: getChatroomPrivacy(app, roomId),
    membership: getChatroomMembership(app, roomId),
    statusDisabled: isStatusDisabled(app, roomId)
  };
};

export default connect(mapStateToProps, {
  _showDock: showDock,
  _showSrwDock: showSrwDock,
  _setHeaderHeight: setHeaderHeight
})(Header);
