// @flow

import React from "react";

type Props = {
  width: number | string,
  height: number | string,
  handleClick?: Function
};

const Decrement = ({ handleClick, width, height }: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={handleClick}
    style={{ cursor: "pointer" }}
  >
    <path
      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM15 11H5V9H15V11Z"
      fill="#DE3618"
    />
  </svg>
);

Decrement.defaultProps = {
  handleClick: () => {},
  width: 20,
  height: 20
};

export default Decrement;
