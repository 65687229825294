// @flow

import React from "react";

type Props = {
  width: number,
  height: number
};

const Download = ({ width, height }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={String(width)}
    height={String(height)}
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      fill="#2A2A2A"
      d="M7.467.5c0-.276.239-.5.533-.5.295 0 .533.224.533.5v11c0 .276-.238.5-.533.5-.294 0-.533-.224-.533-.5V.5z"
    />
    <path
      fill="#2A2A2A"
      d="M12.957 6.146a.559.559 0 0 1 .754 0 .478.478 0 0 1 0 .708l-5.333 5a.559.559 0 0 1-.755 0l-5.333-5a.478.478 0 0 1 0-.708.559.559 0 0 1 .754 0L8 10.793l4.957-4.647zM.533 16C.24 16 0 15.776 0 15.5s.239-.5.533-.5h14.934c.294 0 .533.224.533.5s-.239.5-.533.5H.533z"
    />
  </svg>
);

Download.defaultProps = {
  width: 16,
  height: 16
};

export default Download;
