// @flow

import React from "react";
import { useSelector } from "react-redux";
import Linkify from "react-linkify";
import { Text } from "@chakra-ui/react";
import * as R from "ramda";

import { ValueWrapper } from "./styles";
import {
  Cell,
  EditIconWrapper
} from "src/components/Manage/Workflow/Instances/Table/Row/styles";
import MultipleField from "./MultipleField";
import Icon from "src/icons";
import type { ColumnId } from "src/types";
import { getFieldWidth } from "src/reducers";
import { formatFieldValue } from "src/utils/checklist";

type Props = {
  autoNo: string,
  selected: boolean,
  value: string | number | Array<number | string | null> | null,
  rowIndex: number,
  openChecklistEditModal: Function,
  fieldId: number,
  roomId: string,
  hideEdit?: boolean,
  type: string,
  columnId: ColumnId,
  embeddedIndex?: number,
  fieldSettings?: Object
};

const Field = ({
  autoNo,
  selected,
  value,
  rowIndex,
  openChecklistEditModal,
  fieldId,
  roomId,
  hideEdit,
  type,
  columnId,
  embeddedIndex,
  fieldSettings
}: Props) => {
  const fieldWidth = useSelector(({ app }) => getFieldWidth(app, columnId));
  let fieldValue = R.clone(value);
  if ((value && Array.isArray(value)) || (columnId && columnId.includes("-"))) {
    if (!Array.isArray(value)) {
      fieldValue = [value];
    }
    if (type === "date") {
      fieldValue =
        Array.isArray(fieldValue) &&
        fieldValue.map(item => formatFieldValue(type, item));
    }

    return (
      <MultipleField
        autoNo={autoNo}
        selected={selected}
        value={fieldValue}
        rowIndex={rowIndex}
        openChecklistEditModal={openChecklistEditModal}
        fieldId={fieldId}
        roomId={roomId}
        hideEdit={hideEdit}
        type={type}
        columnId={columnId}
        embeddedIndex={embeddedIndex}
      />
    );
  }

  const formattedValue = formatFieldValue(type, value, fieldSettings);

  return (
    <Cell selected={selected} width={fieldWidth}>
      <ValueWrapper selected={selected}>
        {type === "text" ? (
          <Linkify>
            <Text
              m={0}
              maxWidth="90%"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {formattedValue}
            </Text>
          </Linkify>
        ) : (
          formattedValue
        )}

        {!hideEdit && (
          <EditIconWrapper
            onClick={e => {
              openChecklistEditModal(
                e,
                rowIndex,
                fieldId,
                roomId,
                type === "number" ? value : formattedValue,
                columnId
              );
            }}
          >
            <Icon type="editPencil" />
          </EditIconWrapper>
        )}
      </ValueWrapper>
    </Cell>
  );
};

export default Field;
