// @flow

import React, { PureComponent, Fragment } from "react";
import * as R from "ramda";
import { Editor, EditorState, convertFromRaw } from "draft-js";
import type { ExternalAppMentions } from "src/types";
import {
  MessageText as StyledMessageText,
  MessageContent,
  MessageBody
} from "./styles";
import MessageWithMentions from "./MessageWithMentions";

type Props = {
  message: any,
  isForwarded?: boolean,
  mentions?: ExternalAppMentions
};

// Handling old draftJs
type State = {
  draftJS: boolean
};

// Using PureComponent to avoid re-rendering of the DraftJS component
class MessageText extends PureComponent<Props, State> {
  static defaultProps = {
    isForwarded: false
  };

  state = {
    draftJS: false
  };

  getMessage = (text: any): EditorState => {
    try {
      this.setState({ draftJS: true });
      return EditorState.createWithContent(convertFromRaw(text));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  addSpace = (): any =>
    [...Array(30)].map((val, id) => <Fragment key={id}>&nbsp;</Fragment>);

  preventScrollPropagation = (e: any) => {
    e.stopPropagation();
  };

  render() {
    const { message, isForwarded, mentions } = this.props;
    const { text } = message;
    const { draftJS } = this.state;
    return (
      <StyledMessageText isForwarded={isForwarded}>
        {draftJS || R.type(text) === "Object" ? (
          <>
            <Editor readOnly editorState={this.getMessage(text)} />
            {this.addSpace()}
          </>
        ) : (
          <MessageContent onScroll={this.preventScrollPropagation}>
            <MessageBody>
              <MessageWithMentions
                message={message || ""}
                mentions={mentions}
              />
            </MessageBody>
            {this.addSpace()}
          </MessageContent>
        )}
      </StyledMessageText>
    );
  }
}

export default MessageText;
