// @flow

import React from "react";

import { Checkbox as StyledCheckbox } from "src/styles/input.old";

type Props = {
  id: string,
  checked?: boolean,
  children?: any,
  handleChange?: Function
};

const Checkbox = ({ id, checked, children, handleChange }: Props) => (
  <StyledCheckbox>
    <label htmlFor={id}>
      <input
        type="checkbox"
        onChange={handleChange}
        checked={checked}
        id={id}
      />
      <div />
      <span>{children}</span>
    </label>
  </StyledCheckbox>
);

Checkbox.defaultProps = {
  checked: false,
  children: null,
  handleChange: () => {}
};

export default Checkbox;
