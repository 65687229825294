// @flow

import React from "react";
import { connect } from "react-redux";
import { setChecklistValue } from "src/actions/checklist";
import { getChecklistFormValue, getChecklistFieldDetails } from "src/reducers";
import type { ChecklistValue, RoomId, AppState, FieldId } from "src/types";
import Value from "./ChecklistValue";
import { ChecklistItem as StyledChecklistItem } from "./styles";

type Props = {
  roomId: number,
  formId: number,
  details: Object,
  checklistValue: ChecklistValue,
  id: FieldId,
  handleClose: Function
};

const Field = ({
  formId,
  details,
  roomId,
  id,
  checklistValue,
  handleClose
}: Props) => {
  const type = details ? details.get("type") : "";
  const settings = details ? details.get("settings") : "";

  return (
    <StyledChecklistItem>
      <Value
        settings={settings}
        type={type}
        index={0}
        roomId={roomId}
        selected={false}
        fieldId={id}
        formId={formId}
        value={checklistValue?.val.value}
        handleClose={handleClose}
      />
    </StyledChecklistItem>
  );
};

Field.defaultProps = {
  formId: null
};

const mapStateToProps = (
  { app }: { app: AppState },
  { id, roomId, formId }: { id: FieldId, roomId: RoomId, formId: number }
) => ({
  details: getChecklistFieldDetails(app, `${id}`),
  checklistValue: getChecklistFormValue(app, `${roomId}-${id}-${formId}`)
});

export default connect(mapStateToProps, {
  _setChecklistValue: setChecklistValue
})(Field);
