// @flow

const sentryKey = process.env.SENTRY_KEY || "";
const sentryApp = process.env.SENTRY_APP || "";

const sentryDSN = `https://${sentryKey}@sentry.io/${sentryApp}`;

const techsupportEmail = process.env.TECHNICAL_MAIL || "you@unifize.com";

export default sentryDSN;

export { techsupportEmail };
