// @flow

// This Component auto positions dropdown when it reaches edge of screen

import React, { Component } from "react";
import { findDOMNode } from "react-dom";

import PopOver from "./styles";

type Props = {};

type State = {
  top: ?string,
  bottom: ?string
};

const autoPosition = (
  WrapperComponent: any,
  {
    topOffset,
    bottomOffset,
    leftOffset,
    rightOffset
  }: {
    topOffset: string,
    bottomOffset: string,
    leftOffset?: string,
    rightOffset?: string
  }
) => {
  // eslint-disable-next-line react/display-name
  const Positioner = class extends Component<Props, State> {
    state = {
      top: null,
      bottom: null
    };

    componentDidMount() {
      window.addEventListener("resize", this.setStyles);
      this.setStyles();
    }

    componentWillUnmount() {
      window.removeEventListener("resize", this.setStyles);
    }

    setStyles = () => {
      // eslint-disable-next-line react/no-find-dom-node
      const node = findDOMNode(this);
      if (node && node instanceof Element) {
        const rect = node.getBoundingClientRect();

        if (rect) {
          if (rect.bottom > window.innerHeight) {
            this.setState({
              top: "auto",
              bottom: bottomOffset
            });
          } else {
            this.setState({
              top: topOffset,
              bottom: "auto"
            });
          }
        }
      }
    };

    render() {
      const { top, bottom } = this.state;

      return (
        <PopOver
          top={top}
          bottom={bottom}
          left={leftOffset}
          right={rightOffset}
        >
          <WrapperComponent {...this.props} />
        </PopOver>
      );
    }
  };

  return Positioner;
};
export default autoPosition;
