// @flow

import React from "react";

import * as colors from "src/styles/constants/colors";

type Props = {
  isCurrentUser?: boolean
};

const MessageNotch = ({ isCurrentUser }: Props) => {
  return (
    <svg
      width="22"
      height="13"
      viewBox="0 0 11 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.93811 8C9.97896 7.70881 10 7.41214 10 7.11111V8H9.93811ZM2 0H10V7.11111C10 3.18375 6.41828 0 2 0Z"
          fill={isCurrentUser ? colors.currentUser : colors.brightLight}
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0.5"
          y="0"
          width="10"
          height="10"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="-0.5" dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

MessageNotch.defaultProps = {
  isCurrentUser: false
};

export default MessageNotch;
