// @flow

import React from "react";
import * as R from "ramda";

import { fields } from "src/conditions";
import Select from "src/components/Select";
import RoleDropdown from "./RoleDropdown";
import GroupDropdown from "./GroupDropdown";
import StatusDropdown from "./StatusDropdown";
import ConversationDropdown from "./ConversationDropdown";
import UserDropdown from "./UserDropdown";

import { Flex, FormLabel, Text, Input } from "@chakra-ui/react";
import { styles } from "./styles";

type Props = {
  type: ?string,
  checklistFieldType: ?string,
  selectedFieldType: string,
  value: Array<any>,
  checklistFieldSettings: Object,
  updateCondition: Function,
  blockIndex: number,
  conditionIndex: number
};

const ValueDropdown = ({
  type,
  checklistFieldType,
  selectedFieldType,
  value,
  checklistFieldSettings,
  updateCondition,
  blockIndex,
  conditionIndex
}: Props) => {
  const showUserDropdown =
    (selectedFieldType === fields.owner ||
      selectedFieldType === fields.currentUser ||
      selectedFieldType === "user") &&
    (type === "isAnyOf" || type === "isNoneOf");

  const handleValueChange = option =>
    updateCondition(
      {
        value: R.includes(option, value)
          ? R.reject(R.equals(option), value)
          : R.uniq([...value, option])
      },
      blockIndex,
      conditionIndex,
      false
    );

  const selectAllPicklistOptions = () => {
    updateCondition(
      {
        value: checklistFieldSettings?.options || []
      },
      blockIndex,
      conditionIndex,
      false
    );
  };

  const clearAllPicklistOptions = () => {
    updateCondition(
      {
        value: []
      },
      blockIndex,
      conditionIndex,
      false
    );
  };

  return (
    <>
      {selectedFieldType === fields.currentUser &&
      R.includes("Role", type || "") ? (
        <RoleDropdown handleValueChange={handleValueChange} value={value} />
      ) : null}
      {type === "isPartOf" ? (
        <GroupDropdown handleValueChange={handleValueChange} value={value} />
      ) : null}
      {selectedFieldType === "Status" ? (
        <StatusDropdown handleValueChange={handleValueChange} value={value} />
      ) : null}
      {R.includes(checklistFieldType, [
        "conversation",
        "childConversation",
        "link"
      ]) ? (
        <ConversationDropdown
          handleValueChange={handleValueChange}
          value={value}
          type={checklistFieldSettings.type}
          workflow={checklistFieldSettings.workflow}
        />
      ) : null}
      {showUserDropdown ? (
        <UserDropdown handleValueChange={handleValueChange} value={value} />
      ) : null}
      {checklistFieldType === "number" && (
        <Flex {...styles.valueSelectContainer}>
          <FormLabel {...styles.label}>Value</FormLabel>
          <Input
            h={8}
            type="number"
            value={value}
            onChange={e =>
              updateCondition(
                { value: parseInt(e.target.value) },
                blockIndex,
                conditionIndex,
                false
              )
            }
          />
        </Flex>
      )}
      {checklistFieldType === "select" && (
        <Flex {...styles.valueSelectContainer}>
          <FormLabel {...styles.label}>Value</FormLabel>
          <Select
            value={value}
            placeholder="Value"
            onChange={handleValueChange}
            onSelectAll={selectAllPicklistOptions}
            onClearAll={clearAllPicklistOptions}
            itemPredicate={({ item, inputValue }) =>
              item.props.value.toLowerCase().includes(inputValue.toLowerCase())
            }
            multiple
          >
            {/* // $FlowFixMe */}
            {checklistFieldSettings?.options?.map(option => (
              <Text key={option} value={option} variant="ink400" label={option}>
                {option}
              </Text>
            ))}
          </Select>
        </Flex>
      )}
    </>
  );
};

export default ValueDropdown;
