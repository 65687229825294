// @flow

import { connect } from "react-redux";
import * as R from "ramda";
import onClickOutside from "react-onclickoutside";
import React, { useEffect, useCallback } from "react";

import UniqueValues from "./UniqueValues";
import { setContact, getUniqueContactsValues } from "src/actions/user";
import { Options as StyledOptions, SortItem } from "src/styles/table";

import type { AppState, ContactFilter, UniqueContactsValue } from "src/types";

type Props = {
  column: string,
  filter: ContactFilter,
  uniqueValues: UniqueContactsValue,
  handleClose: Function,
  _setContact: Function,
  _getUniqueContactsValues: Function
};

const Options = ({
  column,
  uniqueValues,
  handleClose,
  filter,
  _setContact,
  _getUniqueContactsValues
}: Props) => {
  Options.handleClickOutside = useCallback((e: any) => {
    const { id } = e.target;
    if (!R.includes("column-", id || "")) {
      handleClose();
    }
  }, []);

  useEffect(() => {
    _getUniqueContactsValues();
  }, [_getUniqueContactsValues]);

  const sortAscending = useCallback(() => {
    const newColumn = R.uniq([
      `${column}:asc`,
      ...R.reject(R.equals(`${column}:desc`), filter.sort || [])
    ]);
    _setContact({
      query: {
        ...filter,
        sort: newColumn
      }
    });
  }, [_setContact, filter, column]);

  const sortDescending = useCallback(() => {
    const newColumn = R.uniq([
      `${column}:desc`,
      ...R.reject(R.equals(`${column}:asc`), filter.sort || [])
    ]);

    _setContact({
      query: {
        ...filter,
        sort: newColumn
      }
    });
  }, [_setContact, filter, column]);

  return (
    <StyledOptions>
      <span>Sort By</span>
      <ul>
        <SortItem onClick={sortAscending}>Ascending</SortItem>
        <SortItem onClick={sortDescending}>Descending</SortItem>
      </ul>
      <UniqueValues column={column} values={uniqueValues[column]} />
    </StyledOptions>
  );
};

const clickOutsideConfig = {
  handleClickOutside: () => Options.handleClickOutside
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  filter: app.users.contactFilter,
  uniqueValues: app.users.uniqueContactsValue
});

export default connect(mapStateToProps, {
  _setContact: setContact,
  _getUniqueContactsValues: getUniqueContactsValues
})(onClickOutside(Options, clickOutsideConfig));
