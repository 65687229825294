// @flow
import React, { useState, useCallback } from "react";
import type { ContentRect } from "react-measure";
import Measure from "react-measure";

import { Grid, VStack, GridItem } from "@chakra-ui/layout";

import type { Breakpoint, RoomId, ChecklistId } from "src/types";
import {
  breakpoints,
  breakpointMaxWidths
} from "src/components/Dock/DockLayout/utils";
import type { ChecklistLayoutFields } from "./utils";
import { generateLayout } from "./utils";

import Field from "../Field";
import Section from "../Section";
import RowItem from "./RowItem";

import * as styles from "src/components/Dock/DockLayout/styles";

type Props = {
  id: ChecklistId,
  roomId: RoomId,
  visibleFields: ChecklistLayoutFields
};

const ChecklistLayout = ({ id, roomId, visibleFields }: Props) => {
  const [breakpoint, setBreakpoint] = useState<Breakpoint>(breakpoints.small);

  const handleResize = useCallback(
    (contentRect: ContentRect) => {
      const width = contentRect.bounds.width + 1;

      if (width >= 700) {
        setBreakpoint(breakpoints.large);
      } else if (width >= 500) {
        setBreakpoint(breakpoints.medium);
      } else {
        setBreakpoint(breakpoints.small);
      }
    },
    [setBreakpoint]
  );
  const layout = generateLayout(visibleFields, breakpoint);

  return (
    <Measure bounds onResize={handleResize}>
      {({ measureRef }) => (
        <VStack ref={measureRef} sx={styles.WideChecklistContainer}>
          <Grid
            templateColumns={
              breakpointMaxWidths[breakpoint] === 1
                ? "100%"
                : `repeat(${breakpointMaxWidths[breakpoint]}, 1fr)`
            }
            gap={"0.5rem"}
            sx={{
              width: "100%"
            }}
          >
            {layout.map(field => {
              const column = {
                minWidth: `${field.w * 200}px`
              };
              return (
                <>
                  {field.startOnNewRow ? (
                    <GridItem
                      key={field.id}
                      colSpan={breakpointMaxWidths[breakpoint]}
                      sx={{ ...column, justifySelf: "stretch" }}
                    >
                      <RowItem breakpoint={breakpoint} fieldW={field.w}>
                        <Field
                          key={field.id}
                          id={field.id}
                          checklistId={id}
                          roomId={roomId}
                        />
                      </RowItem>
                    </GridItem>
                  ) : (
                    <GridItem
                      key={field.id}
                      colSpan={field.w}
                      sx={{ ...column, justifySelf: "stretch" }}
                    >
                      {field.type === "section" ? (
                        <Section
                          key={field.id}
                          id={field.id}
                          checklistId={id}
                          roomId={roomId}
                          layout={field.layout}
                          fields={field.fields}
                          breakpoint={breakpoint}
                          isSectionField={typeof field === "object"}
                        />
                      ) : (
                        <>
                          {field.type !== "subSection" && (
                            <Field
                              key={field.id}
                              id={field.id}
                              checklistId={id}
                              roomId={roomId}
                            />
                          )}
                        </>
                      )}
                    </GridItem>
                  )}
                </>
              );
            })}
          </Grid>
        </VStack>
      )}
    </Measure>
  );
};

export default ChecklistLayout;
