// @flow

import { connect } from "react-redux";
import React from "react";

import RowItem from "./Item";
import Selector from "./Selector";

import ChecklistItem from "src/components/Manage/Workflow/Instances/Table/Row/ChecklistItem";
import { Row as StyledRow } from "../styles";
import { isFormRowSelected } from "src/reducers";

import type { AppState, FormInstance } from "src/types";

type Props = {
  outerRef?: any,
  index: number,
  width: string,
  columnSize: string,
  last: boolean,
  selected: boolean,
  form: FormInstance,
  fields: Array<Object>
};

const Row = ({
  outerRef,
  index,
  width,
  columnSize,
  form,
  last,
  fields,
  selected
}: Props) => {
  return (
    <>
      <StyledRow ref={outerRef} width={width} columnSize={columnSize}>
        <Selector formId={form.formId} index={index} />
        <RowItem
          index={index}
          column={"seqNo"}
          form={form}
          last={last}
          selected={selected}
        />
        <RowItem
          index={index}
          column={"chatroomId"}
          form={form}
          last={last}
          selected={selected}
        />
        <RowItem
          index={index}
          column={"templateId"}
          form={form}
          last={last}
          selected={selected}
        />
        <RowItem
          index={index}
          column={"formId"}
          form={form}
          last={last}
          selected={selected}
        />

        {fields.map(column => {
          const value = form?.[column.id];
          return (
            <ChecklistItem
              key={column.id}
              type={column.type}
              fieldId={column.id}
              label={column.label}
              value={value}
              settings={column.settings}
              index={index}
              roomId={form.chatroomId}
              formId={form.formId}
              selected={selected}
            />
          );
        })}

        <RowItem
          index={index}
          column={"createdBy"}
          form={form}
          last={last}
          selected={selected}
        />
        <RowItem
          index={index}
          column={"createdAt"}
          form={form}
          last={last}
          selected={selected}
        />
      </StyledRow>
    </>
  );
};

Row.defaultProps = {
  outerRef: null
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  selected: isFormRowSelected(app, props.form?.formId)
});

export default connect(mapStateToProps)(Row);
