// @flow

import React from "react";

import StyledRadio from "./styles";

type Props = {
  name?: string,
  checked: boolean,
  id: string,
  children?: any,
  isDisabled?: boolean,
  handleChange: Function
};

const Radio = ({
  name,
  checked,
  id,
  children,
  isDisabled = false,
  handleChange
}: Props) => {
  const onChange = () => {
    if (isDisabled) return;
    handleChange(!checked);
  };
  return (
    <StyledRadio disabled={isDisabled}>
      <label tabIndex="0" role="radio" aria-checked={checked} htmlFor={id}>
        <input
          type="radio"
          id={id}
          name={name}
          onChange={onChange}
          checked={checked}
        />
        <div>
          <span />
        </div>
        <span>{children}</span>
      </label>
    </StyledRadio>
  );
};

Radio.defaultProps = {
  children: [],
  name: ""
};

export default Radio;
