// @flow

import React, { useCallback, useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { useClientDimensions } from "react-client-dimensions";

import { Checklist as StyledChecklist } from "./styles";
import Tooltip from "src/components/Tooltip";

import * as styles from "./styles";
import Icon from "src/icons";
import * as colors from "src/styles/constants/colors";
import { showDock, showSrwDock, hideDock, hideSrwDock } from "src/actions/dock";
import {
  getRecentChats,
  getCurrentRoomId,
  getCurrentUserId,
  getCurrentChecklist,
  getMandatoryFieldCount,
  getIsChecklistPresent,
  getIsSrwMobile
} from "src/reducers";
import { getChatroomChecklistFields } from "src/actions/checklist";

import { Box, Text, Flex } from "@chakra-ui/react";

import { navigatedFromHeader } from "../../../actions/header";

import type { AppState, RoomId, ActiveDockHeader } from "src/types";

type Props = {
  show: Function,
  showPanel: boolean,
  isSrw?: boolean,
  showSrw: Function,
  setNavigatedFromHeader: Function,
  activeDock: ActiveDockHeader,
  isChecklistPresent: boolean
};

const Checklist = ({
  show,
  showPanel,
  isSrw = false,
  showSrw,
  setNavigatedFromHeader,
  activeDock,
  isChecklistPresent
}: Props) => {
  const dispatch = useDispatch();
  const { vw } = useClientDimensions();
  const [preventInitialRender, setPreventInitialRender] = useState(true);
  const [refreshOption, setRefreshOption] = useState(false);
  const room = useSelector(({ app }) => getCurrentRoomId(app));
  const currentUser = useSelector(({ app }) => getCurrentUserId(app));
  const recentChats = useSelector(({ app }) => getRecentChats(app, room));
  const checklists = useSelector(({ app }) => getCurrentChecklist(app));
  const mandatoryFieldCount = useSelector(({ app }) =>
    getMandatoryFieldCount(app)
  );
  const isSrwMobile = useSelector(({ app }) => getIsSrwMobile(app));
  let refreshTimeout;

  // Show Refresh button after 3 minutes of clicking refresh
  const showRefreshButton = () => {
    if (refreshTimeout) {
      clearTimeout(refreshTimeout);
    }

    refreshTimeout = setTimeout(() => {
      setRefreshOption(true);
    }, 180000);
  };

  useEffect(() => {
    // Show Refresh button after 3 minutes of initial checklist load
    activeDock ? showRefreshButton() : setRefreshOption(false);
  }, [activeDock, room]);

  // Checks if last message notification is from checklist actions
  const isLastMessageChecklist =
    recentChats[recentChats.length - 1]?.action === "CHECKLIST_FIELD_FILL";

  const isAuthorAndCurrnentUserSame = isLastMessageChecklist
    ? currentUser === recentChats[recentChats.length - 1]?.author
    : false;

  const data = recentChats[recentChats.length - 1]?.data;

  // shows refresh option if update in checklist ignoring initial load
  useEffect(() => {
    if (
      !preventInitialRender &&
      isLastMessageChecklist &&
      !isAuthorAndCurrnentUserSame
    ) {
      if (!refreshOption) {
        setRefreshOption(true);
        showRefreshButton();
      }
    }
  }, [data]);

  // Prevents refresh option to show on first checklist load
  useEffect(() => {
    setPreventInitialRender(false);
  }, []);

  // Reload checklist on clicking refresh button and hide button
  const reloadChecklist = e => {
    e.stopPropagation();
    checklists.map(el => {
      dispatch(getChatroomChecklistFields(el.id, room));
    });
    setRefreshOption(false);
    showRefreshButton();
  };

  const openOrCloseChecklists = useCallback(
    e => {
      e.stopPropagation();
      if (showPanel) {
        if (!activeDock) {
          if (isSrw) {
            showSrw("checklist");
            setNavigatedFromHeader();
          } else if (activeDock !== "checklist") {
            setNavigatedFromHeader();
            show("checklist");
          }
        } else {
          if (!isSrw) dispatch(hideDock());
          else dispatch(hideSrwDock());
        }
      }
    },
    [showPanel, isSrw, activeDock]
  );

  if (isChecklistPresent) {
    return isSrwMobile ? (
      <Tooltip title="Checklist">
        <StyledChecklist onClick={openOrCloseChecklists} showPanel={showPanel}>
          <Icon type="tasklist" color={colors.active} />
        </StyledChecklist>
      </Tooltip>
    ) : (
      <Box
        sx={
          activeDock
            ? mandatoryFieldCount
              ? styles.Mandatory
              : styles.ChecklistBoxOpen
            : styles.ChecklistBoxClosed
        }
        onClick={openOrCloseChecklists}
        spacing={1}
        data-cy="open-checklist"
      >
        {vw > 1200 && (
          <Flex pr={2} gap={"2px"} alignItems="center">
            <Text sx={styles.ChecklistText} as="span" lineHeight={0}>
              {mandatoryFieldCount ? "Mandatory " : "Checklist"}{" "}
            </Text>
            {mandatoryFieldCount ? (
              <Text
                sx={styles.MandatoryCount}
                as="span"
                data-cy="mandatory-count"
              >
                <Tooltip
                  sx={{ backgroundColor: "red" }}
                  placement="top"
                  title={`${mandatoryFieldCount} Mandatory field remaining`}
                >
                  {mandatoryFieldCount}
                </Tooltip>
              </Text>
            ) : null}
          </Flex>
        )}

        {activeDock && refreshOption && (
          <Tooltip title="Refresh">
            <Box sx={{ ...styles.IconContainer }}>
              <Icon
                type="reload"
                data-cy="reload-checklist"
                onClick={e => reloadChecklist(e)}
              />
            </Box>
          </Tooltip>
        )}
        <Tooltip title="Open Checklist">
          <Box
            sx={{
              ...styles.IconContainer,
              svg: {
                transform: activeDock && "rotate(-180deg)",
                transition: "transform 0.3s ease"
              }
            }}
          >
            <Icon
              type="caretLeftFilled"
              fill={activeDock && "#DEEAEE"}
              pathFill={activeDock && "#919EAB"}
            />
          </Box>
        </Tooltip>
      </Box>
    );
  }

  return null;
};

const mapStateToProps = (
  { app }: { app: AppState },
  { roomId, isSrw }: { roomId: RoomId, isSrw: boolean }
) => {
  return {
    isSrw,
    activeDock: isSrw ? app.activeDock.srw : app.activeDock.main,
    isChecklistPresent: getIsChecklistPresent(app, roomId)
  };
};

export default connect(mapStateToProps, {
  show: showDock,
  showSrw: showSrwDock,
  setNavigatedFromHeader: navigatedFromHeader
})(Checklist);
