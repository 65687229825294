// @flow

import React, { useEffect, useState } from "react";
import * as R from "ramda";
import { connect, useSelector, useDispatch } from "react-redux";
import {
  setWorkflowChanges,
  setWorkflowChangesAndRedirectToChatroom,
  cancelWorkflowChanges,
  hideWorkflowBuilder
} from "src/actions/workflows";
import { updateAppView } from "src/actions";
import type { State, UnifizeChatRoom, ChecklistBuilderField } from "src/types";
import {
  getLastVisitedRoomOfProcess,
  getCurrentChecklistBuilder,
  getChatRoom
} from "src/reducers";
import {
  Tabs,
  TabPanels,
  TabPanel,
  Tab,
  TabList,
  HStack,
  Button
} from "@chakra-ui/react";
import {
  ProcessOverlay,
  tabContainer,
  tabListContainer,
  ProcessBuilderFooter as Footer
} from "./styles";
import Title from "./Title";
import Template from "./Template";
import Status from "./Status";
import Privacy from "./Privacy";
import Advanced from "./Advanced";
import Reminders from "./Reminders";
import Checklist from "./Checklist";
import Deleted from "./Deleted";
import Layout from "./Layout";
import * as styles from "./chakraStyles";
import Tooltip from "src/components/Tooltip";
import { dataStages } from "src/constants";
import SpinnerLoader from "src/components/Loader/SpinnerLoader";

type Props = {
  setWorkflowChanges: Function,
  cancelWorkflowChanges: Function,
  hideWorkflowBuilder: Function,
  defaultTabIndex: number,
  lastVisitedRoom: UnifizeChatRoom | null,
  setWorkflowChangesAndRedirectToChatroom: Function,
  fields: ChecklistBuilderField[]
};

const tabs = [
  {
    label: "Team",
    component: <Template />
  },
  {
    label: "Status",
    component: <Status />
  },
  {
    label: "Checklist",
    component: <Checklist />
  },
  {
    label: "Deleted Fields",
    component: <Deleted />
  },
  {
    label: "Privacy Settings",
    component: <Privacy />
  },
  {
    label: "Advanced Process Settings",
    component: <Advanced />
  },
  {
    label: "Reminders",
    component: <Reminders />
  },
  {
    label: "Layout",
    component: <Layout />
  }
];

const getRoomTitle = (room: UnifizeChatRoom) =>
  `#${String(room.autoNo || room.seqNo)}: ${room.title}`;

const ProcessBuilder = ({
  setWorkflowChanges,
  cancelWorkflowChanges,
  hideWorkflowBuilder,
  defaultTabIndex,
  lastVisitedRoom,
  setWorkflowChangesAndRedirectToChatroom,
  fields
}: Props) => {
  const dispatch = useDispatch();
  const exportChecklistFields = () => {
    const data = JSON.stringify(fields);

    const blob = new Blob([data], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "checklist-fields.json";
    a.click();
  };

  const { title, header, loading } = useSelector(
    store => store.app.workflow.builderDialog
  );

  const [processTitle, setProcessTitle] = useState(title);

  useEffect(() => {
    // Update the 'view' in store once this loads
    dispatch(updateAppView("manage"));
  }, []);

  useEffect(() => {
    // Reset workflow.builderDialog when process builder is unmounted
    return () => hideWorkflowBuilder();
  }, []);

  return (
    <ProcessOverlay>
      {loading === dataStages.fetching ? (
        <SpinnerLoader />
      ) : (
        <>
          <header>
            <Title
              processTitle={processTitle}
              setProcessTitle={setProcessTitle}
            />
          </header>

          <Tabs isLazy sx={styles.tabs} defaultIndex={defaultTabIndex}>
            <TabList sx={tabListContainer}>
              {tabs.map(tab => (
                <Tab key={tab.label} sx={tabContainer}>
                  {tab.label}
                </Tab>
              ))}
              <Tab sx={tabContainer} isDisabled>
                Notification Settings
              </Tab>
            </TabList>

            <TabPanels sx={styles.tabPanels}>
              {tabs.map(tab => (
                <TabPanel key={tab.label} sx={styles.tabPanel}>
                  {tab.component}
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>

          <Footer>
            <HStack spacing={3}>
              <Button variant="uSecondary" onClick={cancelWorkflowChanges}>
                Exit Process Editor
              </Button>

              <Tooltip
                title="Enter a title for this process"
                hide={processTitle}
                placement="right"
                bg="red.600"
              >
                <Button
                  isLoading={loading === dataStages.updating}
                  isDisabled={!processTitle.length}
                  variant="uPrimary"
                  onClick={setWorkflowChanges}
                >
                  {header === "Define New Process Template"
                    ? "Create Process"
                    : "Save Changes"}
                </Button>
              </Tooltip>
            </HStack>

            <HStack>
              <Button variant="transparentLink" onClick={exportChecklistFields}>
                Export fields JSON
              </Button>

              {lastVisitedRoom && (
                <Button
                  variant="transparentLink"
                  onClick={e => {
                    if (e.detail === 1) {
                      setWorkflowChangesAndRedirectToChatroom(
                        R.pick(["address", "templateId"], lastVisitedRoom)
                      );
                    }
                  }}
                >
                  Save and go to {getRoomTitle(lastVisitedRoom)}
                </Button>
              )}
            </HStack>
          </Footer>
        </>
      )}
    </ProcessOverlay>
  );
};

const mapStateToProps = ({ location, app }: State) => {
  const lastVisitedRoomId = !R.isNil(app.workflow.builderDialog.id)
    ? getLastVisitedRoomOfProcess(app, Number(app.workflow.builderDialog.id))
    : null;

  const lastVisitedRoom = lastVisitedRoomId
    ? getChatRoom(app, lastVisitedRoomId)
    : null;

  return {
    defaultTabIndex: Math.max(
      0,
      tabs.findIndex(tab => tab.label === location?.query?.tab)
    ),
    lastVisitedRoom,
    fields: getCurrentChecklistBuilder(app).fields
  };
};

export default connect(mapStateToProps, {
  setWorkflowChanges,
  setWorkflowChangesAndRedirectToChatroom,
  cancelWorkflowChanges,
  hideWorkflowBuilder
})(ProcessBuilder);
