// @flow

import { connect } from "react-redux";
import React, { useCallback, useEffect } from "react";

import RowItem from "./Item";
import Child from "./Child";
import Selector from "./Selector";

import ChecklistItem from "./ChecklistItem";
import { Row as StyledRow } from "./styles";
import { getChildConversations } from "src/actions/chatroom";
import useBoolean from "src/hooks/useBoolean";
import { isRoomPrivateForUser } from "src/utils";
import {
  isProcessRowSelected,
  getWorkflow,
  getProcessFilterId,
  getCurrentUserId,
  getWorkflowInstanceFilter
} from "src/reducers";

import type {
  AppState,
  WorkflowInstances,
  RoomId,
  Workflow,
  UID,
  WorkflowInstanceFilter
} from "src/types";

type Props = {
  outerRef?: any,
  index: number,
  width: string,
  selected: boolean,
  columnSize: string,
  last: boolean,
  numberOfColumns: number,
  process: ?WorkflowInstances,
  processColumns: Array<Object>,
  fields: Array<Object>,
  _getChildConversations: Function,
  roomId: RoomId,
  workflow: Workflow,
  currentUserId: UID,
  row: Object,
  allRevisionsShown: Boolean,
  instanceFilter: WorkflowInstanceFilter
};

const Row = ({
  outerRef,
  index,
  width,
  columnSize,
  process,
  numberOfColumns,
  processColumns,
  _getChildConversations,
  workflow,
  currentUserId,
  row,
  selected,
  allRevisionsShown,
  instanceFilter
}: Props) => {
  const { value: expand, toggleBoolean, setFalse } = useBoolean(false);
  const isSortingApplied =
    instanceFilter && instanceFilter.sort
      ? instanceFilter.sort.length > 0
      : false;

  const toggleExpand = useCallback(() => {
    if (process && !expand) {
      _getChildConversations(process.chatroomId);
    }
    toggleBoolean();
  }, [expand, toggleBoolean, _getChildConversations, process]);

  useEffect(() => {
    if (expand) {
      setFalse();
    }
  }, [isSortingApplied]);

  if (!process) return null;

  const isPrivate = isRoomPrivateForUser({
    uid: currentUserId,
    workflow,
    process
  });

  return (
    <>
      <StyledRow ref={outerRef}>
        <Selector roomId={process.chatroomId} index={index} />
        {row.getVisibleCells().map(cell => {
          return (
            <>
              {parseInt(cell.column.columnDef.fieldId) ? (
                <ChecklistItem
                  selected={selected}
                  key={cell.id}
                  columnId={cell.column.columnDef.accessorKey}
                  fieldId={cell.column.columnDef.fieldId}
                  value={cell.getValue()}
                  settings={cell.column.columnDef.settings}
                  index={index}
                  isPrivate={isPrivate}
                  roomId={row?.original?.chatroomId}
                  process={cell.row.original}
                  allRevisionsShown={allRevisionsShown}
                />
              ) : (
                <RowItem
                  selected={selected}
                  key={cell.id}
                  index={index}
                  column={cell.column.id}
                  process={cell.row.original}
                  expand={false}
                  depth={0}
                  toggleExpand={toggleExpand}
                  isPrivate={isPrivate}
                />
              )}
            </>
          );
        })}
      </StyledRow>
      {expand && (
        <Child
          depth={1}
          width={width}
          parentTitle={process.derivedTitle}
          numberOfColumns={numberOfColumns}
          rowSize={process.childCount}
          parentId={process.chatroomId}
          columnSize={columnSize}
          processColumns={processColumns}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  instanceFilter: getWorkflowInstanceFilter(app),
  selected: isProcessRowSelected(app, props.process?.chatroomId),
  workflow: getWorkflow(app, getProcessFilterId(app) || ""),
  currentUserId: getCurrentUserId(app)
});

Row.defaultProps = {
  outerRef: null
};

export default connect(mapStateToProps, {
  _getChildConversations: getChildConversations
})(Row);
