// @flow

import React from "react";

type Props = {
  handleClick?: Function,
  cursor?: string
};

const CaretLeft = ({ handleClick, cursor }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9"
    height="16"
    fill="none"
    viewBox="0 0 9 16"
    onClick={handleClick}
    style={{ cursor }}
  >
    <path
      fill="#000"
      d="M8.67 14.049c.44.446.44 1.17 0 1.616a1.113 1.113 0 0 1-1.59 0L.33 8.808a1.156 1.156 0 0 1 0-1.616L7.08.335a1.113 1.113 0 0 1 1.59 0c.44.446.44 1.17 0 1.616L2.717 8l5.955 6.049z"
    />
  </svg>
);

CaretLeft.defaultProps = {
  cursor: "",
  handleClick: () => {}
};

export default CaretLeft;
