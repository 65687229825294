// @flow

import React from "react";

import type { UnifizeUser } from "src/types";

import { Section, SectionTitle } from "./styles";
import Creator from "./CreatorItem";

export default function AdHocParticipants({
  users,
  path = ""
}: {
  users: Array<UnifizeUser>,
  path?: string
}) {
  if (!users || users?.length === 0) {
    return null;
  }
  return (
    <Section>
      {path === "cancellers" ? (
        <SectionTitle> Allowed Cancellers</SectionTitle>
      ) : (
        <SectionTitle>Allowed Signatories</SectionTitle>
      )}
      {users.map(user => (
        <Creator
          key={user.uid}
          img={user.photoUrl}
          title={user.displayName || user.email}
        />
      ))}
    </Section>
  );
}
