// @flow

import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { HStack, Text, Input, Portal } from "@chakra-ui/react";

import {
  getChatroomFileCount,
  getRoomFileIds,
  getRoomFiles
} from "src/reducers";

import useBoolean from "src/hooks/useBoolean";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalClose,
  ModalOverlay
} from "src/components/Modal";

import File from "./File";

import AttachmentIcon from "src/icons/Attach";

import * as styles from "./styles";
import { MenuItem } from "../styles";
import type { RoomId } from "src/types";

type Props = {
  roomId: RoomId,
  portalRef: null | React$ElementRef<Portal>,
  closeMoreMenu?: () => void
};

const Files = ({ roomId, portalRef, closeMoreMenu }: Props) => {
  const inputRef = useRef(null);
  const [query, setQuery] = useState("");

  const fileIDs = useSelector(({ app }) => getRoomFileIds(app, roomId));

  const roomFiles = useSelector(({ app }) => getRoomFiles(app));

  const filteredFiles = roomFiles.filter(file => {
    if (query.length < 0 || file === undefined) return true;
    if (file) {
      return (
        file.originalName.toLowerCase().includes(query.toLowerCase()) ||
        file.mimeType.toLowerCase().includes(query.toLowerCase())
      );
    }
  });

  const filteredFileIds = filteredFiles.map((file, index) => {
    if (!file) return fileIDs[index];
    else return file.name;
  });

  const handleSearch = event => {
    setQuery(event.target.value);
  };

  const {
    value: isOpen,
    setTrue: openModal,
    setFalse: closeModal
  } = useBoolean();

  const filesCount = useSelector(({ app }) =>
    getChatroomFileCount(app, roomId)
  );

  return (
    <>
      {filesCount > 0 && (
        <HStack
          onClick={() => {
            openModal();
            closeMoreMenu && closeMoreMenu();
          }}
          sx={MenuItem}
          tabIndex={0}
          onKeyPress={e => {
            e.stopPropagation();
            if (e.key === "Enter") {
              openModal();
              closeMoreMenu && closeMoreMenu();
            }
          }}
        >
          <AttachmentIcon width="1rem" height="1rem" />
          <Text>Files ({filesCount})</Text>
        </HStack>
      )}
      <ModalOverlay
        isOpen={isOpen}
        closeModal={closeModal}
        portalRef={portalRef}
      >
        <Modal isOpen={isOpen}>
          <ModalHeader>
            <Text as="span">Files</Text>
            <ModalClose closeModal={closeModal} />
          </ModalHeader>

          <ModalBody>
            <Input
              sx={styles.InputField}
              ref={inputRef}
              variant="flushed"
              size="sm"
              value={query}
              onChange={e => handleSearch(e)}
              placeholder="Search"
              autoFocus={isOpen ? true : false}
            />
            {filteredFileIds.length > 0 ? (
              filteredFileIds.map(fileId => (
                <File roomId={roomId} fileId={fileId} key={fileId} />
              ))
            ) : (
              <Text as="span" fontSize="xs" color="red">
                No results for {query}
              </Text>
            )}
          </ModalBody>
        </Modal>
      </ModalOverlay>
    </>
  );
};

export default Files;
