// @flow

import React from "react";
import { connect, useSelector } from "react-redux";

import BulkChange from "./BulkChange";
import { DueDate as StyledDueDate } from "./styles";
import ChatroomDueDate from "./Single";
import { ValueWrapper } from "../ChecklistItem/styles";
import { isStatusDisabled, getFieldWidth } from "src/reducers";

import type { AppState, RoomId, ColumnId } from "src/types";

type Props = {
  id: RoomId,
  selected?: boolean,
  hide?: boolean,
  index: number,
  value: ?string,
  active: ?boolean,
  depth: number,
  parentId: ?number,
  statusDisabled: boolean,
  columnId?: ColumnId,
  embedded?: boolean
};

const DueDate = ({
  id,
  selected,
  index,
  value,
  hide,
  active,
  depth,
  parentId,
  statusDisabled,
  columnId,
  embedded = false
}: Props) => {
  const fieldId = columnId ? `${columnId}-dueDate` : "dueDate";
  const fieldWidth = useSelector(({ app }) => getFieldWidth(app, fieldId));
  // Max width allowed to the icon and label for due date field
  const labelWidth = parseInt(fieldWidth.split("px")[0], 10) - 16 + "px";

  return (
    <StyledDueDate selected={selected} width={fieldWidth} embedded={embedded}>
      <ValueWrapper showDropdown={true}>
        {hide || statusDisabled ? (
          <i
            style={{
              maxWidth: fieldWidth,
              overflowX: "hidden"
            }}
          >
            {embedded ? "" : "Disabled"}
          </i>
        ) : (
          <ChatroomDueDate
            roomId={`${id}`}
            label={false}
            value={value}
            index={index}
            active={active}
            depth={depth}
            parentId={parentId}
            columnId={columnId}
            labelWidth={labelWidth}
          />
        )}
        {selected ? <BulkChange /> : null}
      </ValueWrapper>
    </StyledDueDate>
  );
};

DueDate.defaultProps = {
  selected: false,
  hide: false
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  statusDisabled: isStatusDisabled(app, props.id)
});

export default connect(mapStateToProps)(DueDate);
