/* eslint-disable react/no-unescaped-entities */
// @flow

import React, { Component } from "react";
import Raven from "raven-js";
import type { Node } from "react";
import sentryDSN, { techsupportEmail } from "src/config/sentry";
import segmentApiKey from "src/config/segment";
import localForage from "localforage";
import { sendCrashReport } from "src/api/crash";

import { Button } from "src/styles/buttons";
import { AppCrash, CrashDetails } from "src/styles";

type State = {
  hasError: boolean
};

type Props = {
  children: Node
};

const objAgent = navigator.userAgent;
let browserName = navigator.appName;
const vendor = navigator.vendor;
let versionNumber = "" + parseFloat(navigator.appVersion);
let offSetVersion;

const isInternetExplorer =
  objAgent.indexOf("MSIE") !== -1 || objAgent.indexOf("Trident/") !== -1;
const isFirefox = objAgent.indexOf("Firefox") !== -1;
const isChrome = objAgent.indexOf("Chrome") !== -1 && vendor === "Google Inc.";
const isSafari =
  objAgent.indexOf("Safari") !== -1 && vendor === "Apple Computer, Inc.";
const isEdge = objAgent.indexOf("Edge") !== -1;
const isOpera = objAgent.indexOf("OP") !== -1;

// In Chrome
if (isChrome) {
  offSetVersion = objAgent.indexOf("Chrome");
  browserName = "Chrome";
  versionNumber = objAgent.substring(offSetVersion + 7).slice(0, 4);
}

// In Firefox
if (isFirefox) {
  offSetVersion = objAgent.indexOf("Firefox");
  browserName = "Firefox";
  versionNumber = objAgent.substring(offSetVersion + 8).slice(0, 4);
}

// In Internet Explorer
if (isInternetExplorer) {
  offSetVersion = objAgent.indexOf("MSIE");
  browserName = "Microsoft Internet Explorer";
  versionNumber = "";
}

// In Safari
if (isSafari) {
  offSetVersion = objAgent.indexOf("Safari");
  browserName = "Safari";
  versionNumber = objAgent.substring(offSetVersion + 7).slice(0, 4);
}

// In Edge
if (isEdge) {
  offSetVersion = objAgent.indexOf("Edge") || objAgent.indexOf("Trident/");
  browserName = "Edge";
  versionNumber = objAgent.substring(offSetVersion + 5).slice(0, 4);
}

// In Opera
if (isOpera) {
  offSetVersion = objAgent.indexOf("OP");
  browserName = "Opera";
  versionNumber = objAgent.substring(offSetVersion + 4).slice(0, 4);
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidMount() {
    // Initialize 3rd party app here like segment, sentry

    // Initialize raven to send error to sentry
    if (process.env.NODE_ENV === "production") {
      try {
        Raven.config(sentryDSN, {
          release: process.env.VERSION
        }).install();
      } catch (e) {
        console.log("Error in initializing Sentry");
      }
    }

    // Initialize segement analytics with api key
    try {
      if (segmentApiKey) {
        window.analytics.load(segmentApiKey);
      }
    } catch (error) {
      console.log("Error in initializing segment");
    }
  }

  componentDidCatch(error: any, info: any) {
    // Display fallback UI
    this.setState({ hasError: true });
    if (process.env.NODE_ENV === "production") {
      sendCrashReport({
        message: error.message,
        stack: error.stack
      });
      throw new Error(error);
    }
    window.crashReport = error.message;

    // You can also log the error to an error reporting service
    Raven.captureException(error, { extra: info });
  }

  reloadWindow = async () => {
    try {
      const orgId = sessionStorage.getItem("lastOrg");

      if (orgId) {
        // Clearing indexdb
        await localForage.setItem(`chatrooms-${orgId}`, JSON.stringify([]));
        await localForage.setItem(`lastFetched-${orgId}`, "");
      }
    } catch (error) {
      console.log("unable to clear database");
    }
    window.location.reload();
  };

  showSentryReportDialog(e: any) {
    if (Raven.lastEventId()) {
      e.preventDefault();
      Raven.showReportDialog();
    }
  }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <AppCrash onClick={this.showSentryReportDialog}>
          <p>We're sorry — something's gone wrong.</p>
          <p>
            Our team has been notified, but&nbsp;
            <a
              href={`mailto:${techsupportEmail}`}
              onClick={this.showSentryReportDialog}
              className="text-link"
            >
              let us know
            </a>
            &nbsp;what happened.
          </p>
          <CrashDetails>
            <li>
              <strong>Browser:</strong>{" "}
              <span>{browserName + "  " + versionNumber}</span>
            </li>
            <li>
              <strong>Version:</strong> <span>{process.env.VERSION}</span>
            </li>
            <li>
              <strong>UID:</strong> <span>{window.currentUserUID}</span>
            </li>
            <li>
              <strong>URL:</strong> <span>{window.location.href}</span>
            </li>
            <li>
              <strong>Org:</strong> <span>{window.currentOrgId}</span>
            </li>
          </CrashDetails>
          <Button onClick={this.reloadWindow}>Try again</Button>
          {window.crashReport && (
            <code>{JSON.stringify(window.crashReport)}</code>
          )}
        </AppCrash>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
