// @flow

import React from "react";

import { StyledProcessOwners } from "./styles";
import Icon from "src/icons";
import UserSelectMultiple from "src/containers/user/SelectMultiple";
import { Label } from "src/styles/form";
import type { UID } from "src/types";

type Props = {
  privacy?: string,
  processOwners: Array<UID>,
  handleProcessOwners: Function,
  form?: boolean
};

const ProcessOwners = ({
  privacy,
  processOwners,
  handleProcessOwners,
  form
}: Props) => {
  const icon = privacy === "full" || privacy === "content" ? "lock" : "unlock";

  return (
    <StyledProcessOwners form={form}>
      <Label>
        {form ? "Form owners" : "Process owners"}
        <Icon type={icon} />
      </Label>
      <UserSelectMultiple
        value={processOwners}
        handleChange={handleProcessOwners}
      />
    </StyledProcessOwners>
  );
};

ProcessOwners.defaultProps = {
  privacy: "unlock"
};

export default ProcessOwners;
