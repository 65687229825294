// @flow

import { connect } from "react-redux";
import React, { useCallback } from "react";
import * as R from "ramda";

import Chatroom from "./Chatroom";
import User from "./User";
import Checklist from "./Checklist";
import Date from "./Date";
import Process from "./Process";
import { setForm } from "src/actions/forms";
import { getWorkflowInstanceFilter } from "src/reducers";

import type { AppState, WorkflowInstanceFilter } from "src/types";

type Props = {
  column: string,
  value: any,
  filter: WorkflowInstanceFilter,
  additionalFilters: WorkflowInstanceFilter,
  _setForm: Function
};

const FilterItem = ({
  column,
  value,
  filter,
  additionalFilters,
  _setForm
}: Props) => {
  const clearFilter = useCallback(() => {
    if (R.type(filter[column]) === "String") {
      _setForm({
        query: {
          ...filter,
          ...additionalFilters,
          [column]: null,
          page: 1
        }
      });
    } else {
      const newValue = R.reject(R.equals(value), filter[column]);
      if (newValue) {
        _setForm({
          query: {
            ...filter,
            ...additionalFilters,
            [column]: newValue,
            page: 1
          }
        });
      }
    }
  }, [value, column, filter, _setForm]);

  // eslint-disable-next-line no-restricted-globals
  if (!isNaN(column)) {
    return (
      <Checklist column={column} value={value} handleClose={clearFilter} />
    );
  }

  switch (column) {
    case "createdBy":
      return <User uid={value} column={column} handleClose={clearFilter} />;
    case "chatroomId":
      return <Chatroom id={value} column={column} handleClose={clearFilter} />;
    case "templateId":
      return <Process id={value} column={column} handleClose={clearFilter} />;
    case "createdAt":
      return <Date value={value} column={column} handleClose={clearFilter} />;
    default:
      return <></>;
  }
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  filter: getWorkflowInstanceFilter(app),
  additionalFilters: app.workflow.additionalFilters
});

export default connect(mapStateToProps, {
  _setForm: setForm
})(FilterItem);
