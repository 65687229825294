// @flow

import React from "react";
import { useSelector } from "react-redux";

import type { State } from "src/types";
import { getChatRoom } from "src/reducers";

import { Item as StyledItem } from "./styles";
import Icons from "src/icons";
import Name from "src/containers/chatroom/Name";

type Props = {
  id: number,
  handleSelect: Function,
  selectNewRoomVersions?: Function,
  alwaysShowRevisionIcon: boolean
};

const Item = ({
  id,
  handleSelect,
  selectNewRoomVersions,
  alwaysShowRevisionIcon
}: Props) => {
  const room = useSelector(({ app }: State) => getChatRoom(app, `${id}`));

  return (
    <StyledItem onClick={() => handleSelect(id)}>
      <Name hideProcessTitle id={id} />
      {(alwaysShowRevisionIcon ||
        (room.versionCount > 1 && room.currentVersion)) &&
        selectNewRoomVersions && (
          <Icons
            type="currentRevision"
            width="14px"
            height="14px"
            onClick={e => {
              e.stopPropagation();
              selectNewRoomVersions(id);
            }}
          />
        )}
    </StyledItem>
  );
};
export default Item;
