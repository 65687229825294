// @flow

import styled from "@emotion/styled";

import { ButtonOutline } from "src/styles/buttons";
import * as spacing from "src/styles/constants/spacing";
import * as colors from "src/styles/constants/colors";

const StickyCells = styled.td`
  position: ${props => (props.stickiness ? "static" : "sticky")};
  background: ${colors.brightLight};
  z-index: 3;
`;

const Button = styled(ButtonOutline)`
  margin-left: 0.5em;
  padding: 4px 8px;
  font-size: 9px;
`;

const User = styled(StickyCells)`
  cursor: pointer;
  left: 64px;
  width: 220px;
  box-shadow: 1px 1px 1px 0 ${colors.grey1};
  border-right: ${props =>
    props.stickiness
      ? `1px solid ${colors.grey1}`
      : `1px solid ${colors.brightLight}`};
  :hover {
    > div > svg {
      visibility: visible;
    }
  }
  > div {
    display: flex;
    align-items: center;
    width: 220px;
    > svg {
      position: relative;
      top: -1px;
      margin-left: 0.3em;
      visibility: hidden;
    }
    > span > span {
      max-width: 220px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

const Email = styled.td`
  width: 10vw;
  > span {
    display: inline-block;
    overflow: hidden;
    width: 10vw;
    text-overflow: ellipsis;
  }
`;

const Department = styled.td`
  padding-left: 2em !important;
  width: 8vw;
  > span {
    width: 8vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const PhoneNumber = styled.td`
  width: 11vw;
  > span {
    width: 8vw;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const TableContainer = styled.div`
  width: calc(100vw - 5em);
  height: calc(100vh - 16em);
  overflow: auto;
  position: relative;
  z-index: 0;
  margin-left: -${spacing.space_l};
  font-size: 12px;
`;

const DateTime = styled.td`
  width: 20em;
  white-space: nowrap;
`;

const Groups = styled.td`
  width: 32em;
  > div {
    width: 180px;
    display: flex;
    align-items: center;
  }
`;

const GroupName = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: ${colors.highlight};
  margin: 0 2px;
  border-radius: 2px;
  cursor: pointer;
  > img {
    display: inline-block;
    height: 1.2em;
    width: 1.2em;
    border-radius: 2px;
  }
  > span {
    height: 1.65em;
    padding: 0 4px;
    display: inline-block;
    max-width: 4em;
    color: ${colors.primary};
    font-size: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const Initial = styled.div`
  display: inline-block;
  height: 1.2em;
  width: 1.2em;
  border-radius: 2px;
  background-color: ${props => props.color};
`;

const Selector = styled(StickyCells)`
  width: 56px;
  left: 0px;
  outline: none;
`;

const RoleContainer = styled.div`
  width: 180px;
  display: flex;
  align-items: center;
`;

export {
  Button,
  Selector,
  User,
  Email,
  Department,
  PhoneNumber,
  TableContainer,
  Groups,
  GroupName,
  DateTime,
  RoleContainer
};
