// @flow

import React from "react";

type Props = {
  title: string,
  children?: any
};

const Name = ({ title, children }: Props) => (
  <>
    {title}
    {children}
  </>
);

Name.defaultProps = {
  children: null
};

export default Name;
