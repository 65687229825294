// @flow

import React from "react";

import { Icon as StyledIcon } from "src/styles/box";
import { iconSize } from "src/styles/constants/size";
import * as colors from "src/styles/constants/colors";
import getSvg from "src/utils/svg";

type Props = {
  icon: string,
  size?: string,
  fill?: string,
  background?: string,
  color?: string,
  onClick?: Function
};

const Icon = ({ size, icon, color, background, fill, onClick }: Props) => (
  <StyledIcon
    viewBox="-4 -4 24 24"
    preserveAspectRatio="xMinYMin slice"
    height={size}
    width={size}
    size={size}
    background={background}
    stroke={color}
    fill={fill}
    onClick={onClick}
  >
    {getSvg(icon)}
  </StyledIcon>
);

Icon.defaultProps = {
  size: iconSize.space_l,
  background: "transparent",
  fill: "none",
  color: colors.secondary,
  onClick: () => {}
};

export default Icon;
