// @flow

import React from "react";

import { MessageCard, MessageNotchContainer } from "./styles";
import Icon from "src/icons";

type Props = {
  children: any,
  first: boolean,
  isCurrentUser?: boolean,
  maxWidth?: boolean,
  isChecklist?: boolean,
  singleLine?: boolean
};

const MessageNotch = ({
  children,
  first,
  isCurrentUser,
  maxWidth,
  isChecklist,
  singleLine,
  ...restProps
}: Props) => (
  <MessageCard
    first={first}
    isCurrentUser={isCurrentUser}
    maxWidth={maxWidth}
    isChecklist={isChecklist}
    singleLine={singleLine}
    {...restProps}
  >
    {first && (
      <MessageNotchContainer>
        <Icon type="messageNotch" isCurrentUser={isCurrentUser} />
      </MessageNotchContainer>
    )}
    {children}
  </MessageCard>
);

MessageNotch.defaultProps = {
  maxWidth: false,
  isCurrentUser: false,
  isChecklist: false,
  singleLine: false
};

export default MessageNotch;
