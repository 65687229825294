// @flow

import { connect } from "react-redux";
import React from "react";
import * as R from "ramda";

import { Row } from "../../styles";
import Options from "./Options";
import { Th, THContent } from "src/styles/table";
import Checkbox from "src/components/Checkbox";
import columns from "src/constants/peopleColumns";
import { getPeopleTablesort } from "src/reducers";
import Icon from "src/icons";

import type { AppState } from "src/types";

type Props = {
  sort: Array<string>,
  showSort: string,
  stickiness: boolean,
  toggleSortMenu: Function,
  handleClose: Function
};

const Header = ({
  sort,
  showSort,
  stickiness,
  toggleSortMenu,
  handleClose
}: Props) => {
  return (
    <thead stickiness={stickiness}>
      <Row>
        <Th>
          <Checkbox id="selectAllrows" handleChange={() => {}} />
        </Th>
        {columns.map(column => (
          <Th
            onClick={e => toggleSortMenu(e, column.key)}
            id={`column-${column.key}`}
            key={column.key}
            active={R.includes(column.key, sort)}
          >
            <THContent>
              {column.display}
              <Icon type="triangleDown" />
            </THContent>
            {showSort === column.key ? (
              <Options column={showSort} handleClose={handleClose} />
            ) : null}
          </Th>
        ))}
      </Row>
    </thead>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  sort: getPeopleTablesort(app)
});

export default connect(mapStateToProps)(Header);
