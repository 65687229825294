// @flow

import React from "react";

import * as colors from "src/styles/constants/colors";

type Props = {
  color?: string
};

const UnLock = ({ color }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="12"
    fill="none"
    viewBox="0 0 14 16"
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      d="M12.5 15.5h-11a1 1 0 0 1-1-1v-7a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1zM3.5 6.5V4a3.5 3.5 0 0 1 6.855-1"
    />
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      d="M7 12.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
    />
  </svg>
);

UnLock.defaultProps = {
  color: colors.active
};

export default UnLock;
