/* eslint-disable react/display-name */
// @flow

import * as React from "react";
import { Button, Box } from "@chakra-ui/react";

type Props = {
  onMouseEnter?: ?Function,
  isDisabled?: boolean,
  children: React.Node,
  rootProps: Object
};

// $FlowFixMe
const ChakraButton = React.forwardRef(
  ({ onMouseEnter, children, isDisabled, rootProps, ...props }: Props, ref) => {
    return (
      <Box
        {...rootProps}
        ref={ref}
        onMouseEnter={isDisabled ? onMouseEnter : undefined}
      >
        <Button
          {...props}
          onMouseEnter={isDisabled ? undefined : onMouseEnter}
          isDisabled={isDisabled}
        >
          {children}
        </Button>
      </Box>
    );
  }
);

export default ChakraButton;
