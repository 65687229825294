// @flow

const options = [
  { key: "displayName", display: "Name" },
  { key: "email", display: "Email id" },
  { key: "orgRole", display: "Role" },
  { key: "department", display: "Department" },
  { key: "phoneNumber", display: "Phone number" },
  { key: "group", display: "Groups" },
  { key: "lastLogin", display: "Last logged in" },
  { key: "dateInvited", display: "Date invited" },
  { key: "dateJoined", display: "Date joined" }
];

export default options;
