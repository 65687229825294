// @flow

import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { escape } from "entities";
import { Text } from "@chakra-ui/react";

import { WrappingInput, Ellipsis } from "./styles";
import DirectChat from "src/components/chatroom/Title/DirectChat";
import {
  getChatroomType,
  getChatroomTitle,
  getSequenceNo,
  getTemplateId,
  getChatroomStatus,
  isCurrentVersion
} from "src/reducers";
import { CANCELED } from "src/constants/status";
import WorkflowName from "src/containers/workflow/Name";
import { setAttribute } from "src/actions/chatroom";
import type { AppState, RoomId, ReactRef } from "src/types";

type Props = {
  type: string,
  title: string,
  roomId: RoomId,
  seqNo: number,
  templateId?: ?number,
  _setAttribute: Function,
  value: number,
  isCurrentVersion: boolean
};

const Title = ({
  type,
  templateId,
  seqNo,
  title,
  _setAttribute,
  roomId,
  value,
  isCurrentVersion
}: Props) => {
  const titleRef: ReactRef = useRef();
  const escapeRef: ReactRef = useRef();
  const [focused, setFocused] = useState(false);
  const [chatTitle, setChatTitle] = useState("");
  useEffect(() => {
    setChatTitle(escape(title || ""));
  }, [title]);

  const handleChange = e => {
    setChatTitle(e.target.value);
  };

  const handleBlur = () => {
    const newTitle = titleRef.current.innerText;
    if (escapeRef.current) {
      setChatTitle(title);
      setFocused(false);
      return;
    }
    _setAttribute(roomId, { title: R.trim(newTitle || "") });
    titleRef.current.scrollIntoView(true);
    setFocused(false);
  };

  const handleFocus = () => {
    setFocused(true);
  };

  const focusTitle = () => {
    handleFocus();
    titleRef.current.focus();
  };

  useEffect(() => {
    if (titleRef.current) {
      titleRef.current.addEventListener("focus", handleFocus);
      return () => {
        if (titleRef.current)
          titleRef.current.removeEventListener("focus", handleFocus);
      };
    }
  }, [title]);

  const handleEnter = e => {
    if (e.key === "Escape" || e.key === "Esc" || e.keyCode === 27) {
      escapeRef.current = true;
      e.preventDefault();
      e.stopPropagation();
      titleRef.current.blur();
      escapeRef.current = false;
    }
    if (e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
      titleRef.current.blur();
    }
  };

  if (type === "direct") return <DirectChat title={title} />;

  const handlePaste = event => {
    event.preventDefault();
    const value = event.clipboardData.getData("text/plain");
    document.execCommand("insertHTML", false, value);
  };

  return (
    <WrappingInput isFocused={focused} isCanceled={value === CANCELED}>
      {templateId && type === "workflow" ? (
        <div>
          <Text as="span" pt={1}>
            <WorkflowName id={templateId} /> {seqNo ? `#${seqNo}` : ""}
            {isCurrentVersion && "C"}:
          </Text>
        </div>
      ) : null}

      <span
        contentEditable
        ref={titleRef}
        onChange={handleChange}
        data-testid="chatroomTitle"
        onKeyDown={handleEnter}
        onBlur={handleBlur}
        onPaste={handlePaste}
        spellCheck={focused}
        dangerouslySetInnerHTML={{ __html: chatTitle }}
      />

      {type === "workflow" && !focused && (chatTitle || "").length === 0 ? (
        <i onClick={focusTitle}>No title</i>
      ) : null}
      {!focused && titleRef.current?.offsetHeight > 40 ? (
        <Ellipsis>...</Ellipsis>
      ) : null}
    </WrappingInput>
  );
};

Title.defaultProps = {
  templateId: null
};

const mapStateToProps = (
  { app }: { app: AppState },
  { roomId }: { roomId: RoomId }
) => {
  const templateId = getTemplateId(app, roomId);
  return {
    type: getChatroomType(app, roomId),
    templateId,
    title: getChatroomTitle(app, roomId),
    seqNo: getSequenceNo(app, roomId),
    value: getChatroomStatus(app, roomId),
    isCurrentVersion: isCurrentVersion(app, roomId)
  };
};

export default connect(mapStateToProps, { _setAttribute: setAttribute })(Title);
