// @flow

import React from "react";
import { connect } from "react-redux";
import Switch from "react-switch";

import {
  Privacy as StyledPrivacy,
  Label,
  SubHeading,
  StyledSwitch
} from "./styles";
import Type from "./PrivacyType";
import * as colors from "src/styles/constants/colors";
import { setAttribute } from "src/actions/chatroom";
import type { RoomId } from "src/types";

type Props = {
  roomId: RoomId,
  _setAttribute: Function,
  privacy: string
};

const Privacy = ({ roomId, _setAttribute, privacy }: Props) => {
  const handlePrivacy = () => {
    if (privacy === "none") _setAttribute(roomId, { privacy: "content" });
    else _setAttribute(roomId, { privacy: "none" });
  };

  return (
    <StyledPrivacy>
      <StyledSwitch>
        <Label>Make Private</Label>
        <Switch
          onChange={handlePrivacy}
          uncheckedIcon={false}
          checkedIcon={false}
          checked={privacy !== "none"}
          onColor={colors.checked}
          offColor={colors.grey6}
          className={privacy !== "none" ? "on" : "off"}
          height={18}
          width={31}
        />
      </StyledSwitch>
      <SubHeading>Only participants can access these conversations</SubHeading>

      {privacy !== "none" ? (
        <Type privacy={privacy} setAttribute={_setAttribute} roomId={roomId} />
      ) : null}
    </StyledPrivacy>
  );
};

export default connect(null, {
  _setAttribute: setAttribute
})(Privacy);
