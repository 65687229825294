// @flow

import React from "react";

type Props = {
  color?: string,
  size?: string,
  handleClick?: Function,
  cursor?: string
};

const Close = ({ color, size, handleClick, cursor, ...restProps }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 12 12"
    onClick={handleClick}
    style={{ cursor }}
    {...restProps}
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M11.5.5l-11 11M.5.5l11 11" />
    </g>
  </svg>
);

Close.defaultProps = {
  color: "#fff",
  size: "1.5em",
  handleClick: () => {},
  cursor: "initial"
};

export default Close;
