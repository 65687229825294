// @flow

import { connect, useSelector, useDispatch } from "react-redux";
import React, { useCallback, useRef, useEffect } from "react";

import Dropdown from "./Dropdown";
import Selection from "./Selection";
import useBoolean from "src/hooks/useBoolean";
import { Box } from "@chakra-ui/react";
import { getPrimaryFieldList } from "src/actions/chart";
import {
  getPrincipalChecklistFieldsList,
  getChartPrimaryFieldListLoading,
  getChartPrimaryFieldList,
  getChartNumericalFieldList,
  getChartNumericalFieldListLoading,
  getChartFormFieldList,
  getChartRecordCountFieldListLoading,
  getInstanceFilterId,
  getChartGroupByList
} from "src/reducers";

import {
  getRecordCountFieldList,
  getNumericalFieldList,
  getGroupByFieldList
} from "src/actions/chart";

import type { AppState } from "src/types";
import {
  generateFieldId,
  getChartFieldId,
  flattenChartFields,
  findFieldData
} from "src/utils/charts.js";

type Props = {
  result?: Array<Object>,
  disable?: boolean,
  numberField?: boolean,
  value: Array<any>,
  dependentField: ?string,
  otherFieldType?: string,
  handleSelect: Function,
  handleSearch: ?Function,
  isForm?: boolean,
  fieldType?: string,
  fieldFormMap: Object
};

export const FieldSelect = ({
  disable,
  numberField,
  value,
  dependentField,
  otherFieldType = "primary",
  handleSelect,
  handleSearch,
  isForm,
  fieldType = "primary"
}: Props) => {
  const allFields = useSelector(({ app }) =>
    getPrincipalChecklistFieldsList(app)
  );
  const workflowId = useSelector(({ app }) => getInstanceFilterId(app));

  useEffect(() => {
    getFieldList();
  }, []);

  const dispatch = useDispatch();
  const outerRef = useRef(null);
  const { value: dropdown, toggleBoolean, setFalse } = useBoolean();

  const getFieldList = () => {
    switch (fieldType) {
      case "primary":
        return dispatch(getPrimaryFieldList(allFields, workflowId));
      case "form":
        return dispatch(getRecordCountFieldList(allFields, workflowId));
      case "numeric":
        return dispatch(getNumericalFieldList(allFields, workflowId));
      case "group":
        return dispatch(
          getGroupByFieldList(allFields, workflowId, getChartFieldId(value))
        );
      default:
        break;
    }
  };

  const dropdownList = useSelector(({ app }) => {
    switch (fieldType) {
      case "primary":
        return getChartPrimaryFieldList(app);
      case "form":
        return getChartFormFieldList(app);
      case "numeric":
        return getChartNumericalFieldList(app);
      case "group":
        return getChartGroupByList(app);
      default:
        break;
    }
  });

  const dependentFieldList = useSelector(({ app }) => {
    switch (otherFieldType) {
      case "primary":
        return getChartPrimaryFieldList(app);
      case "form":
        return getChartFormFieldList(app);
      case "numeric":
        return getChartNumericalFieldList(app);
      case "group":
        return getChartGroupByList(app);
      default:
        break;
    }
  });

  const loading = useSelector(({ app }) => {
    switch (fieldType) {
      case "primary":
        return getChartPrimaryFieldListLoading(app);
      case "form":
        return getChartRecordCountFieldListLoading(app);
      case "numeric":
        return getChartNumericalFieldListLoading(app);
      default:
        return false;
    }
  });

  const fieldList = flattenChartFields(dropdownList);
  const fieldData = findFieldData(value || [], dropdownList);

  const handleSelection = useCallback(
    (newValue: any) => {
      const {
        linkedProcessOrFormType,
        id,
        type,
        linkedOrFormFieldId,
        linkedProcessOrFormId
      } = newValue;

      const selectedFieldId = generateFieldId({
        id,
        type,
        linkedProcessOrFormType,
        linkedProcessOrFormId,
        linkedOrFormFieldId
      });

      handleSelect(selectedFieldId);
      setFalse();
    },
    [numberField, handleSelect, setFalse]
  );

  return (
    <Box ref={outerRef} position="relative">
      {dropdown && (
        <Dropdown
          result={fieldList}
          value={value}
          dependentField={dependentField}
          dependentFieldList={dependentFieldList}
          outerRef={outerRef}
          handleSelect={handleSelection}
          handleClose={setFalse}
          numberField={numberField}
          handleSearch={handleSearch}
          isForm={isForm}
          fieldType={fieldType}
          isLoading={loading}
        />
      )}

      <Selection
        value={value}
        disable={disable}
        toggleDropdown={toggleBoolean}
        handleSelect={handleSelect}
        isForm={isForm}
        fieldData={fieldData}
        isLoading={loading}
      />
    </Box>
  );
};

FieldSelect.defaultProps = {
  numberField: false,
  disable: false
};

const mapStateToProps = ({ app }: { app: AppState }, props: Props) => ({
  result: props.result ? props.result : app.charts.searchedColumns
});

export default connect(mapStateToProps)(FieldSelect);
