// @flow

import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { useDebouncedCallback } from "use-debounce";

import { Result, Item } from "./styles";
import { Input } from "src/styles/input";
import { searchPrincipalChecklistField } from "src/actions/workflows";
import { RelativeBox as StyledSearch } from "src/styles/box";
import Name from "src/containers/checklist/FieldName";
import useClickoutside from "src/hooks/useClickoutside";
import type { AppState, ReactRef } from "src/types";

type Props = {
  result: Array<number>,
  outerRef: ReactRef,
  handleSelect: Function,
  handleClose: Function,
  _searchPrincipalChecklistField: Function
};

const Search = ({
  result,
  outerRef,
  handleSelect,
  handleClose,
  _searchPrincipalChecklistField
}: Props) => {
  const [debouncedSearchUsers] = useDebouncedCallback(
    _searchPrincipalChecklistField,
    400
  );

  useClickoutside({ outerRef, handleClose });

  useEffect(() => {
    _searchPrincipalChecklistField("");
  }, []);

  const handleSearch = useCallback(
    (event: any) => {
      debouncedSearchUsers(event.target.value);
    },
    [debouncedSearchUsers]
  );

  const handleClick = useCallback(
    (event: any, fieldId) => {
      event.stopPropagation();
      handleSelect(fieldId);
      handleClose();
    },
    [handleSelect, handleClose]
  );

  return (
    <>
      <Input placeholder="Search Fields" onChange={handleSearch} autoFocus />
      <StyledSearch>
        <Result>
          {result.map(fieldId => (
            <Item onClick={event => handleClick(event, fieldId)} key={fieldId}>
              <Name id={fieldId} />
            </Item>
          ))}
        </Result>
      </StyledSearch>
    </>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  result: app.workflow.principalChecklistSearchResult
});

export default connect(mapStateToProps, {
  _searchPrincipalChecklistField: searchPrincipalChecklistField
})(Search);
