// @flow

import React, { useCallback, useEffect, useState } from "react";
import * as moment from "moment";
import * as R from "ramda";
import { useSelector, useDispatch } from "react-redux";
import { Text } from "@chakra-ui/react";

import useFields from "./useFields";
import Icon from "src/icons";
import AddData from "./AddData";
import OutsideClickHandler from "src/components/OutsideClickHandler";

import {
  getChecklistValue,
  getChecklistFieldDetails,
  getWhetherMandatoryField,
  getLockedStatus,
  getChecklistFormValue,
  getIsFieldLocked,
  getFormFieldMandatoryStatus,
  getSelectedChecklist,
  isProcessRowSelected
} from "src/reducers";
import {
  updateChecklistFromManageView,
  bulkUpdateProcess
} from "src/actions/workflows";

import { extractChecklistValue } from "src/utils/checklist";
import { setChecklistValue } from "src/actions/checklist";

import {
  Field,
  DateInput,
  DateDone,
  Done,
  DisplayContainer,
  IconContainer,
  Display
} from "./styles";

import type {
  FieldId,
  RoomId,
  ColumnId,
  FieldValue,
  HttpMethods
} from "src/types";

type Props = {
  formId?: ?number,
  roomId: RoomId,
  fieldId: FieldId,
  roomFieldFormId?: ?string,
  fromManageView?: ?boolean,
  handleClose?: Function
};

const Date = ({
  formId,
  roomId,
  fieldId,
  roomFieldFormId,
  fromManageView = false,
  handleClose
}: Props) => {
  const dispatch = useDispatch();

  const isChecklistFieldMandatory = useSelector(({ app }) =>
    getWhetherMandatoryField(app, fieldId)
  );
  const isFormFieldMandatory = useSelector(({ app }) =>
    getFormFieldMandatoryStatus(app, roomFieldFormId ?? "")
  );
  const isMandatory = formId ? isFormFieldMandatory : isChecklistFieldMandatory;

  const isChecklistFieldLocked = useSelector(({ app }) =>
    getLockedStatus(app, roomId, fieldId)
  );
  const isFormFieldLocked = useSelector(({ app }) =>
    getIsFieldLocked(app, roomFieldFormId, fieldId, roomId)
  );
  const locked = formId ? isFormFieldLocked : isChecklistFieldLocked;

  const checklistFieldValue = useSelector(({ app }) =>
    getChecklistValue(app, fieldId, roomId)
  );
  const formFieldValue = useSelector(({ app }) =>
    getChecklistFormValue(app, roomFieldFormId ?? "")
  );

  const {
    columnId,
    value: selectedValue,
    roomId: selectedRoomId,
    index,
    embeddedIndex
  } = useSelector(({ app }) => getSelectedChecklist(app));

  const rowSelected = useSelector(({ app }) =>
    isProcessRowSelected(app, selectedRoomId)
  );
  const [selectedFieldValue, setSelectedFieldValue] = useState(selectedValue);

  const checklistValue = formId ? formFieldValue : checklistFieldValue;

  const details = useSelector(({ app }) =>
    getChecklistFieldDetails(app, `${fieldId}`)
  );

  const {
    edit,
    value: extractedValue,
    handleChange,
    openEdit,
    closeEdit,
    settings
  } = useFields({
    checklistValue,
    details
  });

  const value = fromManageView ? selectedFieldValue : extractedValue;

  const multiple = settings?.multiple || false;

  const handleSelectedChange = useCallback((e: any) => {
    setSelectedFieldValue(e.target.value);
  }, []);

  useEffect(() => {
    setSelectedFieldValue(selectedValue);
  }, [selectedValue]);

  const formattedValue = value
    ? String(moment(value).format("MMM DD, YYYY"))
    : "";

  const setChecklistValueFromManageView = useCallback(
    ({
      id: fieldId,
      value: fieldDetail,
      httpMethod,
      extraBody = null,
      columnId
    }: {
      id: FieldId,
      value: FieldValue,
      httpMethod?: HttpMethods,
      extraBody?: Object,
      columnId?: ColumnId
    }) => {
      const { value, type } = fieldDetail;
      if (rowSelected) {
        if (!multiple) {
          return bulkUpdateProcess({
            attrs: {
              [fieldId]: value
            }
          });
        } else {
          setSelectedFieldValue(value);
        }
      } else {
        return updateChecklistFromManageView(
          selectedRoomId,
          {
            [fieldId]: value,
            type,
            value
          },
          index,
          fieldId,
          httpMethod,
          extraBody,
          columnId ?? "",
          embeddedIndex
        );
      }

      if (!multiple && rowSelected && handleClose) {
        handleClose();
      }
    },
    []
  );

  const setChecklistFieldValue = useCallback(
    ({
      roomId,
      id,
      value,
      progress,
      formId,
      httpMethod,
      extraBody
    }: {
      roomId: RoomId,
      id: FieldId,
      value: FieldValue,
      progress: boolean,
      formId?: ?number,
      httpMethod?: HttpMethods,
      extraBody?: Object
    }) => {
      if (fromManageView) {
        return setChecklistValueFromManageView({
          columnId,
          extraBody,
          formId,
          httpMethod,
          id,
          progress,
          value
        });
      } else {
        return setChecklistValue({
          roomId,
          id: fieldId,
          value,
          progress: true,
          formId,
          columnId
        });
      }
    },
    []
  );

  const handleClick = useCallback(() => {
    if (locked) return;
    openEdit();
  }, []);

  const updateChecklistValue = useCallback(() => {
    const prevValue = extractChecklistValue(checklistValue);

    if (prevValue !== value)
      dispatch(
        setChecklistFieldValue({
          roomId,
          id: fieldId,
          value: {
            value: value === "" ? null : value,
            type: "date",
            checked: !!value
          },
          progress: true,
          formId,
          columnId
        })
      );

    closeEdit();
  }, [roomId, formId, columnId, fieldId, value]);

  if (edit) {
    return (
      <OutsideClickHandler onClickOutside={updateChecklistValue}>
        <Field>
          <DateInput
            type="date"
            placeholder="MMM DD, YYYY"
            value={value || ""}
            onChange={fromManageView ? handleSelectedChange : handleChange}
          />
          <DateDone>
            <Done onClick={updateChecklistValue}>Done</Done>
          </DateDone>
        </Field>
      </OutsideClickHandler>
    );
  }

  if (R.isNil(formattedValue) || R.isEmpty(formattedValue)) {
    return (
      <AddData
        disabled={locked}
        type="date"
        handleClick={openEdit}
        isSelect={false}
        isMandatory={isMandatory}
      />
    );
  }

  return (
    <DisplayContainer isText={false} locked={locked}>
      <Display
        clickToEdit={true}
        disabled={locked}
        onClick={handleClick}
        isText={false}
        isMandatory={isMandatory}
      >
        <Text m={0} noOfLines={null}>
          {formattedValue}
        </Text>
        <IconContainer>
          <Icon type="checklistEdit" handleClick={openEdit} />
        </IconContainer>
      </Display>
    </DisplayContainer>
  );
};

Date.defaultProps = {
  promptCallback: null,
  value: "",
  handleChange: () => {},
  openEdit: () => {},
  closeEdit: () => {},
  edit: false,
  locked: false
};

export default Date;
