// @flow

import React from "react";

import { DockBodyPlaceholder } from "./styles";
import PanelItem from "./PanelItem";
import { getMapArray } from "src/utils";

const arr = getMapArray(5);

const PanelBody = () => (
  <DockBodyPlaceholder>
    {arr.map(elem => (
      <PanelItem key={elem} />
    ))}
  </DockBodyPlaceholder>
);

export default PanelBody;
