// @flow
import type {
  TextProps,
  StackProps,
  SystemCSSProperites
} from "@chakra-ui/react";

import styled from "@emotion/styled";
import * as spacing from "src/styles/constants/spacing";
import * as colors from "src/styles/constants/colors";
// eslint-disable-next-line
export const ParentWrapper = styled.div`
  > div:first-of-type > div:first-of-type > div:first-of-type {
    padding: ${spacing.space_m};
    > div {
      max-width: 200px;
    }
  }
`;

export const NewConversationHeader: TextProps = {
  fontWeight: 700,
  fontSize: "20px",
  display: "inline"
};

export const Footer: StackProps = {
  justifyContent: "flex-end",
  "> button": {
    fontSize: "12px"
  }
};

export const ModalHeader: SystemCSSProperites = {
  background: colors.grey0,
  padding: "16px 16px",
  maxHeight: "56px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  "> svg:last-child": {
    background: colors.grey0,
    cursor: "pointer"
  }
};
