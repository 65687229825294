// @flow

import React from "react";
import { useSelector } from "react-redux";
import { HStack, Text, Avatar, VStack } from "@chakra-ui/react";
import { getUser } from "src/reducers";

import type { DownshiftProps } from "downshift";
import type { UID, UnifizeUser } from "src/types";
import type { OnItemClick } from "src/components/Dropdown";

import * as styles from "./styles";

type Props = {|
  id: UID,
  onItemClick?: OnItemClick,
  highlightedIndex?: number,
  index?: number,
  scrollIntoView?: DownshiftProps.scrollIntoView
|};

const NewUser = ({
  id,
  onItemClick,
  highlightedIndex,
  index,
  scrollIntoView
}: Props) => {
  const user: UnifizeUser = useSelector(({ app }) => getUser(app, id));
  const { displayName, photoUrl, email } = user;
  return (
    <HStack
      sx={
        (highlightedIndex === index && typeof highlightedIndex === "number") ||
        (highlightedIndex === null && index === 0)
          ? styles.ActiveUserOrgroup
          : styles.inActiveUserOrgroup
      }
      ref={highlightedIndex === index ? scrollIntoView : null}
      onClick={() => onItemClick && onItemClick({ id })}
    >
      <Avatar
        sx={styles.UserAndGroupAvatar}
        src={photoUrl}
        name={displayName}
        size="sm"
      />
      <VStack sx={styles.UserOrGroupDetails}>
        <Text sx={styles.UserOrGroupDetailsHeader}>{displayName}</Text>
        <Text sx={styles.UserOrGroupDetailsBody}>{email}</Text>
      </VStack>
    </HStack>
  );
};

export default NewUser;
