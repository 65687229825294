// @flow

import React, {
  useCallback,
  Fragment,
  useState,
  useRef,
  useEffect
} from "react";
import { connect } from "react-redux";
import dompurify from "dompurify";
import root from "react-shadow";
import * as R from "ramda";

import {
  FileOrEmailContent,
  EmailAddresses,
  Header,
  EmailIconContainer,
  EmailContent,
  EmailHeader,
  EmailBody,
  FileUpload,
  Expand,
  Files
} from "../styles";
import { getFile } from "src/actions/file";
import Icon from "src/icons";
import Display from "src/containers/file/Open";
import { IconWrapper } from "src/components/chatroom/OldEmail/styles";
import { showDock } from "src/actions/dock";
import { getCurrentUserId, getChatRoomEmail } from "src/reducers";
import type { UID } from "src/types";

type Props = {
  message: any,
  _showDock: Function,
  currentUser: UID,
  resolvedFiles: Array<string>,
  _getFile: Function,
  email: string
};

const Content = ({
  message,
  _showDock,
  currentUser,
  resolvedFiles,
  _getFile,
  email
}: Props) => {
  const { opened, data, author } = message;
  const {
    to,
    cc,
    bcc,
    subject,
    fullMessage: body,
    message: messageText,
    attachments
  } = data;

  const emailBody = useRef();

  const [expanded: boolean, expand: Function] = useState(false);

  const isCurrentUser = currentUser === author;

  useEffect(() => {
    (attachments || []).map(attachment => {
      if (!resolvedFiles.includes(attachment)) {
        _getFile(attachment);
      }
      return null;
    });
  }, [attachments, resolvedFiles]);

  const addressMapper = useCallback(
    (address, arrayId, array) => {
      if (arrayId === array.length - 1) {
        return (
          <Fragment key={arrayId}>
            {`${address} `}
            <Icon
              type="readStatus"
              status={opened && opened.includes(address) ? "read" : "delivered"}
            />
          </Fragment>
        );
      }
      return (
        <>
          {`${address} `}
          <Icon
            type="readStatus"
            status={opened && opened.includes(address) ? "read" : "delivered"}
          />
          {", "}
        </>
      );
    },
    [opened]
  );

  const addressFilter = useCallback(address => address !== email, [email]);

  const addTargetToLinks = R.replace(
    /<a /g,
    '<a target="_blank" rel="noopener noreferrer" '
  );

  const createStrippedMarkup = useCallback((htmlMessage, textBody) => {
    const sanitizer = dompurify.sanitize;
    if (htmlMessage)
      return { __html: addTargetToLinks(sanitizer(htmlMessage)) };
    return { __html: textBody };
  }, []);

  return (
    <>
      <FileOrEmailContent isCurrentUser={isCurrentUser}>
        <EmailHeader>
          <EmailIconContainer>
            <Icon type="mail" width={24} />
          </EmailIconContainer>
          <EmailContent>
            <Header>{subject}</Header>
            {to && to.filter(addressFilter).length ? (
              <EmailAddresses>
                To: {to.filter(addressFilter).map(addressMapper)}
              </EmailAddresses>
            ) : null}
            {cc && cc.length > 0 && (
              <EmailAddresses>Cc: {cc.map(addressMapper)}</EmailAddresses>
            )}
            {bcc && bcc.length > 0 && (
              <EmailAddresses>Bcc: {bcc.map(addressMapper)}</EmailAddresses>
            )}
          </EmailContent>
        </EmailHeader>
        <div>
          <EmailBody expanded={expanded}>
            <root.div mode={"closed"}>
              <>
                {messageText || body ? (
                  <div
                    ref={emailBody}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={createStrippedMarkup(
                      body,
                      messageText
                    )}
                    style={{
                      maxHeight: expanded ? "100%" : "200px",
                      overflow: "auto"
                    }}
                  />
                ) : (
                  <div ref={emailBody}>
                    <center>No content</center>
                  </div>
                )}

                {attachments && attachments.length > 0 && (
                  <IconWrapper
                    title={
                      attachments.length > 1 ? "attachments" : "attachment"
                    }
                  >
                    <FileUpload onClick={() => _showDock("file")} />
                  </IconWrapper>
                )}
              </>
            </root.div>
          </EmailBody>
          <Files>
            {attachments &&
              attachments
                .filter(f => f !== undefined)
                .map(f => <Display name={f} key={f} thumbnail={false} />)}
          </Files>
        </div>
      </FileOrEmailContent>
      <Expand onClick={() => expand(!expanded)} isCurrentUser={isCurrentUser}>
        {expanded ? "Hide details" : "View full email"}
      </Expand>
    </>
  );
};

const mapStateToProps = ({ app }) => ({
  currentUser: getCurrentUserId(app),
  email: getChatRoomEmail(app),
  resolvedFiles: app.files.allIds
});

export default connect(mapStateToProps, {
  _showDock: showDock,
  _getFile: getFile
})(Content);
