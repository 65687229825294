// @flow

import React, { Component } from "react";

import { OptionContainer, Option } from "./styles";
import Tooltip from "./NavBar/Tooltip/styles";
import NavIcons from "./NavIcons";

import type { CurrentFilter } from "src/types";

type Props = {
  view: string,
  show: boolean,
  filter: CurrentFilter,
  setFilter: Function,
  showView: Function,
  fetchChatroomUpdates: Function,
  setPreviousChatroom: Function
};

class Item extends Component<Props> {
  applyDefault = () => {
    const { view, filter, show, fetchChatroomUpdates, setPreviousChatroom } =
      this.props;
    const { setFilter, showView } = this.props;

    // Navigate to the chatroom route
    // Dispatch this action only if the user is not moving from "My Conversations"
    // to "Direct Messages" through the left sidebar, because the selected
    // chatroom stays the same and a bunch of actions get dispatched unnecessarily
    if (!(view === "My Conversations" && filter.name === "Direct Messages")) {
      setPreviousChatroom();
    }

    if (view !== filter.name) {
      setFilter(filter, null, filter.name);

      if (!show) {
        showView(filter.name);
      }

      if (filter.name === "Everything") {
        fetchChatroomUpdates();
      }
    }
  };

  render() {
    const { view, filter } = this.props;
    const selected = view === filter.name;
    return (
      <Tooltip selected={selected}>
        <>
          <OptionContainer onClick={this.applyDefault}>
            <Option selected={selected}>
              <NavIcons filter={filter} selected={selected} />
            </Option>
          </OptionContainer>
          <span>{filter.name}</span>
        </>
      </Tooltip>
    );
  }
}

export default Item;
