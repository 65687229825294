// @flow

import React, { useRef, useCallback, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Members as StyledMembers,
  MemberContainer,
  MobileDropdown
} from "./styles";
import Dropdown from "./Dropdown";
import AddUser from "./AddUser";
import Tooltip from "src/components/Tooltip";
import Loader from "src/components/Loader/SpinnerLoader";
import {
  getRoomMembers,
  getHeaderParticipants,
  getMemberAddDialog,
  getIsSrwMobile,
  getIsRoomLoading
} from "src/reducers";
import { getRoomParticipantCount } from "src/reducers/roomMembers";
import Icon from "src/icons";
import * as colors from "src/styles/constants/colors";
import { toggleParticipants } from "src/actions/header";

import type { RoomId } from "src/types";
type Props = {
  location: string,
  roomId: RoomId,
  address: string
};

const Members = ({ location, roomId }: Props) => {
  const outerRef = useRef(null);
  const dispatch = useDispatch();

  const users = useSelector(({ app }) => getRoomMembers(app));
  const participants = useSelector(({ app }) => getHeaderParticipants(app));
  const isSrwMobile = useSelector(({ app }) => getIsSrwMobile(app));
  const addParticipant = useSelector(({ app }) => getMemberAddDialog(app));
  const activeMemberAddDialog = useSelector(({ app }) =>
    getMemberAddDialog(app)
  );

  const allParticipantsCount = useSelector(({ app }) =>
    getRoomParticipantCount(app)
  );
  const isCurrentRoomLoading = useSelector(({ app }) => getIsRoomLoading(app));

  // Local state is there to make sure it initially shows the loader 
  // when the chatroom appears on screen
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Check conditions and set isLoading accordingly
    if (isCurrentRoomLoading) {
      setIsLoading(true);
    } else if (allParticipantsCount > 0 && !isCurrentRoomLoading) {
      setIsLoading(false);
    } else {
      // Case where allParticipantCount === 0 and isCurrentRoomLoading is false
      // Set isLoading to false after 2 seconds if allParticipantCount remains 0
      const timeout = setTimeout(() => {
        if (allParticipantsCount === 0) {
          setIsLoading(false);
        }
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [allParticipantsCount, isCurrentRoomLoading]);

  const _toggleParticipants = (location: string) => {
    dispatch(toggleParticipants(location));
  };

  const renderDropdown = useCallback(
    () =>
      participants === location && (
        <Dropdown
          room={roomId}
          outerRef={outerRef}
          location={location}
          toggleParticipants={_toggleParticipants}
        />
      ),
    [location, participants]
  );

  return (
    <MemberContainer isSrwMobile={isSrwMobile}>
      <Tooltip title="Participants">
        <StyledMembers
          ref={outerRef}
          onClick={() => _toggleParticipants(location)}
          tabIndex={0}
          onKeyPress={() => _toggleParticipants(location)}
        >
          <Icon
            type="group"
            fillColor={
              participants || activeMemberAddDialog ? colors.active : "none"
            }
            color={colors.active}
          />
          <span>
            {isLoading ? (
              <Loader thickness={2} size="sm" color={colors.grey38} />
            ) : (
              allParticipantsCount
            )}
          </span>
        </StyledMembers>
      </Tooltip>
      {isSrwMobile ? (
        <MobileDropdown>{renderDropdown()}</MobileDropdown>
      ) : (
        <>{renderDropdown()}</>
      )}

      {addParticipant === location && (
        <AddUser
          room={roomId}
          users={users}
          outerRef={outerRef}
          location={location}
        />
      )}
    </MemberContainer>
  );
};

export default Members;
