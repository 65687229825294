// @flow
/* eslint-disable no-unused-vars */

import * as R from "ramda";

import type {
  ChecklistFieldType,
  LinkedFieldType,
  chatPickListField,
  TaskField,
  GroupField,
  workflowField
} from "src/types";

const textHandler = {
  tag: (v: ChecklistFieldType, h: Function) => "text",
  rep: (v: ChecklistFieldType, h: Function) => ({
    label: v.label,
    autoCheck: v.autoCheck,
    settings: v.settings
  }),
  stringRep: (v: ChecklistFieldType, h: Function) => v.label
};

const numberHandler = {
  tag: (v: ChecklistFieldType, h: Function) => "number",
  rep: (v: ChecklistFieldType, h: Function) => ({
    label: v.label,
    autoCheck: v.autoCheck,
    settings: v.settings
  }),
  stringRep: (v: ChecklistFieldType, h: Function) => v.label
};

const dateHandler = {
  tag: (v: ChecklistFieldType, h: Function) => "date",
  rep: (v: ChecklistFieldType, h: Function) => ({
    label: v.label,
    autoCheck: v.autoCheck,
    settings: v.settings
  }),
  stringRep: (v: ChecklistFieldType, h: Function) => v.label
};

const userHandler = {
  tag: (v: ChecklistFieldType, h: Function) => "user",
  rep: (v: ChecklistFieldType, h: Function) => ({
    label: v.label,
    autoCheck: v.autoCheck,
    settings: v.settings
  }),
  stringRep: (v: ChecklistFieldType, h: Function) => v.label
};

const conversationHandler = {
  tag: (v: ChecklistFieldType, h: Function) => "conversation",
  rep: (v: ChecklistFieldType, h: Function) => ({
    label: v.label,
    autoCheck: v.autoCheck,
    settings: v.settings
  }),
  stringRep: (v: ChecklistFieldType, h: Function) => v.label
};

const childConversationHandler = {
  tag: (v: ChecklistFieldType, h: Function) => "conversation",
  rep: (v: ChecklistFieldType, h: Function) => {
    return {
      label: v.label,
      autoCheck: v.autoCheck,
      settings: !R.isEmpty(v.settings)
        ? JSON.stringify({ ...JSON.parse(v.settings), child: true })
        : JSON.stringify({ child: true })
    };
  },
  stringRep: (v: ChecklistFieldType, h: Function) => v.label
};

const selectHandler = {
  tag: (v: ChecklistFieldType, h: Function) => "select",
  rep: (v: ChecklistFieldType, h: Function) => ({
    label: v.label,
    autoCheck: v.autoCheck,
    settings: v.settings
  }),
  stringRep: (v: ChecklistFieldType, h: Function) => v.label
};

const formHandler = {
  tag: (v: ChecklistFieldType, h: Function) => "form",
  rep: (v: ChecklistFieldType, h: Function) => ({
    label: v.label,
    autoCheck: v.autoCheck,
    settings: v.settings
  }),
  stringRep: (v: ChecklistFieldType, h: Function) => v.label
};

export const pdfHandler = {
  tag: (v: ChecklistFieldType, h: Function) => "pdf",
  rep: (v: ChecklistFieldType, h: Function) => ({
    label: v.label,
    autoCheck: v.autoCheck,
    settings: v.settings
  }),
  stringRep: (v: ChecklistFieldType, h: Function) => v.label
};

export const sectionHandler = {
  tag: (v: ChecklistFieldType, h: Function) => "section",
  rep: (v: ChecklistFieldType, h: Function) => ({
    label: v.label,
    autoCheck: v.autoCheck,
    settings: v.settings
  }),
  stringRep: (v: ChecklistFieldType, h: Function) => v.label
};

const subSectionHandler = {
  tag: (v: ChecklistFieldType, h: Function) => "section",
  rep: (v: ChecklistFieldType, h: Function) => {
    return {
      label: v.label,
      autoCheck: v.autoCheck,
      settings: !R.isEmpty(v.settings)
        ? JSON.stringify({ ...JSON.parse(v.settings), level: 1 })
        : JSON.stringify({ level: 1 })
    };
  },
  stringRep: (v: ChecklistFieldType, h: Function) => v.label
};

export const fileHandler = {
  tag: (v: ChecklistFieldType, h: Function) => "file",
  rep: (v: ChecklistFieldType, h: Function) => ({
    label: v.label,
    autoCheck: v.autoCheck,
    settings: v.settings
  }),
  stringRep: (v: ChecklistFieldType, h: Function) => v.label
};

// Old Transit Writer
export const chatPickListHandler = {
  tag: (v: chatPickListField, h: Function) => "chatPickList",
  rep: (v: chatPickListField, h: Function) => ({
    label: v.label,
    isRequired: v.isRequired,
    placeholder: v.placeholder,
    isChecked: v.isChecked,
    settings: v.settings
  }),
  stringRep: (v: chatPickListField, h: Function) => v.label
};

export const taskHandler = {
  tag: (v: TaskField, h: Function) => "task",
  rep: (v: TaskField, h: Function) => ({
    label: v.label,
    isRequired: v.isRequired,
    placeholder: v.placeholder,
    isChecked: v.isChecked
  }),
  stringRep: (v: TaskField, h: Function) => v.label
};

export const groupHandler = {
  tag: (v: GroupField, h: Function) => "group",
  rep: (v: GroupField, h: Function) => ({
    label: v.label,
    isRequired: v.isRequired,
    placeholder: v.placeholder,
    isChecked: v.isChecked
  }),
  stringRep: (v: GroupField, h: Function) => v.label
};

export const workflowHandler = {
  tag: (v: workflowField, h: Function) => "workflow",
  rep: (v: workflowField, h: Function) => ({
    label: v.label,
    isRequired: v.isRequired,
    placeholder: v.placeholder,
    isChecked: v.isChecked,
    settings: v.settings
  }),
  stringRep: (v: workflowField, h: Function) => v.label
};

export const approvalHandler = {
  tag: (v: ChecklistFieldType, h: Function) => "approval",
  rep: (v: ChecklistFieldType, h: Function) => ({
    label: v.label,
    autoCheck: v.autoCheck,
    settings: v.settings
  }),
  stringRep: (v: ChecklistFieldType, h: Function) => v.label
};

export const revisionHandler = {
  tag: (v: ChecklistFieldType, h: Function) => "revision",
  rep: (v: ChecklistFieldType, h: Function) => ({
    label: v.label,
    autoCheck: v.autoCheck,
    settings: v.settings
  }),
  stringRep: (v: ChecklistFieldType, h: Function) => v.label
};

export const linkHandler = {
  tag: (v: LinkedFieldType, h: Function) => "link",
  rep: (v: LinkedFieldType, h: Function) => ({
    label: v.label,
    autoCheck: v.autoCheck,
    settings: v.settings,
    sourceProcessSettings: v.sourceProcessSettings,
    linkedProcessSettings: v.linkedProcessSettings
  }),
  stringRep: (v: LinkedFieldType, h: Function) => v.label
};

const transitHandlers = {
  text: textHandler,
  number: numberHandler,
  section: sectionHandler,
  subSection: subSectionHandler,
  date: dateHandler,
  user: userHandler,
  file: fileHandler,
  conversation: conversationHandler,
  childConversation: childConversationHandler,
  select: selectHandler,
  chatPickList: chatPickListHandler,
  task: taskHandler,
  workflow: workflowHandler,
  group: groupHandler,
  pdf: pdfHandler,
  form: formHandler,
  approval: approvalHandler,
  revision: revisionHandler,
  link: linkHandler
};

export default transitHandlers;
