// @flow

import React from "react";

type Props = {
  width: number | string,
  height: number | string,
  handleClick?: Function,
  fill?: string
};

const CaretUp = ({ width, height, handleClick, fill }: Props) => (
  <svg
    onClick={handleClick}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
  >
    <rect
      x="16"
      y="16"
      width="16"
      height="16"
      rx="8"
      transform="rotate(-180 16 16)"
      fill={fill || "transparent"}
    />
    <path
      d="M4.94 10.2734L8 7.2201L11.06 10.2734L12 9.33344L8 5.33344L4 9.33344L4.94 10.2734Z"
      fill="black"
    />
  </svg>
);

CaretUp.defaultProps = {
  width: 24,
  height: 24,
  handleClick: () => {}
};

export default CaretUp;
