// @flow

import { useSelector, useDispatch } from "react-redux";
import React, { useEffect } from "react";

import Select from "src/components/Select";
import Name from "src/containers/chatroom/Name";
import { searchChatroom } from "src/actions/chatroom";
import { getChatroomSearchResults } from "src/reducers";

import { Flex, FormLabel } from "@chakra-ui/react";
import { styles } from "./styles";

import type { WorkflowId, RoomId } from "src/types";

type Props = {
  handleValueChange: Function,
  value: Array<RoomId>,
  type: string,
  workflow: ?WorkflowId
};

const ConversationDropdown = ({
  handleValueChange,
  value,
  type,
  workflow
}: Props) => {
  const dispatch = useDispatch();

  const chatrooms = useSelector(state => getChatroomSearchResults(state.app));

  const searchHandler = (searchQuery: string) => {
    dispatch(
      searchChatroom(searchQuery, {
        workflow,
        type
      })
    );
  };

  useEffect(() => {
    dispatch(
      searchChatroom("", {
        workflow,
        type
      })
    );
  }, []);

  return (
    <Flex {...styles.valueSelectContainer}>
      <FormLabel {...styles.label}>Value</FormLabel>
      <Select
        value={value}
        placeholder="Value"
        onChange={handleValueChange}
        multiple
        customSearchHandler={searchHandler}
      >
        {chatrooms.map(roomId => (
          <Name id={roomId} value={roomId} key={roomId} />
        ))}
      </Select>
    </Flex>
  );
};

export default ConversationDropdown;
