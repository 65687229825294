/* eslint-disable react/no-unescaped-entities */
// @flow

import { connect, useDispatch, useSelector } from "react-redux";
import React, { useCallback } from "react";

import Cell from "./Cell";
import SubHeader from "./SubHeader";
import { setNewChartDialogAttributes, setCreateChart } from "src/actions/chart";
import Name from "src/components/Manage/Reports/Name";
import { getInstanceFilterId } from "src/reducers";
import { closeNewChartDialog } from "src/actions/chart";

import {
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  Flex
} from "@chakra-ui/react";

type Props = {
  reportId: string,
  handleClose: Function,
  _setNewChartDialogAttributes: Function
};

const Type = ({
  reportId,
  _setNewChartDialogAttributes,
  handleClose
}: Props) => {
  const dispatch = useDispatch();

  const workflowId = useSelector(({ app }) => getInstanceFilterId(app));

  const handleType = useCallback(
    (type: string) => {
      _setNewChartDialogAttributes({
        type,
        stage: 1
      });
      if (type === "comparison-stacked") {
        dispatch(
          setCreateChart({
            chartName: "comparison",
            reportId,
            workflowId
          })
        );
        dispatch(closeNewChartDialog());
      } else {
        if (type == "time-series-stacked") {
          _setNewChartDialogAttributes({
            timeInterval: "weekly",
            timePeriod: { timeDiff: 30 }
          });
        }

        _setNewChartDialogAttributes({
          type,
          stage: 1
        });
      }
    },
    [_setNewChartDialogAttributes]
  );

  const handleName = useCallback(
    (name: string) => {
      _setNewChartDialogAttributes({
        chartName: name
      });
    },
    [_setNewChartDialogAttributes]
  );

  return (
    <>
      <ModalHeader>
        {" "}
        Select a chart for <Name id={reportId} />
        <ModalCloseButton bg="white" onClick={handleClose} />
      </ModalHeader>

      <ModalBody>
        <Box mb={4}>
          <SubHeader
            heading1="Count of conversations"
            heading2="Following charts represent the number of underlying conversations"
          />

          <Flex alignItems="center" mb={4}>
            <Cell
              chartType="Bar Chart"
              name="conversation-count-stacked"
              type="conversation-count"
              handleType={handleType}
              handleName={handleName}
            />

            <Cell
              chartType="Pie Chart"
              name="conversation-count-doughnut"
              type="conversation-count-doughnut"
              handleType={handleType}
              handleName={handleName}
            />
          </Flex>
        </Box>

        <Box mb={4}>
          <SubHeader
            heading1="Count of Forms"
            heading2="Following charts represent the number of underlying forms"
          />

          <Flex alignItems="center" mb={4}>
            <Cell
              chartType="Bar Chart"
              name="form-count-stacked"
              type="form-count"
              handleType={handleType}
              handleName={handleName}
            />

            <Cell
              chartType="Pie Chart"
              name="form-count-doughnut"
              type="form-count-doughnut"
              handleType={handleType}
              handleName={handleName}
            />
          </Flex>
        </Box>

        <Box mb={4}>
          <SubHeader
            heading1="Sum of numeric field"
            heading2="Use these charts when you need total $ spent, or total hours etc."
          />
          <Flex alignItems="center" mb={4}>
            <Cell
              chartType="Bar Chart"
              name="numeric-field-stacked"
              type="conversation-count"
              handleType={handleType}
              handleName={handleName}
            />
          </Flex>
        </Box>

        <Box mb={4}>
          <SubHeader
            heading1="Time trends"
            heading2="Use these charts when you need see trends of counts or values over time"
          />
          <Flex alignItems="center" mb={4}>
            <Cell
              chartType="Bar Chart"
              name="time-series-stacked"
              type="time-series"
              handleType={handleType}
              handleName={handleName}
            />
          </Flex>
        </Box>

        <Box mb={4}>
          <SubHeader
            heading1="Comparison Chart"
            heading2="Use these charts when you need to compare two or more data sets. Eg. defect rate vs production data"
          />
          <Flex alignItems="center" mb={4}>
            <Cell
              chartType="Bar Chart"
              name="comparison-stacked"
              type="comparison-stacked"
              handleType={handleType}
              handleName={handleName}
            />
          </Flex>
        </Box>
      </ModalBody>
    </>
  );
};

export default connect(null, {
  _setNewChartDialogAttributes: setNewChartDialogAttributes
})(Type);
