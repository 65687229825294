// @flow

import React from "react";

type Props = {
  color?: string,
  hollow?: boolean
};
const Forward = ({ color, hollow }: Props) => (
  <svg
    width="17"
    height="14"
    viewBox="0 0 17 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Forward"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 7L9 1V5.033C5.219 5.033 1 6.5 1 13C2.969 9.812 6 9 9 9V13L16 7Z"
      fill={!hollow ? color : ""}
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Forward.defaultProps = {
  color: "#BBBBBB",
  hollow: false
};

export default Forward;
