// @flow

import React from "react";

import { Invitation as StyledInvitation, InviteButton } from "./styles";

type Props = {
  text?: string,
  handleInvite: Function
};

const Invitation = ({ text, handleInvite }: Props) => (
  <StyledInvitation>
    <span>
      {text ? (
        <div> Can’t find anyone with this name.</div>
      ) : (
        <div>Can’t find someone?</div>
      )}

      {text ? (
        <InviteButton type="button" onClick={handleInvite}>
          Invite {text} to Unifize via email
        </InviteButton>
      ) : (
        <InviteButton type="button" onClick={handleInvite}>
          Invite them to Unifize via email
        </InviteButton>
      )}
    </span>
  </StyledInvitation>
);

Invitation.defaultProps = {
  text: ""
};

export default Invitation;
