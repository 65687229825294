// @flow

import React from "react";

import VirtualList from "src/components/VirtualList";
import Accordion from "src/components/Accordion";

type Props = {
  title: number,
  count: number,
  header: string,
  render: Function
};

const SegmentedList = ({ count, render, title, header }: Props) => {
  return (
    <Accordion title={title} count={count} header={header}>
      <VirtualList
        initialAmount={20}
        progressiveAmount={20}
        role="list"
        renderItem={render}
        renderLoader={() => <div />}
        rowCount={count}
      />
    </Accordion>
  );
};

SegmentedList.defaultProps = {
  header: "Status"
};

export default SegmentedList;
