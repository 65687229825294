// @flow
import React from "react";
import { HStack } from "@chakra-ui/react";

import type { UID, GroupId } from "src/types";

import UserTag from "./UserTag";
import GroupTag from "./GroupTag";
import EmailTag from "./EmailTag";

import * as styles from "./styles";
import type { HandleRemove } from "./index";

type Email = {
  email: string
};

type Member = UID | Email;

type Props = {
  members: Array<Member>,
  groups?: Array<GroupId>,
  handleRemove: HandleRemove,
  selectionRef: any
};

const Selection = ({ members, groups, handleRemove, selectionRef }: Props) => {
  return (
    <>
      <HStack
        sx={styles.SelectedItemsContainer}
        shouldWrapChildren={true}
        spacing={0}
        ref={selectionRef}
      >
        {members.map((member, index) => {
          if (typeof member === "string") {
            return (
              <div>
                <UserTag
                  name={`member-${index}`}
                  key={`member-${index}`}
                  id={member}
                  handleRemove={handleRemove}
                />
              </div>
            );
          } else {
            return (
              <EmailTag
                name={`member-${index}`}
                key={`member-${index}`}
                value={member}
                handleRemove={handleRemove}
              />
            );
          }
        })}

        {groups
          ? groups.map((id, index) => (
              <GroupTag
                name={`group-${index}`}
                key={`group-${index}`}
                id={id}
                handleRemove={handleRemove}
              />
            ))
          : null}
      </HStack>
    </>
  );
};

export default Selection;
