// @flow

import React from "react";
import { connect } from "react-redux";
import Link from "redux-first-router-link";

import {
  ModalContainer,
  ModalCard,
  ModalInfo,
  LogIn,
  CloseIcon
} from "./styles";
import OutsideClickHandler from "src/components/OutsideClickHandler";
import Icon from "src/icons";
import { hideSignUpModal } from "src/actions/srw";
import * as colors from "src/styles/constants/colors";
import { appDomain } from "src/config/firebase";

import type { AppState } from "src/types";

type Props = {
  path: string,
  _hideSignUpModal: Function
};

const SignUpModal = ({ path, _hideSignUpModal }: Props) => {
  return (
    <ModalContainer>
      <OutsideClickHandler onClickOutside={_hideSignUpModal}>
        <ModalCard>
          <Icon type="lift" />
          <ModalInfo>You need to log in to do that</ModalInfo>
          <Link to={`${appDomain}${path}`} target="_blank">
            <LogIn>Log In</LogIn>
          </Link>

          <CloseIcon>
            <Icon
              type="close"
              color={colors.primary}
              cursor="pointer"
              handleClick={_hideSignUpModal}
            />
          </CloseIcon>
        </ModalCard>
      </OutsideClickHandler>
    </ModalContainer>
  );
};

const mapStateToProps = ({
  // eslint-disable-next-line no-unused-vars
  app,
  location
}: {
  app: AppState,
  location: Object
}) => ({
  path: location.pathname
});

export default connect(mapStateToProps, {
  _hideSignUpModal: hideSignUpModal
})(SignUpModal);
