// @flow

import React from "react";

import { AddButton } from "./styles";

type Props = {
  type?: string,
  isSelect?: boolean,
  disabled: boolean,
  handleClick: Function,
  isMandatory?: boolean
};

const AddData = ({
  type,
  handleClick,
  isSelect,
  disabled,
  isMandatory
}: Props) => {
  return (
    <AddButton
      onClick={handleClick}
      disabled={disabled}
      isMandatory={isMandatory}
    >
      {isSelect ? "Select" : `\u002B Add ${type || ""}`}
    </AddButton>
  );
};

AddData.defaultProps = {
  isSelect: false,
  type: ""
};

export default AddData;
