// @flow

import React, { useCallback } from "react";
import { connect } from "react-redux";

import { Row as StyledRow } from "../../styles";
import User from "./User";
import Selector from "./Selector";
import Groups from "./Groups";
import LastLoggedIn from "./LastLoggedIn";
import DateInvited from "./DateInvited";
import DateJoined from "./DateJoined";
import {
  Department as StyledDepartment,
  Email as StyledEmail,
  PhoneNumber as StyledPhoneNumber
} from "./styles";
import PhoneNumber from "src/containers/user/PhoneNumber";
import Department from "src/containers/user/Department";
import Email from "src/containers/user/Email";
import Role from "./Role";
import { togglePeopleRowSelection } from "src/actions/user";
import { getSelectedPeople } from "src/reducers";

import type { AppState, UID } from "src/types";

type Props = {
  user: UID,
  stickiness: boolean,
  selected: boolean,
  handleEdit: Function,
  _togglePeopleRowSelection: Function,
  toggleStickiness: Function
};

const Row = ({
  user,
  selected,
  stickiness,
  handleEdit,
  _togglePeopleRowSelection,
  toggleStickiness
}: Props) => {
  const handleSelection = useCallback(() => {
    _togglePeopleRowSelection(user);
  }, [user]);

  return (
    <StyledRow key={user} selected={selected}>
      <Selector
        uid={user}
        selected={selected}
        stickiness={stickiness}
        handleSelection={handleSelection}
      />
      <User
        uid={user}
        stickiness={stickiness}
        handleEdit={() => handleEdit(user)}
        toggleStickiness={toggleStickiness}
      />
      <StyledEmail>
        <Email uid={user} type="email" />
      </StyledEmail>
      <td>
        <Role uid={user} />
      </td>
      <StyledDepartment>
        <Department uid={user} />
      </StyledDepartment>

      <StyledPhoneNumber>
        <PhoneNumber uid={user} />
      </StyledPhoneNumber>
      <Groups uid={user} />
      <LastLoggedIn uid={user} />
      <DateInvited uid={user} />
      <DateJoined uid={user} />
    </StyledRow>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  selected: getSelectedPeople(app, props.user)
});

export default connect(mapStateToProps, {
  _togglePeopleRowSelection: togglePeopleRowSelection
})(Row);
