// @flow

import { normalize } from "normalizr";
import { Set as ImmutableSet } from "immutable";
import uuid from "uuid/v4";
import * as R from "ramda";
import { toast } from "react-toastify";
import {
  call,
  put,
  take,
  takeEvery,
  takeLatest,
  cancel,
  select,
  fork,
  delay,
  throttle,
  actionChannel,
  all
} from "redux-saga/effects";

import connection, { rsf } from "src/db";
import getAppState, { getUser, getEmailState } from "src/selectors";
import {
  getFolders,
  getCurrentFile,
  getFolderRoute,
  getLastFolder,
  getFolderFiles,
  getCurrentFolder,
  getCurrentChatRoom,
  getChatRoom
} from "src/reducers";
import * as file from "src/api/file";
import * as actions from "src/actions/file";
import * as schema from "src/actions/schema";
import * as atypes from "src/constants/actionTypes";

import type { Action, UnifizeFile } from "src/types";

function* deleteFile({ payload }: Action) {
  try {
    yield call(file.deleteFile, payload.name);
    yield put({ type: atypes.DELETE_FILE_SUCCESS, payload });
  } catch (e) {
    yield put({
      type: atypes.DELETE_FILE_FAILURE,
      payload: e
    });
  }
}

function* watchDeleteFile(): any {
  yield takeEvery(
    [atypes.DELETE_FILE_REQUEST, atypes.CLEAR_TEMP_ATTACHMENT],
    deleteFile
  );
}

function* deleteEmailTempAttachments() {
  try {
    const { tempAttachments } = yield select(getEmailState);
    const fileIds = R.keys(tempAttachments);
    yield all(fileIds.map(fileId => call(file.deleteFile, fileId)));
  } catch (e) {
    yield put({
      type: atypes.DELETE_FILE_FAILURE,
      payload: e
    });
  }
}

function* watchClearEmailTempAttachments(): any {
  yield takeEvery(
    atypes.CLEAR_ALL_TEMP_ATTACHMENTS,
    deleteEmailTempAttachments
  );
}

function* syncFile({ payload }: Action) {
  yield payload.snapshot.docChanges().map(change => {
    if (change.type === "removed") {
      return put({
        type: atypes.DELETE_FILE_SUCCESS,
        payload: { name: change.doc.id }
      });
    }
    // If change type if not removed then it will be added or modified
    return put({
      type: atypes.ADD_FILE_SUCCESS,
      payload: { ...change.doc.data(), name: change.doc.id }
    });
  });
}

function* watchFiles(): any {
  yield takeLatest(atypes.START_FILE_SYNC, syncFile);
}

function* listenFileChange(): any {
  // start listening file change
  const task = yield fork(
    rsf.firestore.syncCollection,
    connection().collection("files"),

    {
      successActionCreator: actions.startFileSync,
      failureActionCreator: actions.fileSyncError
    }
  );
  // Wait for STOP LISTEN FILE CHANGE
  yield take(atypes.STOP_LISTEN_FILE_CHANGE);
  yield cancel(task);
}

function* watchFileChange(): any {
  yield takeLatest(atypes.LISTEN_FILE_CHANGE, listenFileChange);
}

function* createFolder({ payload }: Action): any {
  try {
    const appState = yield select(getAppState);
    const lastFolder = yield getLastFolder(appState);
    const parentId = lastFolder && lastFolder.id !== 0 ? lastFolder.id : null;
    const dir = yield call(file.createFolder, { name: payload.name, parentId });
    yield put({ type: atypes.CREATE_FOLDER_SUCCESS, payload: dir });
  } catch (e) {
    yield put({ type: atypes.CREATE_FOLDER_FAILURE, payload: e });
  }
}

function* watchCreateFolder(): any {
  yield takeEvery(atypes.CREATE_FOLDER_REQUEST, createFolder);
}

function* fetchFolder({ payload }: Action): any {
  try {
    let folderId = null;
    if (payload.id > 1) {
      folderId = payload.id;
    } else if (payload.id === 0) {
      folderId = null;
    } else {
      const currentFolder = yield R.curry(getLastFolder)(
        yield select(getAppState)
      );
      folderId = currentFolder ? currentFolder.id : null;
    }
    const response = yield call(file.fetchFolder, folderId);
    const myDrive = response.folders.find(folder => folder.name === "My Drive");
    if (myDrive) {
      yield put({ type: atypes.SET_MY_DRIVE, payload: myDrive });
    }
    yield put({
      type: atypes.FETCH_FOLDER_SUCCESS,
      payload: normalize(response, schema.arrayOfFolder)
    });
  } catch (e) {
    yield put({
      type: atypes.FETCH_FOLDER_FAILURE,
      payload: e
    });
  }
}

function* watchFetchFolder(): any {
  yield takeLatest(
    [
      atypes.FETCH_FOLDER_REQUEST,
      atypes.EXPAND_FOLDER,
      atypes.GOTO_FOLDER,
      atypes.GOTO_ROOT_FOLDER,
      atypes.GOTO_MY_DRIVE
    ],
    fetchFolder
  );
}

// Move file request saga

function* fetchBrowseFolder({ payload }: Action) {
  try {
    const response = yield call(file.fetchFolder, payload.id);
    yield put({
      type: atypes.FETCH_BROWSE_FOLDER_SUCCESS,
      payload: { folders: response.folders, files: response.files }
    });
  } catch (e) {
    yield put({
      type: atypes.FETCH_BROWSE_FOLDER_FAILURE,
      payload: e
    });
  }
}

function* watchBrowseFolderChange(): any {
  yield takeLatest(
    [atypes.BROWSE_IN_FOLDER, atypes.BROWSE_BACK_FOLDER],
    fetchBrowseFolder
  );
}

function* moveFileRequest(): any {
  const appState = yield select(getAppState);
  const folders = yield getFolders(appState);
  const files = yield getFolderFiles(appState);
  const folderRoute = yield getFolderRoute(appState);
  yield put({
    type: atypes.COPY_FILE_STATE,
    payload: { folders, files, folderRoute }
  });
  // yield call(listenFolderChange);
}

function* watchFileMoveRequest(): any {
  yield takeEvery(
    [atypes.START_MOVE_FILE_PROCESS, atypes.START_MOVE_FOLDER_PROCESS],
    moveFileRequest
  );
}

function* moveFile({ payload }: Action) {
  const toFolder = payload.folderId;
  const appState = yield select(getAppState);
  const currentFile = getCurrentFile(appState);
  const currentFolder = getLastFolder(appState);

  let folders = ImmutableSet(currentFile.folders);
  if (currentFolder) {
    folders = folders.delete(currentFolder.id);
  }

  try {
    yield call(file.moveFile, currentFile.name, folders.add(toFolder));
    yield put({ type: atypes.MOVE_FILE_SUCCESS, payload: {} });
  } catch (e) {
    yield put({ type: atypes.MOVE_FILE_FAILURE, payload: e });
  }
}

function* watchMoveFile(): any {
  yield takeEvery(atypes.MOVE_FILE_REQUEST, moveFile);
}

function* moveFolder({ payload }: Action) {
  const toFolder = payload.folderId === 0 ? null : payload.folderId;
  const currentFolder = R.curry(getCurrentFolder)(yield select(getAppState));
  try {
    yield call(file.moveFolder, currentFolder.id, toFolder);
    yield put({
      type: atypes.MOVE_FOLDER_SUCCESS,
      payload: currentFolder
    });
  } catch (e) {
    yield put({ type: atypes.MOVE_FOLDER_FAILURE, payload: e });
  }
}

function* watchMoveFolder(): any {
  yield takeEvery(atypes.MOVE_FOLDER_REQUEST, moveFolder);
}

function* deleteFolder({ payload }: Action) {
  try {
    yield call(file.deleteFolder, payload.folderId);
    yield put({
      type: atypes.DELETE_FOLDER_SUCCESS,
      payload: { id: payload.folderId }
    });
  } catch (e) {
    yield put({
      type: atypes.DELETE_FOLDER_FAILURE,
      payload: e
    });
  }
}

function* watchDeleteFolder(): any {
  yield takeEvery(atypes.DELETE_FOLDER_REQUEST, deleteFolder);
}

function* cloneFile({ payload }: Action): any {
  try {
    const appState = yield select(getAppState);
    const lastFolder = yield getLastFolder(appState);
    const folderId = lastFolder && lastFolder.id !== 0 ? lastFolder.id : null;
    const blob = yield call(
      file.getFileBlob,
      payload.file.name,
      payload.file.generation
    );

    const fileData = yield call(file.uploadFileToStorage, uuid(), blob);
    const response = yield call(file.createFile, {
      ...fileData,
      folderId,
      originalName: `Copy of ${payload.file.originalName}`
    });

    yield put({ type: atypes.CLONE_FILE_SUCCESS, payload: response });
  } catch (e) {
    yield put({ type: atypes.CLONE_FILE_FAILURE, payload: e });
  }
}

function* watchCloneFile(): any {
  yield takeEvery(atypes.CLONE_FILE_REQUEST, cloneFile);
}

function* pinFile({ payload }: Action) {
  try {
    const appState = yield select(getAppState);
    const currentFile = getCurrentFile(appState);
    const currentChatRoom = getCurrentChatRoom(appState);

    const result = yield call(
      file.pinFile,
      currentChatRoom.id,
      currentFile.name,
      payload.description
    );
    yield put({ type: atypes.PIN_FILE_SUCCESS, payload: result });
  } catch (e) {
    yield put({
      type: atypes.PIN_FILE_FAILURE,
      payload: e
    });
  }
}

function* watchPinFile(): any {
  yield takeEvery(atypes.PIN_FILE_REQUEST, pinFile);
}

function* unpinFile({ payload }: Action) {
  try {
    const appState = yield select(getAppState);
    const currentChatRoom = getCurrentChatRoom(appState);
    const result = yield call(file.unpinFile, currentChatRoom.id, payload);
    yield put({
      type: atypes.UNPIN_FILE_SUCCESS,
      payload: result
    });
  } catch (err) {
    yield put({
      type: atypes.UNPIN_FILE_FAILURE,
      payload: { err }
    });
  }
}

function* watchUnpinFile(): any {
  yield takeEvery(atypes.UNPIN_FILE_REQUEST, unpinFile);
}

function* getOrgFiles({ payload }: Action) {
  let response = [];
  try {
    if (payload.searchText === "" || undefined || null) {
      response = yield call(file.fetchOrgFiles);
    } else {
      response = yield call(file.searchOrgFiles, payload.searchText);
    }

    yield put({
      type: atypes.SEARCH_FILES_SUCCESS,
      payload: {
        searchedFiles: response
      }
    });
  } catch (e) {
    yield put({
      type: atypes.SEARCH_FILES_FAILURE,
      payload: e
    });
  }
}

function* watchGetOrgFiles(): any {
  yield takeLatest(atypes.SEARCH_FILES_REQUEST, getOrgFiles);
  yield takeEvery(atypes.UPLOAD_FILE_TO_CHECKLIST_SUCCESS, getOrgFiles);
}

function* fileUploadTask(fileData: UnifizeFile, payload) {
  const {
    roomId,
    fieldId,
    columnId,
    formId,
    multiple,
    dispatch,
    setChecklistValue
  } = payload;

  const {
    name,
    url: signedURL,
    contentType
  } = yield call(file.getSignedURLForUpload, fileData.name);

  const handleProgress = (progressEvent: any) => {
    const percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    dispatch({
      type: atypes.UPDATE_CHECKLIST_FILE_UPLOAD_PROGRESS,
      payload: {
        roomId,
        progress: percentCompleted,
        fieldId,
        fileName: fileData.name
      }
    });
  };

  const response = yield call(file.uploadFileToAWS, {
    signedURL,
    fileData,
    name,
    handleProgress,
    contentType
  });

  const versionId =
    response.headers["x-ms-version-id"] ?? response.headers["x-amz-version-id"];
  const fileResponse = {
    name,
    versionId,
    size: fileData.size,
    originalName: fileData.name,
    folderId: null
  };

  const updatedValue = yield call(
    file.uploadFileToChecklist,
    fileData,
    roomId,
    fileResponse,
    fieldId,
    multiple,
    formId || null
  );

  if (setChecklistValue) {
    setChecklistValue({
      roomId,
      id: fieldId,
      value: {
        ...updatedValue,
        value: updatedValue?.val?.value
      },
      progress: true,
      columnId
    });
  } else {
    yield put({
      type: atypes.SET_CHECKLIST_VALUE_SUCCESS,
      payload: {
        chatroomId: roomId,
        ...updatedValue
      }
    });
  }
  const fieldValues = {};

  if (formId) {
    fieldValues[`${roomId}-${fieldId}-${formId}`] = {
      checked: true,
      chatroomId: roomId,
      ...updatedValue
    };

    yield put({
      type: atypes.GET_CHECKLIST_FORM_VALUES,
      payload: fieldValues
    });
  }

  yield put({
    type: atypes.UPLOAD_FILE_TO_CHECKLIST_SUCCESS,
    payload: {
      roomId,
      fieldId,
      fileName: fileData.name
    }
  });
}

function* uploadFileToChecklist({ payload }: Action) {
  const { fileData, roomId, fieldId, columnId } = payload;

  // Send payload with updated roomId for API call
  if (columnId && columnId.includes("-")) {
    const appState = yield select(getAppState);
    const autoNo = getChatRoom(appState, `${roomId}`).autoNo;
    const parentInstance = appState.workflow.instancesById[autoNo];
    const selectedChecklist = appState.checklist.selectedChecklist;
    const embeddedFieldRoomId =
      parentInstance[columnId.split("-")[0]].result[
        selectedChecklist.embeddedIndex ?? selectedChecklist.index
      ];
    payload = {
      ...payload,
      fieldId: selectedChecklist.fieldId,
      roomId: embeddedFieldRoomId
    };
  }

  const files =
    R.type(fileData) === "Array" || R.type(fileData) === "FileList"
      ? fileData
      : [fileData];

  // Set progress for all files to 0, this will show an empty progress bar for all
  // yet to be uploaded files
  for (let fileData of files) {
    payload.dispatch({
      type: atypes.UPDATE_CHECKLIST_FILE_UPLOAD_PROGRESS,
      payload: {
        roomId: payload.roomId,
        progress: 0,
        fieldId,
        fileName: fileData.name
      }
    });
  }

  try {
    const { allowFileUpload } = (yield select(getAppState)).orgs;

    if (allowFileUpload) {
      let fileUploadTasks = [];
      for (let fileData of files) {
        fileUploadTasks.push(fork(fileUploadTask, fileData, payload));
      }

      yield all(fileUploadTasks);
    } else {
      toast.error("Your org has disabled file uploads");
      throw new Error("Your org has disabled file uploads");
    }
  } catch (e) {
    alert(JSON.stringify(e));
    yield put({
      type: atypes.UPLOAD_FILE_TO_CHECKLIST_FAILURE,
      payload: { e }
    });
  }
}

function* watchUploadFileToChecklist(): any {
  yield takeLatest(
    atypes.UPLOAD_FILE_TO_CHECKLIST_REQUEST,
    uploadFileToChecklist
  );
}

function* getFile({ payload }: Action) {
  try {
    const result = yield call(file.getFile, payload.name);

    yield put({
      type: atypes.GET_FILE_SUCCESS,
      payload: {
        ...result,
        versionId: R.last(result?.versions || [])?.versionId || null
      }
    });
  } catch (error) {
    yield put({ type: atypes.GET_FILE_FAILURE, payload: error });
  }
}

function* watchGetFile(): any {
  yield takeEvery(atypes.GET_FILE_REQUEST, getFile);
}

function* searchFile({ payload }: Action) {
  try {
    const result = yield call(file.searchFile, payload.searchText);
    yield put({
      type: atypes.SEARCH_FILE_SUCCESS,
      payload: { result }
    });

    delay(100);
    yield put({
      type: atypes.SEARCH_RESULTS_FILE_SUCCESS,
      payload: { result }
    });
  } catch (error) {
    yield put({
      type: atypes.SEARCH_FILE_FAILURE,
      payload: error
    });
  }
}

function* watchSearchFile(): any {
  yield throttle(800, atypes.SEARCH_FILE_REQUEST, searchFile);
}

function* restoreVersion({ payload }: Action): any {
  try {
    yield file.restoreVersion(payload.name, payload.generation);
    yield put({
      type: atypes.RESTORE_FILE_VERSION_SUCCESS,
      payload: {}
    });
  } catch (error) {
    yield put({
      type: atypes.RESTORE_FILE_VERSION_FAILURE,
      payload: error
    });
  }
}

function* watchRestoreVersion(): any {
  yield takeEvery(atypes.RESTORE_FILE_VERSION_REQUEST, restoreVersion);
}

function* showManageFiles({ payload, meta }: Action): any {
  try {
    const { uid } = yield select(getUser);
    const query = (meta || {}).query || {};

    if (!uid) {
      yield put({ type: atypes.SIGN_IN });
      yield put({
        type: atypes.SET_REQUESTED_PAGE,
        payload: {
          page: "file",
          query
        }
      });
    } else {
      yield put({
        type: atypes.SET_FILE_SUCCESS,
        payload
      });
    }
  } catch (error) {
    yield put({
      type: atypes.SET_FILE_FAILURE,
      payload: { error }
    });
  }
}

function* watchShowManageFiles(): any {
  yield takeEvery(atypes.SET_FILE_REQUEST, showManageFiles);
}

function* openFileViewer({ payload }: Action): any {
  const { roomId, name, generation, versionId } = payload;
  // If the file is of type HEIC, fetch JEPG format instead.
  const format =
    payload.mimeType === "image/heic" || payload.mimeType === "image/heif"
      ? "jpeg"
      : "";
  try {
    const { url } = yield call(file.fetchFileDownloadUrlV2, {
      roomId,
      name,
      generation,
      versionId,
      format
    });

    if (payload.type === "video") {
      yield put({
        type: atypes.PLAY_VIDEO_FILE,
        payload: { url }
      });
    } else if (["image", "pdf"].includes(payload.type)) {
      yield put({
        type: atypes.OPEN_FILE_VIEWER,
        payload: { ...payload, url }
      });
    } else {
      yield put({
        type: atypes.DOWNLOAD_FILE_REQUEST,
        payload
      });
    }
  } catch (error) {
    console.error(error);
    yield put({
      type: atypes.OPEN_FILE_VIEWER_FAILURE,
      payload: { error }
    });
  }
}

function* watchOpenFileViewer(): any {
  yield takeLatest(atypes.OPEN_FILE_VIEWER_REQUEST, openFileViewer);
}

function* bufferFilesLoads(): any {
  let files = {};
  let interval = null;
  try {
    const filesChannel = yield actionChannel(atypes.GET_FILE_SUCCESS);
    interval = setInterval(() => {
      if (!R.isEmpty(files)) {
        filesChannel.close();
      }
    }, 1500);
    while (true) {
      const { payload: file } = yield take(filesChannel);
      files = {
        ...files,
        [file.name]: {
          ...file,
          name: `${file.name}`
        }
      };
    }
  } catch (error) {
    yield put({
      type: atypes.GET_FILE_BATCH_FAILURE,
      payload: { error: error.message }
    });
  } finally {
    clearInterval(interval);
    yield put({
      type: atypes.START_FILES_CHANNEL,
      payload: {}
    });
    yield put({
      type: atypes.GET_FILE_BATCH_SUCCESS,
      payload: files
    });
  }
}

function* watchBufferFilesLoads(): any {
  yield takeEvery(atypes.START_FILES_CHANNEL, bufferFilesLoads);
}

function* watchStartFilesChannel(): any {
  yield takeEvery(atypes.LOAD_CHATROOMS_SUCCESS, bufferFilesLoads);
}

function* watchStartFilesChannelSrw(): any {
  yield takeEvery(atypes.SRW_CHATROOM_ATTRIBUTES, bufferFilesLoads);
}

function* downloadFile({ payload }: Action): any {
  try {
    const { name, generation, versionId, roomId } = payload;
    let url = null;

    if (!roomId) {
      url = yield call(file.fetchFileDownloadURL, name, generation, versionId);
    } else {
      const res = yield call(file.fetchFileDownloadUrlV2, {
        roomId,
        name,
        generation,
        versionId,
        format: ""
      });

      if (!res.url) throw new Error();

      url = res.url;
    }

    const link = document.createElement("a");
    link.href = `${url}`;
    if (document.body) {
      document.body.appendChild(link);
    }
    link.click();
    if (document.body) {
      document.body.removeChild(link);
    }
  } catch (error) {
    const statusCode = error.status;
    const isDownloadBlocked = statusCode === 403;

    if (isDownloadBlocked) {
      yield put({
        type: atypes.DOWNLOAD_FILE_FAILURE,
        payload: { isDownloadBlocked }
      });
    }
  }
}

function* watchDownloadFile(): any {
  yield takeLatest(atypes.DOWNLOAD_FILE_REQUEST, downloadFile);
}

function* uploadFileToDropdown({ payload }: Action): any {
  try {
    const { allowFileUpload } = (yield select(getAppState)).orgs;

    if (allowFileUpload) {
      const fileData = payload.file;
      const {
        name,
        url: signedURL,
        contentType
      } = yield call(file.getSignedURLForUpload, fileData.name);
      // eslint-disable-next-line no-inner-declarations
      function handleProgress(progressEvent: any) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        payload.dispatch({
          type: atypes.DROPDOWN_FILE_UPLOAD_PROGRESS,
          payload: { progress: percentCompleted }
        });
      }

      const response = yield call(file.uploadFileToAWS, {
        signedURL,
        fileData,
        name,
        handleProgress,
        contentType
      });

      const versionId =
        response.headers["x-ms-version-id"] ??
        response.headers["x-amz-version-id"];
      const fileObj = {
        name,
        versionId,
        size: fileData.size,
        originalName: fileData.name,
        folderId: null,
        mimeType: fileData.type
      };

      yield call(file.createFile, fileObj);

      yield put({
        type: atypes.UPLOAD_FILE_IN_DROPDOWN_SUCCESS,
        payload: fileObj
      });
    } else {
      toast.error("Your org has disabled file uploads");
      throw new Error("Your org has disabled file uploads");
    }
  } catch (error) {
    toast.error("Error in uploading file please try again");
    yield put({
      type: atypes.UPLOAD_FILE_IN_DROPDOWN_FAILURE,
      payload: { error }
    });
  }
}

function* watchUploadFileToDropdown(): any {
  yield takeLatest(
    atypes.UPLOAD_FILE_IN_DROPDOWN_REQUEST,
    uploadFileToDropdown
  );
}

function* getPreview({ payload }: Action): any {
  try {
    let previewCount = 7;
    let delayTime = 3000;

    if (payload.name) {
      // Retry 7 times for preview url with interval between
      while (previewCount > 0) {
        try {
          previewCount--;
          yield delay(delayTime);
          delayTime += 2000;
          const { url } = yield call(file.getPreview, {
            name: payload.name,
            roomId: payload.roomId
          });
          yield put({
            type: atypes.GET_FILE_PREVIEW_SUCCESS,
            payload: {
              [payload.name]: { url, date: new Date() }
            }
          });
          break;
        } catch (error) {
          // Break the execution if its an authorization error
          if (error.status === 403) {
            break;
          }
          console.error(error);
        }
      }
    }
  } catch (error) {
    console.error(error);
  }
}

function* watchGetPreview(): any {
  yield takeEvery(atypes.GET_FILE_PREVIEW_REQUEST, getPreview);
}

function* getThumbnail({ payload }: Action): any {
  try {
    let previewCount = 7;
    let delayTime = 3000;

    if (payload.name) {
      // Retry 7 times for thumbnail url with interval between
      while (previewCount > 0) {
        try {
          previewCount--;
          yield delay(delayTime);
          delayTime += 2000;
          const { url } = yield call(file.getThumbnail, {
            name: payload.name,
            roomId: payload.roomId
          });
          yield put({
            type: atypes.GET_FILE_THUMBNAIL_SUCCESS,
            payload: {
              [payload.name]: { url, date: new Date() }
            }
          });
          break;
        } catch (error) {
          console.error(error);
        }
      }
    }
  } catch (error) {
    console.error(error);
  }
}

function* watchGetThumbnail(): any {
  yield takeEvery(atypes.GET_FILE_THUMBNAIL_REQUEST, getThumbnail);
}

function* uploadCustomSignature({ payload }: Action): any {
  try {
    const {
      name,
      url: signedURL,
      contentType
    } = yield call(file.getSignatureUploadURL, payload.fileName);

    // eslint-disable-next-line no-inner-declarations
    function handleProgress(progressEvent: any) {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      payload.dispatch({
        type: atypes.UPDATE_CHECKLIST_FILE_UPLOAD_PROGRESS,
        payload: {
          roomId: payload.roomId,
          progress: percentCompleted,
          fieldId: payload.fieldId,
          fileName: payload.fileName
        }
      });
    }

    yield call(file.uploadFileToAWS, {
      signedURL,
      fileData: payload.fileData,
      name,
      handleProgress,
      contentType
    });

    yield call(file.createSignature);

    yield put({
      type: atypes.UPLOAD_CUSTOM_SIGNATURE_SUCCESS,
      payload: {}
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: atypes.UPLOAD_CUSTOM_SIGNATURE_FAILURE,
      payload: error
    });
  } finally {
    yield put({
      type: atypes.UPLOAD_FILE_TO_CHECKLIST_SUCCESS,
      payload: {
        roomId: payload.roomId,
        fieldId: payload.fieldId,
        fileName: payload.fileName
      }
    });
  }
}

function* watchUploadCustomSignature(): any {
  yield takeEvery(
    atypes.UPLOAD_CUSTOM_SIGNATURE_REQUEST,
    uploadCustomSignature
  );
}

function* uploadCSVToProcess({ payload }: Action): any {
  try {
    const { fileData, templateId } = payload;

    const {
      name,
      url: preSignedURL,
      contentType
    } = yield call(file.getPresignedURLForProcessImport, {
      templateId,
      fileName: fileData.name
    });

    const handleProgress = (progressEvent: any) =>
      Math.round((progressEvent.loaded * 100) / progressEvent.total);

    const s3UploadResponse = yield call(file.uploadFileToAWS, {
      signedURL: preSignedURL,
      fileData,
      name,
      handleProgress,
      contentType
    });

    const versionId =
      s3UploadResponse.headers["x-ms-version-id"] ??
      s3UploadResponse.headers["x-amz-version-id"];
    const fileResponse = {
      name,
      versionId,
      size: fileData.size,
      originalName: fileData.name,
      folderId: null
    };

    // Accept any 2xx response as success
    if (Math.floor(s3UploadResponse.status / 100) === 2) {
      yield put({
        type: atypes.UPLOAD_CSV_TO_PROCESS_SUCCESS,
        payload: { templateId, file: fileResponse }
      });
      yield put({
        type: atypes.GET_PROCESS_FIELD_MAPPINGS_REQUEST,
        payload: { templateId, fileName: name }
      });
    }
  } catch (e) {
    console.error(e);
    yield put({
      type: atypes.UPLOAD_CSV_TO_PROCESS_FAILURE,
      payload: { error: e, templateId: payload.templateId }
    });
    toast.error("Failed to upload csv file");
  }
}

function* watchUploadCSVToProcess(): any {
  yield takeEvery(atypes.UPLOAD_CSV_TO_PROCESS_REQUEST, uploadCSVToProcess);
}

export default [
  watchGetPreview(),
  watchGetThumbnail(),
  watchUploadFileToChecklist(),
  watchGetOrgFiles(),
  watchUploadFileToDropdown(),
  watchDownloadFile(),
  watchStartFilesChannel(),
  watchBufferFilesLoads(),
  watchStartFilesChannelSrw(),
  watchFiles(),
  watchFetchFolder(),
  watchCloneFile(),
  watchDeleteFile(),
  watchFileChange(),
  watchCreateFolder(),
  watchFileMoveRequest(),
  watchMoveFile(),
  watchBrowseFolderChange(),
  watchMoveFolder(),
  watchDeleteFolder(),
  watchPinFile(),
  watchUnpinFile(),
  watchGetFile(),
  watchSearchFile(),
  watchRestoreVersion(),
  watchShowManageFiles(),
  watchOpenFileViewer(),
  watchUploadCustomSignature(),
  watchClearEmailTempAttachments(),
  watchUploadCSVToProcess()
];
