// @flow

import React from "react";

type Props = {
  size?: number
};

const RemoveCircle = ({ size }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 20 20"
  >
    <rect width="12" height="12" x="2.5" y="3.8" stroke="#E53D01" rx="11.5" />
    <path fill="#E53D01" d="M12 9v1H5v-1h10z" />
  </svg>
);

RemoveCircle.defaultProps = {
  size: 20
};

export default RemoveCircle;
