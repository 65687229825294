// @flow

import React from "react";
import usePortal from "react-useportal";

import {
  Content as StyledContent,
  PrimaryButton,
  Overlay,
  ModalText,
  SecondaryFooter,
  HeadingWrapper,
  Secondary,
  Form
} from "src/components/Manage/Reports/Modal/styles";
import { ModalFormBody } from "src/components/chatroom-nav/filter/styles";
import * as colors from "src/styles/constants/colors";
import ButtonLoader from "src/components/ButtonLoader";

type Props = {
  title: string,
  handleRemove: Function,
  handleClose: Function
};

const Modal = ({ title, handleRemove, handleClose }: Props) => {
  const { Portal } = usePortal();

  const handleSubmit = (event: any) => {
    event.preventDefault();
    handleRemove();
    handleClose();
  };

  return (
    <Portal>
      <Overlay>
        <StyledContent>
          <Form onSubmit={handleSubmit}>
            <HeadingWrapper>Delete</HeadingWrapper>
            <ModalFormBody>
              <ModalText>
                <div>
                  Are you sure you want to remove {title} from the checklist?
                </div>
              </ModalText>
            </ModalFormBody>
            <SecondaryFooter>
              <PrimaryButton delete>
                <ButtonLoader type="submit" color={colors.danger}>
                  Delete
                </ButtonLoader>
              </PrimaryButton>
              <Secondary type="button" onClick={handleClose}>
                Cancel
              </Secondary>
            </SecondaryFooter>
          </Form>
        </StyledContent>
      </Overlay>
    </Portal>
  );
};

export default Modal;
