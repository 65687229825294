// @flow

import React, { Component } from "react";

import type { ComponentType } from "react";

type State = {
  dropdown: boolean,
  focus: number
};

type Props = {
  result: Array<any>
};

const selector = (DropdownComponent: ComponentType<Object>) => {
  // eslint-disable-next-line react/display-name
  const component = class extends Component<Props, State> {
    state = {
      dropdown: false,
      focus: 0
    };

    handleDropdown = () => {
      this.setState(prevState => ({ dropdown: !prevState.dropdown }));
    };

    setFocus = (direction: string) => {
      const { result } = this.props;
      if (direction === "up") {
        this.setState(prevState => ({
          focus: prevState.focus > 0 ? prevState.focus - 1 : result.length
        }));
      } else {
        this.setState(prevState => ({
          focus: prevState.focus < result.length ? prevState.focus + 1 : 0
        }));
      }
    };

    handleClose = () => {
      this.setState({ dropdown: false });
    };

    render() {
      const props = {
        ...this.state,
        ...this.props,
        handleDropdown: this.handleDropdown,
        handleClose: this.handleClose,
        setFocus: this.setFocus
      };

      return <DropdownComponent {...props} />;
    }
  };

  return component;
};

export default selector;
