// @flow

import * as easyFetch from "src/utils/fetch";
import type { CrashReport } from "src/types";

/**
 * Send errors to the backend for tracking
 * @param {object} error - the error object received when the app crashed
 */
export const sendCrashReport = (error: CrashReport) => {
  easyFetch.post("/crash", {
    body: {
      error
    }
  });
};
