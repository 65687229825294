// @flow

import React, { useRef, useCallback, useEffect } from "react";
import { connect } from "react-redux";

import Pill from "./Pill";
import Dropdown from "./Dropdown";
import { PillContainer, MobileDropdown } from "./styles";
import type { AppState, ReactRef, RoomId } from "src/types";
import { toggleChatroomOverlay } from "src/actions/header";
import { getChatroomOwner, getUserSearchResult } from "src/reducers";
import useBoolean from "src/hooks/useBoolean";
import { Box } from "@chakra-ui/react";

type Props = {
  roomId: RoomId,
  value: ?Object,
  location: string,
  chatroomOverlay?: string,
  isSrwMobile: boolean,
  _toggleChatroomOverlay: Function,
  setOwnerTrigger: Function,
  activeDock: string,
  ownerDropdown: boolean,
  hideName?: boolean
};

const Owner = ({
  value,
  roomId,
  location,
  isSrwMobile,
  _toggleChatroomOverlay,
  chatroomOverlay,
  activeDock,
  ownerDropdown,
  hideName
}: Props) => {
  const outerRef: ReactRef = useRef(null);
  const { value: dropdown, toggleBoolean, setValue } = useBoolean();

  // Set dropdown true or false if triggered from info panel
  useEffect(() => {
    if (ownerDropdown) {
      setValue(true);
      _toggleChatroomOverlay("message");
    } else if (!ownerDropdown) {
      setValue(false);
    }
  }, [ownerDropdown]);

  const toggleOwner = useCallback(() => {
    toggleBoolean();
    _toggleChatroomOverlay(chatroomOverlay);
  }, [toggleBoolean]);

  const isInfoPanelOpen = activeDock === "info";
  const renderDropdown = useCallback(
    () =>
      dropdown && (
        <Dropdown
          chatroomOverlay={chatroomOverlay}
          outerRef={outerRef}
          currentOwner={value}
          roomId={roomId}
          toggleOwner={toggleOwner}
          location={location}
          value={value}
          isInfoPanelOpen={isInfoPanelOpen}
        />
      ),
    [dropdown, value, roomId, location, toggleOwner]
  );

  return (
    <Box
      sx={{
        position: "relative",
        padding: 0
      }}
    >
      <PillContainer onClick={toggleOwner} ref={outerRef}>
        <Pill
          value={value}
          hideName={hideName}
          location={location}
          isActive={dropdown}
          toggleOwner={toggleOwner}
        />
      </PillContainer>
      {isSrwMobile ? (
        <MobileDropdown>{renderDropdown()}</MobileDropdown>
      ) : (
        <>{renderDropdown()}</>
      )}
    </Box>
  );
};

const mapStateToProps = (
  { app }: { app: AppState },
  { roomId }: { roomId: RoomId }
) => ({
  value: getChatroomOwner(app, roomId),
  result: getUserSearchResult(app),
  isSrwMobile: app.srw.isMobile
});

Owner.defaultProps = {
  chatroomOverlay: "message"
};

export default connect(mapStateToProps, {
  _toggleChatroomOverlay: toggleChatroomOverlay
})(Owner);
