// @flow
import React from "react";
import { HStack, Box, Spinner } from "@chakra-ui/react";
import Tooltip from "src/components/Tooltip";
import Icons from "src/icons";
import { RemoveWrapper } from "../../styles";
import * as styles from "./styles";

type ActionsBarProps = {
  openFile: Function,
  fetchingFileUrl: boolean,
  fetchingEditUrl: boolean,
  disabled: boolean,
  fileSource: string,
  handleEditSharepointFile: Function,
  handleDownload: Function,
  openModal: Function
};

const ActionsBar = ({
  openFile,
  fetchingFileUrl,
  fetchingEditUrl,
  disabled,
  fileSource,
  handleEditSharepointFile,
  handleDownload,
  openModal
}: ActionsBarProps) => {
  return (
    <HStack {...styles.actionsBar}>
      <Tooltip title="Open">
        <Box sx={styles.iconBtn} onClick={openFile}>
          {fetchingFileUrl ? (
            <Spinner size="xs" color="unifize_inkLightest" />
          ) : (
            <Icons type="preview" />
          )}
        </Box>
      </Tooltip>

      {!disabled && fileSource === "microsoft365" && (
        <Tooltip title="Edit">
          <Box sx={styles.iconBtn} onClick={handleEditSharepointFile}>
            {fetchingEditUrl ? (
              <Spinner size="xs" color="unifize_inkLightest" />
            ) : (
              <Icons type="edit3" />
            )}
          </Box>
        </Tooltip>
      )}

      <Tooltip title="Download">
        <Box sx={styles.iconBtn} onClick={handleDownload}>
          <Icons type="download2" />
        </Box>
      </Tooltip>

      {!disabled && (
        <Tooltip
          title="Delete"
          sx={{
            backgroundColor: "red"
          }}
        >
          <RemoveWrapper onClick={openModal}>
            <Icons type="decrement" width="14" height="14" />
          </RemoveWrapper>
        </Tooltip>
      )}
    </HStack>
  );
};

export default ActionsBar;
