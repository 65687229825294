// @flow

import React, { useCallback } from "react";
import onClickOutside from "react-onclickoutside";

import { Dropdown as StyledDropdown, DropdownItem } from "./styles";
import rules from "src/constants/promptRules";

type Props = {
  setShowDropdown: Function,
  updateRuleId: Function
};

const Dropdown = ({ setShowDropdown, updateRuleId }: Props) => {
  Dropdown.handleClickOutside = useCallback(() => {
    setShowDropdown(false);
  }, []);

  return (
    <StyledDropdown>
      {rules.map((rule, id) => (
        <DropdownItem
          // eslint-disable-next-line react/no-array-index-key
          key={id}
          onClick={(e: any) => {
            e.stopPropagation();
            updateRuleId(id + 1);
          }}
        >
          {rule}
        </DropdownItem>
      ))}
    </StyledDropdown>
  );
};

const clickOutsideConfig = {
  handleClickOutside: () => Dropdown.handleClickOutside
};

export default onClickOutside(Dropdown, clickOutsideConfig);
