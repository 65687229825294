// @flow

import React from "react";
import { useSelector } from "react-redux";
import { Tag, TagLabel, Box, useDisclosure } from "@chakra-ui/react";
import { getGroup, getCurrentLocation } from "src/reducers";
import Icon from "src/icons";

import type { Group, GroupId } from "src/types";
import MembersModal from "src/components/Modal/Members";

import * as styles from "./styles";

type Props = {
  id: number,
  handleRemove: (id: GroupId) => void
};

const GroupTag = ({ id, handleRemove }: Props) => {
  const group: Group = useSelector(({ app }) => getGroup(app, id));
  const currentLocation = useSelector(({ location }) =>
    getCurrentLocation(location)
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isOnManageView = currentLocation.includes("manage");
  const { title, photoUrl } = group;
  const groupSize = group?.members?.length;

  return (
    <>
      <MembersModal id={group?.id} isOpen={isOpen} onClose={onClose} />
      <Tag sx={styles.UserOrGroupTag} size={"lg"} tabIndex={0}>
        <Icon
          type="groupAvatar"
          text={title}
          size="xs"
          id={id}
          photoUrl={photoUrl}
        />
        <TagLabel
          onClick={onOpen}
          sx={
            isOnManageView
              ? styles.UserAndGroupTagTextHighlight
              : styles.UserAndGroupTagText
          }
        >
          {title}{" "}
          {isOnManageView &&
            `(${groupSize} ${groupSize > 0 ? "members" : "member"})`}
        </TagLabel>
        <Box display="flex" sx={styles.IconBox}>
          <Icon
            type="decrement"
            handleClick={() => handleRemove && handleRemove(id)}
          />
        </Box>
      </Tag>
    </>
  );
};

export default GroupTag;
