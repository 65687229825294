// @flow

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { VStack, useBoolean, Popover } from "@chakra-ui/react";
import { WarningIcon } from "@chakra-ui/icons";

import { getCurrentUserId } from "src/reducers";
import CreatorsModal from "src/components/Dock/Checklist/Revision/CreatorsModal";
import Button from "src/components/Buttons/ChakraButton";
import SignatureModal from "./SignatureModal";
import { InteractiveToolTip } from "src/components/Unifize";

import type { RoomId, FieldId, ApprovalFieldSettingsV2, UID } from "src/types";

type Props = {
  hasUserSigned: boolean,
  done: number,
  total: number,
  bordered?: boolean,
  disabled?: boolean,
  handleSign: Function,
  roomId: RoomId,
  fieldId: FieldId,
  settings: ApprovalFieldSettingsV2,
  signatories: UID[]
};

const borderedStyles = {
  border: "2px",
  borderColor: "purple.200",
  padding: 2
};

function SignatureButtonGroup({
  hasUserSigned,
  done,
  total,
  bordered,
  handleSign,
  disabled,
  roomId,
  fieldId,
  settings,
  signatories
}: Props) {
  const startingSigPos = done + 1;
  const pendingSignatures = Math.max(0, total - done);
  const pendingSignaturePositions = [...Array(pendingSignatures)].map(
    (_, i) => i + startingSigPos
  );

  const rootStyles = bordered ? borderedStyles : {};

  const [showMembers, setShowMembers] = useState(false);
  const [isSignatureModalOpen, setSignatureModal] = useBoolean();
  const [isPopoverOpen, setIsPopoverOpen] = useBoolean(false);

  const currentUserId = useSelector(state => getCurrentUserId(state.app));

  const isUserSignatory = signatories.includes(currentUserId);

  const openApprovalModal = () => {
    setSignatureModal.on();
  };

  if (pendingSignatures === 0) {
    return null;
  }

  return (
    <Popover isOpen={isPopoverOpen} onClose={setIsPopoverOpen.off}>
      <VStack
        w="100%"
        {...rootStyles}
        onMouseEnter={() => {
          if (disabled && !isUserSignatory) {
            setIsPopoverOpen.on();
          }
        }}
        onMouseLeave={() => {
          if (disabled) {
            setIsPopoverOpen.off();
          }
        }}
      >
        {pendingSignaturePositions.map(pos => {
          const isDisabled =
            disabled || hasUserSigned ? true : pos - startingSigPos !== 0;

          return (
            <InteractiveToolTip
              key={pos}
              isShown={!isUserSignatory}
              onClick={() => setShowMembers(true)}
              message="You are not authorized. Click here to see details."
            >
              <Button
                key={pos}
                onClick={openApprovalModal}
                isDisabled={isDisabled}
                variant="add"
                rootProps={{ width: "100%" }}
                rightIcon={
                  !isUserSignatory && (
                    <WarningIcon w={4} h={4} color="red.500" />
                  )
                }
              >
                &#43; Add Signature {pos}
              </Button>
            </InteractiveToolTip>
          );
        })}

        {showMembers && (
          <CreatorsModal
            title="Approval permissions"
            subtitle="Approval can only be signed by"
            emptySubtitle="The approval settings have not been configured."
            isOpen={showMembers}
            onClose={() => setShowMembers(false)}
            fieldId={fieldId}
            roomId={roomId}
            settings={settings}
            path="approvers"
          />
        )}

        <SignatureModal
          isOpen={isSignatureModalOpen}
          onClose={setSignatureModal.off}
          handleSign={handleSign}
          roomId={roomId}
          fieldId={fieldId}
          commentOnApproval={settings?.requireComment}
          commentOnRejection={settings?.requireRejectionComment}
        />
      </VStack>
    </Popover>
  );
}

export default SignatureButtonGroup;
