// @flow

import React from "react";

import { RoundButton } from "./styles";
import Icons from "src/icons";

type Props = {
  handleClick?: Function
};

const SaveList = ({ handleClick }: Props) => {
  return (
    <RoundButton type="button" onClick={handleClick}>
      <Icons type="save" />
      Save
    </RoundButton>
  );
};

SaveList.defaultProps = {
  handleClick: () => {}
};

export default SaveList;
