// @flow

import React, { useCallback } from "react";
import { connect } from "react-redux";

import { RoundButton, ClearSort, Arrow } from "./styles";
import Icons from "src/icons";

import { getCurrentFilterSortBy, getCurrentFilter } from "src/reducers";
import { updateFilter } from "src/actions/filter";
import { sorByItemNames } from "src/constants/defaultFilter";

import type { AppState, CurrentFilter } from "src/types";

type Props = {
  handleClick?: Function,
  sort: string,
  _updateFilter: Function,
  currentFilter: CurrentFilter
};

const Sort = ({ handleClick, sort, _updateFilter, currentFilter }: Props) => {
  const clearSortBy = useCallback(
    (e: any) => {
      e.stopPropagation();
      _updateFilter({
        ...currentFilter,
        sortBy: "updatedAt"
      });
    },
    [_updateFilter, currentFilter]
  );
  const active = sort !== "updatedAt";
  return (
    <>
      <RoundButton type="button" onClick={handleClick} active={active}>
        <Icons type="sortBy" />
        {active ? sorByItemNames[sort] : "Sort By"}
        {active ? (
          <ClearSort onClick={clearSortBy}>
            <Icons type="blueCross" />
          </ClearSort>
        ) : (
          <Arrow>
            <Icons type="triangleDown" />
          </Arrow>
        )}
      </RoundButton>
    </>
  );
};

Sort.defaultProps = {
  handleClick: () => {}
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  sort: getCurrentFilterSortBy(app),
  currentFilter: getCurrentFilter(app)
});

export default connect(mapStateToProps, {
  _updateFilter: updateFilter
})(Sort);
