// @flow

import React from "react";

import { SelectCircle } from "./styles";

import * as colors from "src/styles/constants/colors";

type Props = {
  highlight?: boolean
};

const CheckMark = ({ highlight }: Props) => (
  <SelectCircle>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM17.5991 8.22287L10.2374 15.0573L6.40091 11.4956C6.06701 11.1856 5.54114 11.2009 5.22634 11.5296C4.91154 11.8584 4.92701 12.3762 5.26091 12.6862L9.6674 16.7771C9.98749 17.0743 10.4873 17.0743 10.8074 16.7771L18.7391 9.4135C19.073 9.10351 19.0885 8.58569 18.7737 8.25691C18.4589 7.92813 17.933 7.91289 17.5991 8.22287Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM17.5991 8.22287L10.2374 15.0573L6.40091 11.4956C6.06701 11.1856 5.54114 11.2009 5.22634 11.5296C4.91154 11.8584 4.92701 12.3762 5.26091 12.6862L9.6674 16.7771C9.98749 17.0743 10.4873 17.0743 10.8074 16.7771L18.7391 9.4135C19.073 9.10351 19.0885 8.58569 18.7737 8.25691C18.4589 7.92813 17.933 7.91289 17.5991 8.22287Z"
        fill={highlight ? colors.active : `${colors.grey10}00`}
      />
      <path
        d="M10.2374 15.0573L9.55703 15.7902L10.2374 16.4218L10.9178 15.7902L10.2374 15.0573ZM17.5991 8.22287L16.9187 7.49001V7.49001L17.5991 8.22287ZM6.40091 11.4956L5.72053 12.2285L6.40091 11.4956ZM5.22634 11.5296L4.50404 10.8381L4.50404 10.8381L5.22634 11.5296ZM5.26091 12.6862L4.58053 13.4191L5.26091 12.6862ZM9.6674 16.7771L8.98703 17.51V17.51L9.6674 16.7771ZM10.8074 16.7771L11.4878 17.51V17.51L10.8074 16.7771ZM18.7391 9.4135L18.0587 8.68063V8.68063L18.7391 9.4135ZM18.7737 8.25691L18.0514 8.9485V8.9485L18.7737 8.25691ZM23 12C23 18.0751 18.0751 23 12 23V25C19.1797 25 25 19.1797 25 12H23ZM12 1C18.0751 1 23 5.92487 23 12H25C25 4.8203 19.1797 -1 12 -1V1ZM1 12C1 5.92487 5.92487 1 12 1V-1C4.8203 -1 -1 4.8203 -1 12H1ZM12 23C5.92487 23 1 18.0751 1 12H-1C-1 19.1797 4.8203 25 12 25V23ZM10.9178 15.7902L18.2795 8.95573L16.9187 7.49001L9.55703 14.3245L10.9178 15.7902ZM5.72053 12.2285L9.55703 15.7902L10.9178 14.3245L7.08128 10.7627L5.72053 12.2285ZM5.94863 12.2212C5.88506 12.2876 5.78637 12.2896 5.72053 12.2285L7.08128 10.7627C6.34765 10.0816 5.19722 10.1141 4.50404 10.8381L5.94863 12.2212ZM5.94128 11.9534C6.01591 12.0226 6.02056 12.1461 5.94863 12.2212L4.50404 10.8381C3.80252 11.5707 3.83811 12.7298 4.58053 13.4191L5.94128 11.9534ZM10.3478 16.0443L5.94128 11.9534L4.58053 13.4191L8.98703 17.51L10.3478 16.0443ZM10.127 16.0443C10.1906 15.9852 10.2842 15.9852 10.3478 16.0443L8.98703 17.51C9.69077 18.1633 10.784 18.1633 11.4878 17.51L10.127 16.0443ZM18.0587 8.68063L10.127 16.0443L11.4878 17.51L19.4195 10.1464L18.0587 8.68063ZM18.0514 8.9485C17.9794 8.87338 17.9841 8.74992 18.0587 8.68063L19.4195 10.1464C20.1619 9.45711 20.1975 8.29801 19.496 7.56533L18.0514 8.9485ZM18.2795 8.95574C18.2136 9.01686 18.1149 9.01489 18.0514 8.9485L19.496 7.56533C18.8028 6.84137 17.6523 6.80892 16.9187 7.49001L18.2795 8.95574Z"
        fill={highlight ? colors.active : `${colors.grey10}00`}
        mask="url(#path-1-inside-1)"
      />
    </svg>
  </SelectCircle>
);

CheckMark.defaultProps = {
  highlight: false
};

export default CheckMark;
