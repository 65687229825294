// @flow

import React from "react";

type Props = {
  color?: string
};

const TaskList = ({ color }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="16"
    viewBox="0 0 14 16"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M11.5 1.5h1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-11a1 1 0 0 1-1-1v-12a1 1 0 0 1 1-1h1" />
      <path d="M4.5.5h5v2h-5zM4 9l2 2 4-4" />
    </g>
  </svg>
);

TaskList.defaultProps = {
  color: "#2a2a2a"
};
export default TaskList;
