// @flow

import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import * as R from "ramda";

import type { State, RoomId, ChecklistId, RevisionFieldValue } from "src/types";
import {
  getChatroomChecklistFields,
  getChecklistFieldValue
} from "src/actions/checklist";
import {
  getFirstChecklist,
  getRevisionField,
  getChatRoom,
  getChecklistValue
} from "src/reducers";

import { getMainActiveDock, getSRWActiveDock } from "src/reducers/activeDock";

import useBoolean from "src/hooks/useBoolean";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalClose,
  ModalOverlay
} from "src/components/Modal";
import { MenuItem } from "../MoreMenu/styles";

import Icons from "src/icons";
import { Text, HStack, Portal } from "@chakra-ui/react";
import RevisionField from "./Popover";

type Props = {
  roomId: RoomId,
  checklistId: ChecklistId,
  fieldId: ?number,
  fieldValue: ?RevisionFieldValue,
  versionNumber: number,
  currentVersion: boolean,
  getChatroomChecklistFields: Function,
  getChecklistFieldValue: Function,
  setChecklistValue: Function,
  isSrwMobile?: boolean,
  portalRef: null | React$ElementRef<Portal>,
  closeMoreMenu?: () => void
};

function Revisions({
  roomId,
  checklistId,
  versionNumber,
  currentVersion,
  getChatroomChecklistFields,
  getChecklistFieldValue,
  isSrwMobile,
  portalRef,
  closeMoreMenu
}: Props) {
  // Store field value in local state when it is available
  // when it is null or undefined then don't change it

  const [
    localFieldValue,
    setLocalFieldValue
  ] = useState<RevisionFieldValue | null>(null);

  const activeDock = useSelector(({ app }) => getMainActiveDock(app));
  const srwActiveDock = useSelector(({ app }) => getSRWActiveDock(app));

  const revisionField = useSelector(({ app }) =>
    getRevisionField(app, checklistId)
  );

  // $FlowFixMe
  const field = revisionField?.toJS() || {};
  const fieldId = field?.id;

  const fieldValue = useSelector(({ app }) =>
    !R.isNil(fieldId) ? getChecklistValue(app, fieldId, roomId) : null
  );

  // Update local field value when field value changes except when it is null or undefined
  useEffect(() => {
    if (fieldValue !== null && fieldValue !== undefined) {
      setLocalFieldValue(fieldValue);
    }
  }, [fieldValue]);

  useEffect(() => {
    if (
      !R.isNil(checklistId) &&
      activeDock === null &&
      srwActiveDock === null
    ) {
      getChatroomChecklistFields(checklistId, roomId, false);
    }
  }, [checklistId, roomId]);

  useEffect(() => {
    if (!R.isNil(fieldId)) {
      getChecklistFieldValue(roomId, fieldId, true);
    }
  }, [fieldId, roomId]);

  // Reset the value when the room changes
  // because we reset checklist related redux slices
  // when the dock is hidden but we still need to show
  // the revision values in the header
  useEffect(() => {
    setLocalFieldValue(null);
  }, [roomId]);

  const {
    value: isOpen,
    setTrue: openModal,
    setFalse: closeModal
  } = useBoolean();

  return (
    <>
      {revisionField && (
        <HStack
          onClick={() => {
            openModal();
            closeMoreMenu && closeMoreMenu();
          }}
          sx={MenuItem}
          tabIndex={0}
          onKeyPress={e => {
            e.stopPropagation();
            if (e.key === "Enter") {
              openModal();
              closeMoreMenu && closeMoreMenu();
            }
          }}
        >
          <Icons type="currentRevisionV2" width="1rem" height="1rem" />
          <Text>
            {!isSrwMobile && "Revisions "}V{versionNumber}
            {currentVersion && "C"}
          </Text>
        </HStack>
      )}
      <ModalOverlay
        isOpen={isOpen}
        closeModal={closeModal}
        portalRef={portalRef}
      >
        <Modal isOpen={isOpen}>
          <ModalHeader>
            <Text as="span">Revisions</Text>

            <ModalClose closeModal={closeModal} />
          </ModalHeader>

          <ModalBody customStyles={{ padding: "1rem 0 0 1rem" }}>
            <RevisionField
              roomId={roomId}
              fieldId={fieldId}
              checklistValue={localFieldValue}
            />
          </ModalBody>
        </Modal>
      </ModalOverlay>
    </>
  );
}

const mapStateToProps = ({ app }: State, { roomId }: Props) => {
  const checklistId = getFirstChecklist(app)?.id;
  const room = getChatRoom(app, roomId);

  return {
    checklistId,
    versionNumber: room.version,
    currentVersion: room.currentVersion
  };
};

const mapDispatchToProps = {
  getChatroomChecklistFields,
  getChecklistFieldValue
};

export default connect(mapStateToProps, mapDispatchToProps)(Revisions);
