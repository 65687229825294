// @flow

import React from "react";

import Common from "./Common";
import type { Message } from "src/types";

type Props = {
  message: Message
};

const Privacy = ({ message }: Props) => {
  const { data } = message;
  const { new: newPrivacy } = data;

  const renderInfo = () => {
    if (newPrivacy === "content") return <strong>Hide chat content</strong>;
    if (newPrivacy === "full") return <strong>Hide everything</strong>;
    return <strong>Public</strong>;
  };

  return (
    <Common {...message}> changed the privacy setting to {renderInfo()}</Common>
  );
};

export default Privacy;
