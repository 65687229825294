// @flow

import React, { useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import * as R from "ramda";

import {
  UserItem,
  ImageContainer,
  UserInfo,
  UserRemove,
  DisplayName,
  Email
} from "./styles";
import UserImage from "src/components/user/Photo";
import Icon from "src/icons";
import useKeyDownHandlers from "src/hooks/useKeyDownHandlers";
import { getUser } from "src/reducers";
import { removeRoomMember } from "src/actions/chatroom";

import type { AppState, UID, UnifizeUser, RoomId } from "src/types";

type Props = {
  id: number,
  uid: UID,
  user: UnifizeUser,
  addUserMenu?: boolean,
  handleClick?: Function,
  onDelete?: ?Function,
  room: RoomId
};

const User = ({
  id,
  onDelete,
  uid,
  user,
  addUserMenu,
  handleClick,
  room,
  ...restProps
}: Props) => {
  const dispatch = useDispatch();
  const { displayName, email } = user || {};

  const { handleEnter } = useKeyDownHandlers(handleClick);

  const removeUser = useCallback(() => {
    if (onDelete) {
      onDelete(id, uid);
    } else {
      dispatch(removeRoomMember(uid, room));
    }
  }, [uid, room, id]);
  if (!R.equals(user, {})) {
    return (
      <UserItem
        addUserMenu={addUserMenu}
        tabIndex="0"
        onClick={handleClick}
        onKeyDown={handleEnter}
        {...restProps}
      >
        <ImageContainer>
          <UserImage
            uid={uid}
            size="sm"
            shape={addUserMenu ? "ellipse" : "curve"}
          />
        </ImageContainer>
        <UserInfo>
          {/* If both displayName and email is not available then display
          deleted user as display name
        */}
          <DisplayName>
            {!displayName && !email
              ? "Deleted User"
              : displayName || "Deleted User"}
          </DisplayName>
          <Email>{email}</Email>
        </UserInfo>
        {!addUserMenu && (
          <UserRemove>
            <Icon type="decrement" handleClick={removeUser} />
          </UserRemove>
        )}
      </UserItem>
    );
  } else {
    return <></>;
  }
};

User.defaultProps = {
  addUserMenu: false,
  handleClick: () => null
};

const mapStateToProps = (
  { app }: { app: AppState },
  { uid }: { uid: UID }
) => ({
  user: getUser(app, uid)
});

export default connect(mapStateToProps)(User);
