// @flow

import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import { Set as ImmutableSet } from "immutable";
import * as R from "ramda";

import Charts from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { updateFilter } from "src/actions/filter";
import { setNewChartDialogAttributes } from "src/actions/chart";
import { setPreviousChatroom } from "src/actions/chatroom";
import { openHomeScreenTile } from "src/actions/homescreen";
import { showView } from "src/actions";
import { chartColors } from "src/styles/constants/colors";

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

type Props = {
  height?: string,
  width?: string,
  type: string,
  chartId: number,
  field2: ?string,
  baseFontSize?: string,
  dataSource: Object,
  _updateFilter: Function,
  _setNewChartDialogAttributes: Function,
  _setPreviousChatroom: Function,
  _showView: Function,
  _openHomeScreenTile: Function
};

const Visualization = ({
  height,
  width,
  type,
  chartId,
  baseFontSize,
  dataSource,
  _updateFilter,
  _setNewChartDialogAttributes,
  _setPreviousChatroom,
  _openHomeScreenTile,
  _showView
}: Props) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 20);
    return () => clearTimeout(timer);
  }, [setShow]);

  let chartData = {
    type,
    dataFormat: "json",
    dataSource: {
      ...dataSource,
      chart: {
        scrollPosition: "top",
        ...dataSource.chart,
        baseFontSize,
        labelDisplay: "rotate",
        slantLabel: "1",
        showSum: "1",
        palettecolors: chartColors
      },
      dataset: (dataSource?.dataset || []).map(series => ({
        ...series,
        data: (series?.data || []).map(value => ({
          value: value.value,
          label: value.label,
          link: `j-openchart-${JSON.stringify({
            ...value.filter,
            chartId
          })}`
        }))
      }))
    },
    events: {
      // eslint-disable-next-line no-unused-vars
      dataplotClick: (eventObj, dataObj) => {
        eventObj.stopPropagation();
        eventObj.preventDefault();
        eventObj.stopImmediatePropagation();
        window.openchart = (filters: string) => {
          _setNewChartDialogAttributes({ show: false });
          try {
            const currentFilter = JSON.parse(filters);
            const active =
              currentFilter.active === true
                ? "pending"
                : currentFilter.active === false
                ? "closed"
                : "all";
            _updateFilter({
              ...R.pick(
                ["mine", "owner", "unread", "sortBy", "overdue"],
                currentFilter
              ),
              workflow: currentFilter.templateId,
              active:
                (currentFilter.status || []).length > 0 ? "custom" : active,
              customStatuses: ImmutableSet(currentFilter.status || []),
              type: currentFilter.workflow
                ? ["workflow"]
                : [
                    "group",
                    "conversation",
                    "task",
                    "approval",
                    "direct",
                    "workflow"
                  ],

              name: "Search results",
              homescreen: true
            });

            _setPreviousChatroom();
            _showView("My Conversations");
            _openHomeScreenTile({
              type: "clicked-tile",
              tileType: "My Conversation chart"
            });
          } catch (error) {}
        };
      }
    },
    width,
    height,
    dataLoadErrorMessage:
      "Failed to load chart data. Please refresh after some time",
    typeNotSupportedMessage:
      "Failed to load chart. Please refresh after some time",
    dataEmptyMessage: "No data to display"
  };

  return <>{show && <ReactFC {...chartData} />}</>;
};

Visualization.defaultProps = {
  height: "100%",
  width: "100%",
  baseFontSize: "10"
};

export default connect(null, {
  _updateFilter: updateFilter,
  _setPreviousChatroom: setPreviousChatroom,
  _setNewChartDialogAttributes: setNewChartDialogAttributes,
  _showView: showView,
  _openHomeScreenTile: openHomeScreenTile
})(Visualization);
