// @flow

import { combineReducers } from "redux";
import moment from "moment";
import { createSelector } from "reselect";
import {
  Set as ImmutableSet,
  Map as ImmutableMap,
  List,
  OrderedSet
} from "immutable";
import type { Set as ImmutableSetType } from "immutable";
import * as R from "ramda";
import { v4 as uuidv4 } from "uuid";
import {
  filterByOwner,
  filterByWorkflow,
  filterByApproved,
  filterByType,
  filterByText,
  filterByParent,
  filterByMine,
  filterByUnread,
  filterByCompletion,
  filterByFavourite,
  filterByOverdue,
  filterByCritical,
  filterByNewChats,
  filterByCancelled,
  filterByArchived
} from "src/utils/filters";
import chatroomMetaData from "src/constants/chatroomMetaData";
import { isValidChatroom, extractChatroomIds } from "src/utils/chatroom";
import { getChatroomMetaData } from "src/utils/checklist";

import type {
  Action,
  UnifizeChatRooms,
  UnifizeChatRoom,
  ChatRoomsById,
  Membership,
  CurrentFilter,
  UnifizeChatRoomById,
  RoomId,
  UID,
  SavedFilter,
  ReadMessageCount,
  MessageID,
  MemberDialog,
  WorkflowTitle,
  WorkflowSettings,
  LastMessage,
  Breadcrumbs,
  ChatroomFiles,
  RelatedConversationsById,
  RelatedCount,
  UserNames,
  DirectMessages,
  ChecklistHeader,
  ChildCount,
  SuggestedWorkflows,
  SegmentedByStatus,
  SegmentedByPriority,
  TemplateStatus,
  ChatInputFocus,
  AppState,
  PreservedChatInput,
  ShowSelect,
  RoomLoadingState,
  ChecklistValue
} from "src/types";

import * as atypes from "src/constants/actionTypes";

export const currentRoomAccessStatuses = {
  restricted: 1,
  failedToLoad: 2,
  allowed: 3
};

const currentRoomAccessStatus = (
  state: ?boolean = null,
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SET_CURRENT_CHATROOM_REQUEST:
      return null;
    case atypes.SET_CURRENT_CHATROOM_SUCCESS:
    case atypes.SINGLE_RESPONSE_WINDOW:
    case atypes.OPEN_CONVERSATION_MODAL:
      return currentRoomAccessStatuses.allowed;
    case atypes.SET_CURRENT_CHATROOM_FAILURE:
      return currentRoomAccessStatuses.failedToLoad;
    case atypes.LOAD_CHATROOM_FAILURE: {
      if (payload.isCurrentRoom) {
        if (payload.status === 401) {
          return currentRoomAccessStatuses.restricted;
        } else if (payload.status >= 400 && payload.status < 600) {
          return currentRoomAccessStatuses.failedToLoad;
        }
      }
      return state;
    }
    default:
      return state;
  }
};

const statusesById = (
  state: RoomLoadingState = {},
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SHOW_PARTICIPANT_LOADER:
      const roomAddress = payload.address;
      if (roomAddress) {
        return {
          ...state,
          [roomAddress]: true
        };
      }
      return state;
    case atypes.LOAD_CHATROOM_SUCCESS:
      const roomAddr = payload.room?.address;
      if (roomAddr) {
        const updated = {
          ...state,
          [roomAddr]: false
        };

        return updated;
      }
      return state;
    case atypes.HIDE_PARTICIPANT_LOADER:
      const chatRoomAddress = payload.address;
      if (chatRoomAddress) {
        const updated = {
          ...state,
          [chatRoomAddress]: false
        };

        return updated;
      }
      return state;
    default:
      return state;
  }
};

const current = (state: ?string = null, { type, payload }: Action) => {
  switch (type) {
    case atypes.SET_CURRENT_CHATROOM_SUCCESS:
      return payload.id;
    case atypes.SRW_CURRENT_CHATROOM:
      return `${payload}`;
    default:
      return state;
  }
};

const templateId = (state: ?number = null, { type, meta, payload }: Action) => {
  switch (type) {
    case atypes.SET_CURRENT_CHATROOM_REQUEST:
      const templateId = ((meta || {}).query || {}).templateId;
      if (!isNaN(templateId)) {
        return parseInt(templateId, 10);
      }
      return null;
    case atypes.UPDATE_CHATROOM_TEMPLATE:
      if (!isNaN(payload.templateId)) {
        return parseInt(payload.templateId, 10);
      }
      return null;
    default:
      return state;
  }
};

const lastMessage = (state: LastMessage = {}, { type, payload }: Action) => {
  switch (type) {
    case atypes.LOAD_CHATROOMS_SUCCESS:
    case atypes.BATCH_LOAD_ROOMS_SUCCESS:
    case atypes.FETCH_CHATROOM_UPDATES_SUCCESS:
      return { ...state, ...payload.lastMessage };
    default:
      return state;
  }
};

const byId = (state: ChatRoomsById = {}, { type, payload }: Action) => {
  switch (type) {
    case atypes.CREATE_CHATROOM_SUCCESS:
      return {
        ...state,
        ...{
          [payload.id]: {
            ...R.pickAll(chatroomMetaData, payload),
            id: `${payload.id}`
          }
        }
      };
    case atypes.FETCH_CHATROOM_UPDATES_SUCCESS:
    case atypes.BATCH_LOAD_ROOMS_SUCCESS:
      const chatRooms = payload?.chatRooms || {};
      return R.mergeDeepRight(state, chatRooms);

    case atypes.GET_WORKFLOW_INSTANCES_SUCCESS: {
      return R.mergeDeepRight(
        state,
        R.mergeAll(
          payload.workflows.map(chatroom => ({
            [`${chatroom.id}`]: getChatroomMetaData(chatroom)
          }))
        )
      );
    }

    case atypes.SET_CONVERSATION_FIELD:
      return {
        ...state,
        [payload.chatroom.id]: payload.chatroom
      };
    case atypes.LOAD_CHATROOMS_SUCCESS:
      return payload.chatRooms;
    case atypes.SRW_CHATROOM_METADATA_SUCCESS:
      return {
        ...state,
        ...payload
      };
    case atypes.REFETCH_ALL_CONVERSATIONS_SUCCESS:
      return {
        ...payload,
        ...state
      };
    case atypes.FETCH_CHATROOM_DETAILS_SUCCESS:
      return {
        ...state,
        ...payload
      };
    case atypes.SET_CHATROOM_ATTRIBUTE_FAILURE:
    case atypes.CHANGE_CHATROOM_ATTRIBUTE:
      const updated = {
        ...state,
        [payload.roomId]: {
          ...state[payload.roomId],
          ...payload.value
        }
      };
      return updated;
    case atypes.REMOVE_CHATROOM:
      return R.omit([payload.roomId], state);
    case atypes.BULK_UPDATE_PROCESS_OPTIMISTIC:
      return {
        ...state,
        ...R.mergeAll(
          R.map(
            c => ({ [`${c}`]: { ...state[`${c}`], ...payload.value } }),
            payload.chatrooms
          )
        )
      };
    case atypes.BULK_UPDATE_PROCESS_FAILURE:
      return {
        ...state,
        ...R.mergeAll(
          R.map(
            c => ({ [c.id]: { ...state[c.id], ...c.value } }),
            payload.oldValues
          )
        )
      };
    case atypes.LOAD_CHATROOM_METADATA:
      return R.mergeDeepRight(
        state,
        R.mergeAll(
          payload
            .filter(room => isValidChatroom(room))
            .map(room => ({
              [room.id]: room
            }))
        )
      );
    case atypes.REMOVE_DELETED_ROOMS:
      return R.omit(payload || [], state || {});
    /* Remove all the chatrooms that belong to deleted workflows.
       We're doing this to prevent the conversations from showing
       up in the chat list. */
    case atypes.REMOVE_WORKFLOW_SUCCESS:
      return R.omit(
        R.keys(state || {}).filter(roomId =>
          R.includes(state[roomId]?.templateId, payload.workflows)
        ),
        state || {}
      );
    default:
      return state;
  }
};

const favourites = (
  state: ImmutableSet<number> = ImmutableSet(),
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SYNC_USER_CHATROOM_ATTRIBUTES_SUCCESS:
      if (state.hashCode() !== ImmutableSet(payload.favourites).hashCode()) {
        return state.union(payload.favourites);
      }
      return state;
    case atypes.ADD_TO_FAVOURITE:
      return state.add(payload);
    case atypes.REMOVE_FROM_FAVOURITE:
      return state.delete(payload);
    default:
      return state;
  }
};

const fillConversations = data => {
  const invertedData = R.invert(
    // eslint-disable-next-line no-unused-vars
    R.mapObjIndexed((val, key) => val.parent, data)
  );
  const parents = R.reject(R.equals("undefined"), R.keys(invertedData));
  return R.mergeAll(
    R.map(x => ({ [x]: R.map(parseInt, invertedData[x]) }), parents)
  );
};

const loading = (state = false, { type }: Action) => {
  switch (type) {
    case atypes.SHOW_CHATROOM_LINK:
      return true;
    case atypes.HIDE_CHATROOM_LINK:
      return false;
    default:
      return state;
  }
};

/**
 * Reducer which stores conversations by their parents. Perpetual
 * chats without any parents will be ignored.
 */
const byParent = (state = {}, { type, payload }: Action) => {
  switch (type) {
    case atypes.LOAD_CHATROOMS_SUCCESS:
    case atypes.BATCH_LOAD_ROOMS_SUCCESS:
      return R.mergeDeepWith(
        R.concat,
        state,
        fillConversations(payload.chatRooms)
      );
    case atypes.REFETCH_ALL_CONVERSATIONS_SUCCESS:
      return R.mergeDeepWith(R.concat, state, fillConversations(payload));
    default:
      return state;
  }
};

const isAddFile = (state: boolean = false, { type, payload }: Action) => {
  switch (type) {
    case atypes.TOGGLE_CHATROOM_ADD:
      return payload.isAddFile;
    case atypes.UPLOAD_FILE_TO_CHAT_REQUEST:
      return false;
    default:
      return state;
  }
};

const initialCustomStatus: ImmutableSetType<number> = ImmutableSet([]);

export const initialFilterState = {
  text: "",
  name: "My Conversations",
  type: ["task", "group", "conversation", "workflow", "approval", "direct"],
  cancelled: false,
  active: "all",
  workflow: null,
  parent: null,
  mine: true,
  favourite: false,
  approved: false,
  ultimateParent: false,
  sortBy: "updatedAt",
  owner: false,
  unread: false,
  customStatuses: initialCustomStatus,
  overdue: false,
  critical: false,
  archived: false
};

const currentFilter = (
  state: Object = initialFilterState,
  { type, payload = {} }: Action
) => {
  switch (type) {
    case atypes.SET_QUERY_ACTIVE:
      return {
        ...state,
        active: payload.active
      };
    case atypes.SET_QUERY_TEXT:
      return {
        ...state,
        text: payload.text
      };
    case atypes.CLEAR_FILTER_TYPE:
      return {
        ...state,
        type: initialFilterState.type
      };
    case atypes.CLEAR_QUERY_TEXT:
      return {
        ...state,
        text: ""
      };
    case atypes.SET_QUERY_TYPE:
      return {
        ...state,
        type: [...state.type, payload.type]
      };
    case atypes.SET_SINGLE_QUERY_TYPE:
      return {
        ...state,
        type: [payload.type],
        name: payload.name
      };
    case atypes.REMOVE_QUERY_TYPE:
      return {
        ...state,
        type: R.reject(R.equals(payload.type), state.type)
      };
    case atypes.SET_QUERY_FILTER:
      if (payload.filter) {
        return {
          ...payload.filter,
          name: payload.name
        };
      }

      return state;
    case atypes.SET_MINE:
      return {
        ...state,
        mine: payload.mine,
        name: payload.name
      };
    case atypes.SET_APPROVED:
      return { ...state, approved: payload.approved };
    case atypes.SET_ULTIMATE_PARENT:
      return {
        ...state,
        ultimateParent: payload.ultimateParent
      };
    case atypes.SAVE_PINNED_LIST_SUCCESS: {
      return { ...state, name: payload.name };
    }
    case atypes.SET_OWNER:
      return {
        ...state,
        owner: payload.owner
      };
    case atypes.SET_UNREAD:
      return {
        ...state,
        unread: payload.unread
      };
    case atypes.TRIGGER_FILTER_UPDATE:
      return {
        ...state,
        ...payload
      };
    case atypes.SET_NEW_CHATS:
      return { ...state, newChats: payload.newChats };
    case atypes.SET_QUERY_RELATED_CONVERSATION:
      return { ...state, parent: payload.parent };
    case atypes.SET_FAVOURITE_FILTER:
      return { ...state, favourite: payload.favourite };
    case atypes.SET_WORKFLOW_FILTER:
      return {
        ...state,
        workflow: payload.workflow,
        name: payload.name
      };
    case atypes.SET_MESSAGE_FILTER:
      return {
        ...state,
        messages: payload.messages,
        fieldId: payload.fieldId
      };
    case atypes.CLEAR_MESSAGE_FILTER:
      return R.dissoc("fieldId", { ...R.dissoc("messages", state) });
    case atypes.SET_HOME_SCREEN_REQUEST:
    case atypes.CLEAR_ACTIVE_FILTER:
      return initialFilterState;
    case atypes.SET_SORT_BY:
      return {
        ...state,
        sortBy: payload.sortBy
      };
    case atypes.SET_CUSTOM_STATUS:
      return {
        ...state,
        customStatuses: payload.id
      };
    default:
      return state;
  }
};

const initialActiveFilter = {
  type: "default",
  value: "My Conversations"
};

/**
 * activeFilter reducer
 * @param {Object} state Current state
 * @param {Action} action Action to the reducer
 */
const activeFilter = (
  state: Object = initialActiveFilter,
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SET_ACTIVE_FILTER:
      return payload;
    case atypes.CLEAR_ACTIVE_FILTER:
      return initialActiveFilter;
    default:
      return state;
  }
};

const initialMembershipState = ImmutableSet();

const membership = (
  state: Membership = initialMembershipState,
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.ADD_ROOM_MEMBER_REQUEST:
      if (payload.request) {
        return state;
      }
      return state.union([parseInt(payload.roomId, 10)]);
    case atypes.ADD_ROOM_MEMBER_REQUEST_FAILURE:
      return payload.request
        ? state.remove(parseInt(payload.roomId, 10))
        : state;
    case atypes.GET_USER_MEMBERSHIP_SUCCESS:
      if (
        state.hashCode() !== ImmutableSet(payload.membership || []).hashCode()
      ) {
        return state.union(payload.membership);
      }
      return state;
    case atypes.REMOVE_USER_MEMBERSHIP_SUCCESS:
      return state.remove(payload.membership);
    case atypes.SRW_CURRENT_CHATROOM:
      return state.union([payload]);
    default:
      return state;
  }
};

const showFilter = (state: boolean = false, { type }: Action) => {
  switch (type) {
    case atypes.SHOW_FILTER_DIALOG:
      return true;
    case atypes.HIDE_FILTER_DIALOG:
    case atypes.SAVE_PINNED_LIST_SUCCESS:
      return false;
    case atypes.TOGGLE_FILTER_DIALOG:
      return !state;
    default:
      return state;
  }
};

const saveModal = (state: boolean = false, { type }: Action) => {
  switch (type) {
    case atypes.HIDE_SAVE_PINNED_LIST:
    case atypes.SAVE_PINNED_LIST_SUCCESS:
    case atypes.HIDE_EDIT_FILTER_MODAL:
      return false;
    case atypes.SHOW_SAVE_PINNED_LIST:
      return true;
    default:
      return state;
  }
};

const sortbyModal = (state: boolean = false, { type }: Action) => {
  switch (type) {
    case atypes.HIDE_SORT_BY_MODAL:
      return false;
    case atypes.SHOW_SORT_BY_MODAL:
      return true;
    default:
      return state;
  }
};

const statusModal = (state: boolean = false, { type }: Action) => {
  switch (type) {
    case atypes.HIDE_STATUS_MODAL:
      return false;
    case atypes.SHOW_STATUS_MODAL:
      return true;
    default:
      return state;
  }
};

const savePinnedListError = (state: boolean = false, { type }: Action) => {
  switch (type) {
    case atypes.SAVE_PINNED_LIST_FAILURE:
    case atypes.EDIT_PINNED_LIST_FAILURE:
      return true;
    case atypes.HIDE_SAVE_PINNED_LIST:
    case atypes.SAVE_PINNED_LIST_REQUEST:
    case atypes.EDIT_PINNED_LIST_REQUEST:
      return false;
    default:
      return state;
  }
};

const editFilterModal = (state: boolean = false, { type }: Action) => {
  switch (type) {
    case atypes.SHOW_EDIT_FILTER_MODAL:
      return true;
    case atypes.HIDE_EDIT_FILTER_MODAL:
    case atypes.EDIT_PINNED_LIST_SUCCESS:
      return false;
    default:
      return state;
  }
};

const currentClickedSavedFilter = (
  state: string = "",
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SHOW_TO_BE_EDITED_FILTER_NAME:
      return payload.name;
    default:
      return state;
  }
};

const showMember = (state: boolean = false, { type }: Action) => {
  switch (type) {
    case atypes.SHOW_MEMBERS:
      return true;
    case atypes.HIDE_MEMBERS:
      return false;
    case atypes.TOGGLE_MEMBERS:
      return !state;
    default:
      return state;
  }
};

const searchResults = (
  state: Array<RoomId> = [],
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SEARCH_CHATROOM_SUCCESS:
      return payload.result;
    case atypes.RESET_CHATROOM_SEARCH_SUCCESS:
      return payload.chatrooms;
    default:
      return state;
  }
};

const memberDialog = (state: MemberDialog = {}, { type, payload }: Action) => {
  switch (type) {
    case atypes.SHOW_MEMBERS:
      return payload;
    case atypes.HIDE_MEMBERS:
      return {};
    case atypes.TOGGLE_MEMBERS:
      return R.isEmpty(state) ? payload : {};
    default:
      return state;
  }
};

const savedFilters = (state: SavedFilter = [], { type, payload }: Action) => {
  switch (type) {
    case atypes.SYNC_PINNED_LIST_SUCCESS:
      return payload.filters;
    default:
      return state;
  }
};

const focusChatInput = (
  state: ChatInputFocus = { focusId: "", roomId: "" },
  { type, payload }
) => {
  switch (type) {
    case atypes.FOCUS_CHAT_INPUT:
      return { focusId: uuidv4(), roomId: payload.roomId };
    case atypes.RESET_CHAT_INPUT_FOCUS:
      return { focusId: "", roomId: "" };
    default:
      return state;
  }
};

const preserveChatInput = (state: PreservedChatInput[] = [], action) => {
  switch (action.type) {
    case atypes.PRESERVE_CHAT_INPUT:
      const { roomId, message } = action.payload;
      const updatedInputs = state.map(input => {
        if (input.roomId === roomId) {
          return { roomId, message };
        }
        return input;
      });
      if (!updatedInputs.some(input => input.roomId === roomId)) {
        updatedInputs.push({ roomId, message });
      }
      return updatedInputs;
    case atypes.CLEAR_CHAT_INPUT:
      const { roomId: id } = action.payload;
      const clearedInputs = state.filter(input => input.roomId !== id);
      return clearedInputs;
    default:
      return state;
  }
};

const readMessageCount = (
  state: ReadMessageCount = ImmutableMap(),
  { type, payload }
) => {
  switch (type) {
    case atypes.SYNC_USER_CHATROOM_ATTRIBUTES_SUCCESS:
      if (state.hashCode() !== ImmutableMap(payload.count).hashCode()) {
        return state.merge(ImmutableMap(payload.count));
      }
      return state;
    case atypes.INCREMENT_MESSAGE_COUNT:
      return state.set(payload.roomId, payload.count + 1);
    default:
      return state;
  }
};

const chatroomEmail = (state: string = "", { type, payload }) => {
  switch (type) {
    case atypes.GENERATE_CHATROOM_EMAIL_SUCCESS: {
      return payload;
    }
    case atypes.SET_CURRENT_CHATROOM_SUCCESS:
      return "";
    default:
      return state;
  }
};

const searchedQuickFilterWorkflows = (
  state: Array<number> = [],
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.UPDATE_SEARCHED_QUICK_FILTER_WORKFLOWS:
      return payload.updatedWorkflows;
    default:
      return state;
  }
};

const byStatus = (state: SegmentedByStatus = {}, { type, payload }: Action) => {
  switch (type) {
    case atypes.UPDATE_SEGMENTED_LIST_BY_STATUS:
      return payload.segmentedStatus;
    case atypes.TRIGGER_FILTER_UPDATE:
      return {};
    default:
      return state;
  }
};

const byPriority = (
  state: SegmentedByPriority = {},
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.UPDATE_SEGMENTED_LIST_BY_PRIORITY:
      return payload.segmentedStatus;
    case atypes.TRIGGER_FILTER_UPDATE:
      return {};
    default:
      return state;
  }
};

const suggestedWorkflows = (
  state: SuggestedWorkflows = [],
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.GET_SUGGESTED_WORKFLOW_SUCCESS:
    case atypes.UPDATE_SUGGESTED_WORKFLOWS_FAILURE:
      return payload.suggestedWorkflows;
    case atypes.UPDATE_SUGGESTED_WORKFLOWS_REQUEST:
      return R.take(5, R.uniq(R.prepend(payload.id, state)));
    default:
      return state;
  }
};
const segmentedStatus = combineReducers({
  byStatus,
  byPriority
});

const filters = combineReducers({
  templateId,
  current: currentFilter,
  activeFilter,
  show: showFilter,
  saveModal,
  savePinnedListError,
  saved: savedFilters,
  sortbyModal,
  statusModal,
  editFilterModal,
  currentClickedSavedFilter,
  searchedQuickFilterWorkflows,
  suggestedWorkflows,
  segmentedStatus
});

const showSelect = (
  state: ShowSelect = { selected: "", roomId: "" },
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SET_DELETE_OPTIONS:
      return { selected: "DELETE", roomId: payload.roomId };
    case atypes.SET_FORWARD_OPTIONS:
      return { selected: "FORWARD", roomId: payload.roomId };
    case atypes.SET_MOBILE_OPTIONS:
      return { selected: "MOBILE", roomId: payload.roomId };
    case atypes.HIDE_SELECT_OPTIONS:
    case atypes.SET_CURRENT_CHATROOM_SUCCESS:
    case atypes.CLEAR_MESSAGE_SELECTION:
    case atypes.FORWARD_MESSAGE_SUCCESS:
      return { selected: "", roomId: "" };
    default:
      return state;
  }
};

const selectedMessages = (
  state: Array<MessageID> = [],
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SELECT_MESSAGE:
      return [...state, payload.id];
    case atypes.DESELECT_MESSAGE:
      return state.filter(message => message !== payload.id);
    case atypes.HIDE_SELECT_OPTIONS:
    case atypes.CLEAR_MESSAGE_SELECTION:
    case atypes.FORWARD_MESSAGE_SUCCESS:
    case atypes.SET_CURRENT_CHATROOM_SUCCESS:
      return [];
    default:
      return state;
  }
};

const deletableMessages = (
  state: Array<{ id: MessageID, timestamp: Date }> = [],
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SELECT_MESSAGE: {
      const { id, timestamp, isOwner } = payload;
      if (
        isOwner &&
        moment.duration(moment().diff(moment(timestamp))).asMinutes() < 15
      )
        return [...state, { id, timestamp }];
      return state;
    }
    case atypes.DESELECT_MESSAGE:
      return state.filter(message => message.id !== payload.id);
    case atypes.HIDE_SELECT_OPTIONS:
    case atypes.CLEAR_MESSAGE_SELECTION:
    case atypes.FORWARD_MESSAGE_SUCCESS:
    case atypes.SET_CURRENT_CHATROOM_SUCCESS:
      return [];
    default:
      return state;
  }
};

const selectedRooms = (
  state: Array<RoomId> = [],
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SELECT_ROOM:
      return [...state, payload.id];
    case atypes.DESELECT_ROOM:
      return state.filter(room => room !== payload.id);
    case atypes.CLEAR_ROOM_SELECTION:
    case atypes.HIDE_FORWARD_MODAL:
    case atypes.FORWARD_MESSAGE_SUCCESS:
      return [];
    default:
      return state;
  }
};

const tempFiles = (state: any = [], { type, payload }: Action) => {
  switch (type) {
    case atypes.UPLOAD_FILE_TO_TEMP_STORAGE_SUCCESS:
      return [...state, payload];
    case atypes.SET_CURRENT_CHATROOM_SUCCESS:
    case atypes.CLEAR_ALL_TEMP_ATTACHMENTS:
      return [];
    default:
      return state;
  }
};

const commonMembers = (state: Array<UID> = [], { type, payload }: Action) => {
  switch (type) {
    case atypes.GET_COMMON_MEMBERS_REQUEST:
      return [];
    case atypes.GET_COMMON_MEMBERS_SUCCESS:
      return payload.members;
    default:
      return state;
  }
};

const workflowTitle = (
  state: WorkflowTitle = {},
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SYNC_WORKFLOWS_SUCCESS:
      return {
        ...state,
        ...R.mergeAll(R.map(w => ({ [w.id]: w.title }), payload.workflows))
      };
    case atypes.DELETE_WORKFLOW_SUCCESS:
      return R.dissoc(payload.workflow, state);
    default:
      return state;
  }
};

const workflowSettings = (
  state: WorkflowSettings = {},
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SYNC_WORKFLOWS_SUCCESS:
      return {
        ...state,
        ...R.mergeAll(
          R.map(w => ({ [w.id]: w.settings || {} }), payload.workflows)
        )
      };
    default:
      return state;
  }
};

const breadcrumbs = (state: Breadcrumbs = {}, { type, payload }: Action) => {
  switch (type) {
    case atypes.CREATE_CHATROOM_SUCCESS:
      return {
        ...state,
        ...{ [payload.id]: payload.breadcrumbs }
      };
    case atypes.LOAD_CHATROOM_SUCCESS:
    case atypes.FETCH_CHATROOM_UPDATES_SUCCESS:
      if (payload.breadcrumbs) {
        return {
          ...state,
          ...payload.breadcrumbs
        };
      }
      return state;
    case atypes.SRW_CHATROOM_ATTRIBUTES:
      return { ...state, ...payload.breadcrumbs };
    default:
      return state;
  }
};

const userNames = (state: UserNames = {}, { type, payload }: Action) => {
  switch (type) {
    case atypes.SYNC_USERS_SUCCESS:
      return {
        ...state,
        ...R.mergeAll(
          R.map(
            uid => ({
              [uid]: {
                displayName: payload.users[uid].displayName,
                email: payload.users[uid].email
              }
            }),
            R.keys(payload.users)
          )
        )
      };
    default:
      return state;
  }
};

const files = (state: ChatroomFiles = {}, { type, payload }: Action) => {
  switch (type) {
    case atypes.CREATE_CHATROOM_SUCCESS:
      return {
        ...state,
        ...{ [payload.id]: payload.files }
      };
    case atypes.LOAD_CHATROOM_SUCCESS:
    case atypes.FETCH_CHATROOM_UPDATES_SUCCESS:
      if (payload.files) {
        return {
          ...state,
          ...payload.files
        };
      }
      return state;
    case atypes.SRW_CHATROOM_ATTRIBUTES:
      return { ...state, ...payload.files };
    default:
      return state;
  }
};

const relatedConversationAttribute = (
  state: RelatedConversationsById = {},
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.CREATE_CHATROOM_SUCCESS:
      return {
        ...state,
        ...{ [payload.id]: payload.relatedConversations }
      };
    case atypes.LOAD_CHATROOM_SUCCESS:
    case atypes.FETCH_CHATROOM_UPDATES_SUCCESS:
      if (payload.relatedConversations) {
        return {
          ...state,
          ...payload.relatedConversations
        };
      }
      return state;
    case atypes.SRW_CHATROOM_ATTRIBUTES:
      return { ...state, ...payload.relatedConversations };
    default:
      return state;
  }
};

const checklists = (
  state: { [RoomId]: Array<ChecklistHeader> } = {},
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.CREATE_CHATROOM_SUCCESS:
      return {
        ...state,
        ...{ [payload.id]: payload.checklists }
      };
    case atypes.LOAD_CHATROOM_SUCCESS:
    case atypes.FETCH_CHATROOM_UPDATES_SUCCESS:
      if (payload.checklists) {
        return {
          ...state,
          ...payload.checklists
        };
      }
      return state;
    case atypes.SRW_CHATROOM_ATTRIBUTES:
      return { ...state, ...payload.checklists };
    default:
      return state;
  }
};

const childCount = (state: ChildCount = {}, { type, payload }: Action) => {
  switch (type) {
    case atypes.CREATE_CHATROOM_SUCCESS:
      return {
        ...state,
        ...{ [payload.id]: payload.childCount }
      };
    case atypes.LOAD_CHATROOM_SUCCESS:
      if (payload.childCount) {
        return {
          ...state,
          ...payload.childCount
        };
      }
      return state;
    default:
      return state;
  }
};

const chatroomAttributes = combineReducers({
  breadcrumbs,
  files,
  relatedConversations: relatedConversationAttribute,
  checklists,
  childCount
});

const relatedConversationSearch = (
  state: Array<RoomId> = [],
  { type, payload }
) => {
  switch (type) {
    case atypes.SEARCH_RELATED_CONVERSATIONS_SUCCESS:
      return payload;
    default:
      return state;
  }
};

const initialRelatedCount = {
  child: {
    overdue: 0,
    completed: 0,
    pending: 0,
    owned: 0
  },
  parent: {
    overdue: 0,
    completed: 0,
    pending: 0,
    owned: 0
  }
};

const relatedCount = (
  state: RelatedCount = initialRelatedCount,
  { type, payload }
) => {
  switch (type) {
    case atypes.SET_RELATED_COUNT:
      return payload;
    case atypes.SET_CURRENT_CHATROOM_SUCCESS:
      return initialRelatedCount;
    default:
      return state;
  }
};

const directMessages = (
  state: DirectMessages = {},
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.LOAD_DIRECT_CONVERSATIONS:
      return { ...state, ...payload };
    default:
      return state;
  }
};

const currentUserId = (state: ?UID = null, { type, payload }: Action) => {
  switch (type) {
    case atypes.SIGNED_IN:
    case atypes.SRW_SIGNED_IN:
      return payload.uid;
    case atypes.SIGNED_OUT:
      return null;
    default:
      return state;
  }
};

const creating = (state: Array<number> = [], { type, payload }: Action) => {
  switch (type) {
    case atypes.CREATE_CHATROOM_SUCCESS:
      return [...state, parseInt(payload.id, 10)];
    case atypes.GET_USER_MEMBERSHIP_SUCCESS:
      return R.difference(state, payload.membership || []);
    default:
      return state;
  }
};

const lastFetched = (state: ?String = null, { type, payload }: Action) => {
  switch (type) {
    case atypes.FETCH_CHATROOM_UPDATES_SUCCESS:
    case atypes.SET_LAST_FETCHED_TIME:
      return payload.lastFetched;
    default:
      return state;
  }
};

const chatRooms = combineReducers<Object, Action>({
  lastFetched,
  creating,
  current,
  currentRoomAccessStatus,
  currentUserId,
  chatroomEmail,
  lastMessage,
  byId,
  byParent,
  isAddFile,
  loading,
  filters,
  membership,
  favourites,
  showMember,
  searchResults,
  readMessageCount,
  focusChatInput,
  preserveChatInput,
  showSelect,
  selectedMessages,
  deletableMessages,
  selectedRooms,
  tempFiles,
  commonMembers,
  memberDialog,
  workflowTitle,
  workflowSettings,
  attributes: chatroomAttributes,
  relatedConversationSearch,
  relatedCount,
  userNames,
  directMessages,
  statusesById
});

export default chatRooms;

export const preservedInputState = (state: AppState) =>
  state.chatRooms.preserveChatInput;

export const getPreservedInput = createSelector(
  preservedInputState,
  (_, roomId) => roomId,
  (preservedInputs, roomId) =>
    preservedInputs.find(input => input.roomId === roomId)
);

export const getChatRoomsById = (state: UnifizeChatRooms) => state.byId;

export const getUserMembership = (state: UnifizeChatRooms) => [
  ...state.membership
];

export const getChatRoom = (state: UnifizeChatRooms, roomId: string) =>
  state.byId[`${roomId}`];

export const getActiveState = createSelector(getChatRoom, room =>
  room ? room.active : null
);

export const getOutcome = createSelector(getChatRoom, room =>
  room ? room.outcome : null
);

export const getCanceled = createSelector(getChatRoom, room =>
  room ? room.canceled : null
);

export const getChatroomType = createSelector(getChatRoom, room =>
  room ? room.type : ""
);

export const getChatroomTitle = createSelector(getChatRoom, room =>
  room ? room.title : ""
);

export const getCreator = createSelector(getChatRoom, room =>
  room ? room.creator : ""
);

export const getCreatedDate = createSelector(getChatRoom, room =>
  room ? room.createdAt : null
);

export const getCompletedBy = createSelector(getChatRoom, room =>
  room ? room.completedBy : ""
);

export const getChatroomPrivacy = createSelector(getChatRoom, room =>
  room ? room.privacy : null
);

export const getDescription = createSelector(getChatRoom, room =>
  room ? room.description : ""
);

export const getChatroomPriority = createSelector(getChatRoom, room =>
  room ? room.priority : ""
);

export const getCreationTime = createSelector(getChatRoom, room =>
  room ? room.createdAt : null
);

export const getUpdationTime = createSelector(getChatRoom, room =>
  room ? room.updatedAt : null
);

export const getCompletionTime = createSelector(getChatRoom, room =>
  room ? room.completedAt : null
);

export const getMessageCount = createSelector(getChatRoom, room =>
  room ? room.count : 0
);

export const getChatroomParticipants = createSelector(
  getChatRoom,
  room => room?.members || []
);

const getWorkflows = (state: UnifizeChatRooms) => state.workflowTitle;

export const getProcessTitle = createSelector(
  [getChatRoom, getWorkflows],
  (room, workflow) => (room ? workflow[`${room.templateId}`] : "")
);

export const getWorkflowName = (state: UnifizeChatRooms, id: string) =>
  state.workflowTitle[`${id}`];

export const getSequenceNo = createSelector(getChatRoom, room =>
  room ? room.autoNo || room.seqNo : ""
);

export const isCurrentVersion = createSelector(getChatRoom, room =>
  room ? room.versionCount > 1 && room.currentVersion === true : false
);

export const getVersionNo = createSelector(
  getChatRoom,
  room => room.version || (room.autoNo || "").split("/")[1] || "1"
);

export const getSeqNoForSearchedGroupedFiles = createSelector(
  getChatRoom,
  room => (room ? room.seqNo : "")
);

export const getTemplateId = createSelector(getChatRoom, room =>
  room ? room.templateId : ""
);

export const getParent = createSelector(getChatRoom, room =>
  room ? room.parent : null
);

export const getDueDate = (state: UnifizeChatRooms, roomId: string) =>
  state.byId[roomId] ? state.byId[roomId].dueDate : null;

export const getRelatedConversations = (
  state: UnifizeChatRooms,
  roomId: RoomId
) => state.attributes.relatedConversations[`${roomId}`];

export const getChildCount = (state: UnifizeChatRooms, roomId: RoomId) =>
  state.attributes.childCount[`${roomId}`];

export const getDirectChildren = createSelector(
  getRelatedConversations,
  relatedConversations => {
    const children = (relatedConversations || {}).children || [];
    return R.sort((a, b) => parseInt(a, 10) - parseInt(b, 10), children);
  }
);

export const getChildren = createSelector(
  getRelatedConversations,
  relatedConversations => {
    const directChildren = (relatedConversations || {}).children || [];
    const fosterChildren = (relatedConversations || {}).fosterChildren || [];

    return R.sort(
      (a, b) => parseInt(a, 10) - parseInt(b, 10),
      R.uniq<any>([...directChildren, ...fosterChildren])
    );
  }
);

export const getChildrenCount = createSelector(
  getChildren,
  children => children.length
);

export const getParents = createSelector(
  getRelatedConversations,
  relatedConversations => {
    const { parent } = relatedConversations || {};
    const fosterParents = (relatedConversations || {}).fosterParents || [];
    const parents = [...(fosterParents || [])];

    if (parent) {
      parents.push(parent);
    }

    return R.sort(
      (a, b) => parseInt(a, 10) - parseInt(b, 10),
      R.uniq<any>(parents)
    );
  }
);

export const getParentsCount = createSelector(
  getParents,
  parents => parents.length
);

export const getChatroomOwner = (state: UnifizeChatRooms, room: RoomId) =>
  (state.byId[room] || { owner: "" }).owner;

export const getErrorMessage = (state: UnifizeChatRooms) => state.errorMessage;

export const getCurrentRoom = (state: UnifizeChatRooms) => state.current;

export const getIsAddFile = (state: UnifizeChatRooms) => state.isAddFile;

export const getReadMessageCount = (state: UnifizeChatRooms, roomId: string) =>
  state.readMessageCount.get(roomId);

export const getBreadCrumbs = (state: UnifizeChatRooms, roomId: RoomId) =>
  state.attributes.breadcrumbs[`${roomId}`];

export const getChatroomChecklists = (
  state: UnifizeChatRooms,
  roomId: RoomId
) => state.attributes.checklists[`${roomId}`];

export const getChatRoomFiles = (state: UnifizeChatRooms, roomId: RoomId) =>
  state.attributes.files[`${roomId}`];

export const getSelectedAction = (state: UnifizeChatRooms) =>
  state.showSelect.selected;

export const getSelectedRoomId = (state: UnifizeChatRooms) =>
  state.showSelect.roomId;

export const getCurrentChatRoomFiles = (
  state: UnifizeChatRooms,
  roomId: RoomId,
  fileById: Object,
  includeFileDetails?: boolean
) =>
  // $FlowFixMe
  (state.attributes.files[`${roomId}`] || [])
    .map((id: string): Object => fileById[id])
    .sort(
      (a: Object, b: Object) =>
        new Date(a.uploadTime).getTime() - new Date(b.uploadTime).getTime()
    )
    .map((file: Object) => (includeFileDetails ? file : file?.name));

export const getRoomFileIds = (state: UnifizeChatRooms, roomId: RoomId) =>
  state.attributes.files[roomId] || [];

export const getChatroomFileCount = createSelector(getChatRoomFiles, files => {
  return (files || []).length;
});

const sortDescend: Function = (sort: string) => R.sort(R.descend(R.prop(sort)));
const sortAscend: Function = (sort: string) => R.sort(R.ascend(R.prop(sort)));

const priority = {
  low: -1,
  normal: 0,
  high: 1,
  critical: 2
};

const getCurrentChatRoom = (state: UnifizeChatRooms) => state.current;

// TODO: Optimize selectors to reduce number of times the
// the selector is running while switching chatRooms.

const allPredicates = (
  currentUser: UID,
  workflows: Object,
  filter: CurrentFilter,
  // eslint-disable-next-line no-shadow
  membership: Membership,
  // eslint-disable-next-line no-shadow
  readMessageCount: ReadMessageCount,
  // eslint-disable-next-line no-shadow
  chatRooms: UnifizeChatRoomById,
  // eslint-disable-next-line no-shadow
  favourites: ImmutableSet<string>,
  users: UserNames,
  customStatuses: ImmutableSet<number>,
  current: string,
  templateStatus: TemplateStatus
) => {
  if (R.isEmpty(chatRooms)) {
    return [];
  }

  const rooms =
    R.filter(
      R.allPass([
        filterByMine(filter, membership),
        filterByUnread(filter, readMessageCount, current),
        filterByApproved(filter),
        filterByOwner(filter, currentUser),
        filterByWorkflow(filter),
        filterByParent(filter),
        filterByText(filter, workflows, users, currentUser),
        filterByType(filter),
        filterByFavourite(filter, favourites),
        filterByCompletion(filter, customStatuses),
        filterByCritical(filter),
        filterByOverdue(filter),
        filterByNewChats(filter, readMessageCount, membership),
        filterByCancelled(filter),
        filterByArchived(filter, templateStatus)
      ]),
      R.values(chatRooms)
    ) || [];

  const { sortBy } = filter;
  let sortedRooms = [];
  switch (sortBy) {
    case "updatedAt":
      sortedRooms = sortDescend("updatedAt")(
        R.map(
          (x: UnifizeChatRoom) => ({
            id: x.id,
            updatedAt: new Date(x.updatedAt ? x.updatedAt : 0).getTime()
          }),
          rooms
        )
      );
      break;
    case "updatedAt:ascending":
      sortedRooms = sortAscend("updatedAt")(
        R.map(
          (x: UnifizeChatRoom) => ({
            id: x.id,
            updatedAt: new Date(x.updatedAt ? x.updatedAt : 0).getTime()
          }),
          rooms
        )
      );
      break;
    case "priority":
      sortedRooms = sortDescend("priority")(
        R.map(
          (x: UnifizeChatRoom) => ({
            id: x.id,
            priority: x && x.priority ? priority[x.priority] || 0 : 0
          }),
          rooms
        )
      );
      break;
    case "priority:ascending":
      sortedRooms = sortAscend("priority")(
        R.map(
          (x: UnifizeChatRoom) => ({
            id: x.id,
            priority: x && x.priority ? priority[x.priority] || 0 : 0
          }),
          rooms
        )
      );
      break;
    case "createdAt":
      sortedRooms = sortDescend("createdAt")(
        R.map(
          (x: UnifizeChatRoom) => ({
            id: x.id,
            createdAt: new Date(x.createdAt ? x.createdAt : "").getTime()
          }),
          rooms
        )
      );
      break;
    case "createdAt:ascending":
      sortedRooms = sortAscend("createdAt")(
        R.map(
          (x: UnifizeChatRoom) => ({
            id: x.id,
            createdAt: new Date(x.createdAt ? x.createdAt : "").getTime()
          }),
          rooms
        )
      );
      break;
    case "dueDate:ascending":
      sortedRooms = sortDescend("dueDate")(
        R.map(
          (x: UnifizeChatRoom) => ({
            id: x.id,
            dueDate: new Date(x.dueDate ? x.dueDate : "").getTime()
          }),
          rooms
        )
      );
      break;
    case "status":
      sortedRooms = sortDescend("active")(
        R.map(
          (x: UnifizeChatRoom) => ({
            ...x,
            active: x && x.active ? 1 || 0 : 0
          }),
          rooms
        )
      );
      break;
    case "status:ascending":
      sortedRooms = sortAscend("active")(
        R.map(
          (x: UnifizeChatRoom) => ({
            ...x,
            active: x && x.active ? 1 || 0 : 0
          }),
          rooms
        )
      );
      break;
    default:
      sortedRooms = [
        ...sortAscend("dueDate")(
          R.map(
            (x: UnifizeChatRoom) => ({
              id: x.id,
              dueDate: new Date(x.dueDate ? x.dueDate : "").getTime()
            }),
            R.filter(y => y.dueDate !== null && !R.isEmpty(y.dueDate), rooms)
          )
        ),
        ...R.map(
          (x: UnifizeChatRoom): Object => ({ id: x.id }),
          R.reject(y => y.dueDate !== null && !R.isEmpty(y.dueDate), rooms)
        )
      ];
  }

  return List(R.map(R.prop("id"), sortedRooms));
};

export const getCurrentFilter = (state: UnifizeChatRooms) =>
  state.filters.current;

export const getCurrentFilterSortBy = (state: UnifizeChatRooms) =>
  getCurrentFilter(state).sortBy;

export const getCurrentFilterWorkflow = (state: UnifizeChatRooms) =>
  getCurrentFilter(state).workflow;

const getMembership = (state: UnifizeChatRooms) => state.membership;
const getFavourites = (state: UnifizeChatRooms) => state.favourites;
export const getCustomStatuses = (state: UnifizeChatRooms) =>
  state.filters.current.customStatuses;
const getAllReadMessageCount = (state: UnifizeChatRooms) =>
  state.readMessageCount;

export const getUserNames = (state: UnifizeChatRooms) => state.userNames;
const getCurrentUserId = (state: UnifizeChatRooms) => state.currentUserId;

const getTemplateStatus = (_, templateStatus: TemplateStatus) => templateStatus;

export const getFilteredChatRooms = createSelector(
  getCurrentUserId,
  getWorkflows,
  getCurrentFilter,
  getMembership,
  getAllReadMessageCount,
  getChatRoomsById,
  getFavourites,
  getUserNames,
  getCustomStatuses,
  getCurrentChatRoom,
  getTemplateStatus,
  allPredicates
);

export const getFilteredConversations = (
  filter: Object,
  templateStatus: TemplateStatus
) =>
  createSelector(
    getCurrentUserId,
    getWorkflows,
    getMembership,
    getAllReadMessageCount,
    getChatRoomsById,
    getUserNames,
    () => filter.customStatuses,
    (
      currentUser: UID,
      workflows: Object,
      // eslint-disable-next-line no-shadow
      membership: Membership,
      // eslint-disable-next-line no-shadow
      readMessageCount: ReadMessageCount,
      // eslint-disable-next-line no-shadow
      chatRooms: UnifizeChatRoomById,
      users: UserNames,
      customStatuses: ImmutableSet<number>
    ) => {
      if (R.isEmpty(chatRooms)) {
        return [];
      }

      const rooms =
        R.filter(
          R.allPass([
            filterByMine(filter, membership),
            filterByUnread(filter, readMessageCount),
            filterByApproved(filter),
            filterByOwner(filter, currentUser),
            filterByWorkflow(filter),
            filterByCompletion(filter, customStatuses),
            filterByOverdue(filter),
            filterByCancelled(filter),
            filterByArchived(filter, templateStatus)
          ]),
          R.values(chatRooms)
        ) || [];

      const { sortBy } = filter;
      let sortedRooms = [];
      switch (sortBy) {
        case "updatedAt":
          sortedRooms = sortDescend("updatedAt")(
            R.map(
              (x: UnifizeChatRoom) => ({
                id: x.id,
                updatedAt: new Date(x.updatedAt ? x.updatedAt : 0).getTime()
              }),
              rooms
            )
          );
          break;
        case "updatedAt:ascending":
          sortedRooms = sortAscend("updatedAt")(
            R.map(
              (x: UnifizeChatRoom) => ({
                id: x.id,
                updatedAt: new Date(x.updatedAt ? x.updatedAt : 0).getTime()
              }),
              rooms
            )
          );
          break;
        case "priority":
          sortedRooms = sortDescend("priority")(
            R.map(
              (x: UnifizeChatRoom) => ({
                id: x.id,
                priority: x && x.priority ? priority[x.priority] || 0 : 0
              }),
              rooms
            )
          );
          break;
        case "priority:ascending":
          sortedRooms = sortAscend("priority")(
            R.map(
              (x: UnifizeChatRoom) => ({
                id: x.id,
                priority: x && x.priority ? priority[x.priority] || 0 : 0
              }),
              rooms
            )
          );
          break;
        case "createdAt":
          sortedRooms = sortDescend("createdAt")(
            R.map(
              (x: UnifizeChatRoom) => ({
                id: x.id,
                createdAt: new Date(x.createdAt ? x.createdAt : "").getTime()
              }),
              rooms
            )
          );
          break;
        case "createdAt:ascending":
          sortedRooms = sortAscend("createdAt")(
            R.map(
              (x: UnifizeChatRoom) => ({
                id: x.id,
                createdAt: new Date(x.createdAt ? x.createdAt : "").getTime()
              }),
              rooms
            )
          );
          break;
        case "dueDate:ascending":
          sortedRooms = sortDescend("dueDate")(
            R.map(
              (x: UnifizeChatRoom) => ({
                id: x.id,
                dueDate: new Date(x.dueDate ? x.dueDate : "").getTime()
              }),
              rooms
            )
          );
          break;
        case "status":
          sortedRooms = sortDescend("active")(
            R.map(
              (x: UnifizeChatRoom) => ({
                ...x,
                active: x && x.active ? 1 || 0 : 0
              }),
              rooms
            )
          );
          break;
        case "status:ascending":
          sortedRooms = sortAscend("active")(
            R.map(
              (x: UnifizeChatRoom) => ({
                ...x,
                active: x && x.active ? 1 || 0 : 0
              }),
              rooms
            )
          );
          break;
        default:
          sortedRooms = [
            ...sortAscend("dueDate")(
              R.map(
                (x: UnifizeChatRoom) => ({
                  id: x.id,
                  dueDate: new Date(x.dueDate ? x.dueDate : "").getTime()
                }),
                R.filter(
                  y => y.dueDate !== null && !R.isEmpty(y.dueDate),
                  rooms
                )
              )
            ),
            ...R.map(
              (x: UnifizeChatRoom): Object => ({ id: x.id }),
              R.reject(y => y.dueDate !== null && !R.isEmpty(y.dueDate), rooms)
            )
          ];
      }

      return R.map(R.prop("id"), sortedRooms);
    }
  );

export const getDirectMessages = (state: UnifizeChatRooms) =>
  state.directMessages;

export const getFilteredDirectMessages = createSelector(
  [getUserNames, getCurrentFilter, getDirectMessages],
  (users, filter, directMessages) => {
    const uids = R.keys(directMessages);
    const search = R.toLower(filter.text || "");

    const result = R.filter(
      uid =>
        !R.includes(uid, uids) &&
        R.includes(
          search,
          R.toLower(users[uid].displayName || users[uid].email || "")
        ),
      R.keys(users)
    );

    // Sort Users alphabetically
    return result.sort((firstUserName, secondUserName) => {
      let firstName = "";
      let secondName = "";
      const firstUser = users[firstUserName];
      const secondUser = users[secondUserName];
      if (firstUser && secondUser) {
        firstName = R.toLower(firstUser.displayName || firstUser.email || "");
        secondName = R.toLower(
          secondUser.displayName || secondUser.email || ""
        );
      }

      return firstName < secondName ? -1 : firstName > secondName ? 1 : 0;
    });
  }
);

export const getChatrooms = createSelector(
  getChatRoomsById,
  (state: UnifizeChatRoomById) => R.values(state)
);

export const getCurrentRoomId = (state: UnifizeChatRooms) => state.current;

export const getCurrentRoomDescription = createSelector(
  getCurrentRoomId,
  getChatRoomsById,
  // eslint-disable-next-line no-shadow
  (id: string, byId: UnifizeChatRoomById) =>
    byId[`${id}`] ? byId[`${id}`].description : ""
);

export const getFilterText = (state: UnifizeChatRooms) =>
  state.filters.current.text;

export const getCurrentRoomOwner = createSelector(
  getChatRoomsById,
  getCurrentRoom,
  // eslint-disable-next-line no-shadow
  (byId: UnifizeChatRoomById, current: number) =>
    byId[`${current}`] ? byId[`${current}`].owner : null
);

export const getRoomOwner = (state: UnifizeChatRooms, roomId: string) =>
  state.byId[roomId] ? state.byId[roomId].owner : null;

export const getTotalRelatedCount = createSelector(
  getRelatedConversations,
  relatedConversation => {
    const count = (relatedConversation || {}).count || 0;
    return count;
  }
);

export const getChatRoomEmail = (state: UnifizeChatRooms) =>
  state.chatroomEmail;

export const getFilteredChatroomCount = createSelector(
  getFilteredChatRooms,
  rooms => rooms.size
);

export const getFilteredUnreadConversationCount = createSelector(
  getFilteredChatRooms,
  getChatRoomsById,
  getAllReadMessageCount,
  (
    filteredChatrooms: OrderedSet<string>,
    chatroomsById: UnifizeChatRoomById,
    readCount: ReadMessageCount
  ) => {
    return filteredChatrooms.reduce((count, roomId) => {
      if (
        chatroomsById[roomId] &&
        chatroomsById[roomId].count - (readCount.get(roomId) || 0) > 0
      ) {
        return count + 1;
      }
      return count;
    }, 0);
  }
);

export const getChatroomMembership = (
  state: UnifizeChatRooms,
  roomId: RoomId
) => state.membership.includes(parseInt(roomId, 10));

export const getCurrentChatroomMembership = createSelector(
  [getMembership, getCurrentChatRoom],
  (membership, currentRoomId) => {
    return membership.includes(parseInt(currentRoomId, 10));
  }
);

export const getChatroomAddress = (state: UnifizeChatRooms, roomId: RoomId) =>
  (state.byId[roomId] || {}).address;

export const getTempFile = (state: UnifizeChatRooms, id: string) =>
  R.find(file => file.name === id, state.tempFiles);

const getLastMessage = (state: UnifizeChatRooms, roomId: RoomId) =>
  state.lastMessage[`${roomId}`] || {};

export const getLastMessageAuthor = createSelector(
  getLastMessage,
  (message: LastMessage) => message.author
);

export const getLastMessageText = createSelector(
  getLastMessage,
  (message: LastMessage) => message.text
);

export const getIsCurrentRoom = (state: UnifizeChatRooms, roomId: RoomId) =>
  state.current === roomId;

export const getChatroomStatus = createSelector(getChatRoom, room =>
  room ? room.status : ""
);

export const getChatroomReminders = createSelector(getChatRoom, room =>
  room ? room.reminders : null
);

export const getChatroomManualArchived = createSelector(getChatRoom, room =>
  room ? room.archived : null
);

export const getSortByModal = (state: UnifizeChatRooms) =>
  state.filters.sortbyModal;

export const getStatusModal = (state: UnifizeChatRooms) =>
  state.filters.statusModal;

export const getCurrentFilterActive = (state: UnifizeChatRooms) =>
  state.filters.current.active;

export const getToogleUnread = (state: UnifizeChatRooms) =>
  state.filters.current.unread;

export const getIsQuickFilterOpen = (state: UnifizeChatRooms) =>
  state.filters.show;

export const getCurrentActiveFilterName = (state: UnifizeChatRooms) =>
  state.filters.current.name;

export const getSuggestedWorkflows = (state: UnifizeChatRooms) =>
  R.take(3, state.filters.suggestedWorkflows);

export const sortConversation = (
  state: Object,
  sortBy: string,
  value: Array<number>
): Array<number> => {
  if (sortBy === "added") {
    return value;
  }

  if (value && (value || []).length > 1) {
    return value.sort(
      (a: number, b: number): number =>
        // Prettier is removing the parans causing issue
        // prettier-ignore
        (new Date(state.byId[b]?.createdAt)).getTime() - 
        (new Date(state.byId[a]?.createdAt)).getTime()
    );
  }

  return value;
};

export const getChatroomSearchResults = (state: UnifizeChatRooms) =>
  state.searchResults;

export const getCurrentRoomAccess = (state: UnifizeChatRooms) =>
  state.currentRoomAccessStatus;

export const getFilteredChatroomExcluding = createSelector(
  [
    getChatroomSearchResults,
    (state: UnifizeChatRooms, exclude: Array<number>) => exclude
  ],
  (searchResults, exclude) => {
    const results = R.map(roomId => parseInt(roomId, 10), searchResults || []);

    const exclusion = R.map(roomId => parseInt(roomId, 10), exclude || []);

    return R.difference(results, exclusion);
  }
);

// Return current versions of the chatrooms
// excluding the current versions of the rooms
// that are in the selectedVersions list
export const filterCurrentVersions = (
  state: UnifizeChatRooms,
  results: number[],
  selectedVersions: ?(number[])
): number[] => {
  return R.filter(roomId => {
    const room = state.byId[`${roomId}`];

    if (!room?.currentVersion) return false;

    if (!selectedVersions) return true;

    const versionSibling = selectedVersions.find(
      versionId => state.byId[`${versionId}`].seqNo === room?.seqNo
    );

    return !versionSibling;
  }, results);
};

const areMutualVersions = (
  roomsById: UnifizeChatRoomById,
  room1Id: number,
  room2Id: number
) => {
  const room1 = roomsById[`${room1Id}`];
  const room2 = roomsById[`${room2Id}`];
  return room1.seqNo === room2.seqNo && room2.templateId === room2.templateId;
};

const getUnifizeChatroomsById = (state: AppState) => state.chatRooms.byId;

export const getMutualVersions = createSelector(
  [getUnifizeChatroomsById, (_, params) => params],
  (roomsById, params) => {
    const { linkedField, roomId } = params;
    return (linkedField ?? []).filter(item =>
      areMutualVersions(roomsById, item.chatroom.id, roomId)
    );
  }
);

export const getMututalVersionsAndCurrentVersionId = createSelector(
  [getChatRoom, getChatrooms],
  (currentRoom, allRooms) => {
    let currentVersionId;

    const versions = allRooms.filter(room => {
      const isVersionOfCurrentRoom =
        room.type === "workflow" &&
        room.seqNo === currentRoom.seqNo &&
        room.templateId === currentRoom.templateId;

      if (isVersionOfCurrentRoom && room.currentVersion) {
        currentVersionId = room.id;
      }

      return isVersionOfCurrentRoom;
    });

    return { versions, currentVersionId: Number(currentVersionId) };
  }
);

export const getChecklistFieldCount = createSelector(
  getChatroomChecklists,
  chatroomChecklists => {
    return chatroomChecklists ? chatroomChecklists[0].fieldCount : 0;
  }
);

export const getIsChecklistPresent = createSelector(
  getChatroomChecklists,
  chatroomChecklists => {
    return !!chatroomChecklists;
  }
);

export const getAllChecklists = (state: UnifizeChatRooms) =>
  state.attributes.checklists;

export const getCurrentChecklist = createSelector(
  [getAllChecklists, getCurrentRoomId],
  (checklists, currentRoomId) => {
    return checklists?.[`${currentRoomId ?? ""}`] ?? {};
  }
);

export const getFirstChecklist = createSelector(
  [getCurrentChecklist],
  checklist => {
    return checklist[0];
  }
);

export const getArchivedConversations = createSelector(
  [getChatRoomsById, (state, checklistValue: ChecklistValue) => checklistValue],
  (roomsById, checklistValue) => {
    const chatroomsById = checklistValue?.value.entities.chatrooms || {};
    // Resolve linked chatroom ids from checklist value
    const chatroomIds = extractChatroomIds(chatroomsById);

    const archived: Array<number> =
      chatroomIds &&
      chatroomIds
        .filter((id: RoomId) => roomsById[id] && roomsById[id].archived)
        .map((id: RoomId) => parseInt(id));

    return archived;
  }
);
const getWorkflowTitles = (state: UnifizeChatRooms): WorkflowTitle =>
  state.workflowTitle;

export const getCurrentRoomWorkflowTitle = createSelector(
  [getCurrentRoom, getWorkflowTitles],
  (currentRoom: UnifizeChatRoom, workflowTitles: WorkflowTitle) => {
    return workflowTitles[`${currentRoom.templateId ?? ""}`];
  }
);

export const getCurrentFilterTemplateId = (state: UnifizeChatRooms) =>
  state.filters.templateId;

export const getSelectedMessages = (state: UnifizeChatRooms) =>
  state.selectedMessages;

export const getCurrentChatroomAddress = createSelector(
  [getChatRoomsById, getCurrentRoom],
  (roomsById: UnifizeChatRoomById, currentRoomId: string) => {
    const currentRoom = roomsById[currentRoomId] || {};
    return currentRoom.address || "";
  }
);

export const getFocusChatInput = (state: UnifizeChatRooms) =>
  state.focusChatInput;

export const getRoomLoadingState = (state: UnifizeChatRooms) =>
  state.statusesById;

export const getIsRoomLoading = createSelector(
  [getCurrentChatroomAddress, getRoomLoadingState],
  (currentChatroomAddress, roomLoadingState) => {
    const isAddressPresent = R.has(currentChatroomAddress)(roomLoadingState);

    if (isAddressPresent) {
      return roomLoadingState[currentChatroomAddress];
    }

    return false;
  }
);
