// @flow

import { connect } from "react-redux";
import React from "react";

import { AddButton } from "../styles";
import { conversationTypes } from "src/constants/display";
import WorkflowName from "src/containers/workflow/Name";

import { getWorkflowNextSeqNo } from "src/reducers";
import type { AppState, ConversationChecklistSettings } from "src/types";

type Props = {
  settings: ConversationChecklistSettings,
  isActive?: boolean,
  seqNo: number,
  handleDropdown: Function,
  disabled: boolean,
  isMandatory: ?boolean
};

const AddConversation = ({
  settings,
  isActive,
  // eslint-disable-next-line no-unused-vars
  seqNo,
  handleDropdown,
  disabled,
  isMandatory
}: Props) => {
  const type = settings.type || "group";
  const { workflow } = settings;
  if (type === "workflow" && workflow) {
    return (
      <AddButton
        type="button"
        isActive={isActive}
        onClick={handleDropdown}
        disabled={disabled}
        isMandatory={isMandatory}
      >
        &#43; Add <WorkflowName id={workflow} />
      </AddButton>
    );
  }

  return (
    <AddButton
      type="button"
      onClick={handleDropdown}
      disabled={disabled}
      isMandatory={isMandatory}
    >
      &#43; Add {conversationTypes[type][0]}
    </AddButton>
  );
};

AddConversation.defaultProps = {
  isActive: false
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  seqNo: getWorkflowNextSeqNo(app)
});

export default connect(mapStateToProps)(AddConversation);
