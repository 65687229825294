// @flow

import { connect } from "react-redux";
import React from "react";

import Icon from "src/icons";
import { getChatroomPrivacy, getChatroomType } from "src/reducers";

import type { AppState } from "src/types";

type Props = {
  privacy?: string,
  type: string
};

const Privacy = ({ privacy, type }: Props) => {
  if ((privacy === "full" || privacy === "content") && type !== "direct") {
    return <Icon type="lock" />;
  }
  return <></>;
};

Privacy.defaultProps = {
  privacy: null
};

const mapStateToProps = ({ app }: { app: AppState }, props) => ({
  privacy: getChatroomPrivacy(app, props.roomId),
  type: getChatroomType(app, props.roomId)
});

export default connect(mapStateToProps)(Privacy);
