// @flow

import styled from "styled-components";
import * as colors from "src/styles/constants/colors";
import * as radius from "src/styles/constants/radius";
import * as spacing from "src/styles/constants/spacing";

const Container = styled.div`
  width: 100%;
  position: relative;
  padding: ${spacing.space_xs};
`;

const Selection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const Label = styled.label`
  font-size: 14px;
  width: fit-content;
`;

const SelectedItems = styled.div`
  display: inline-flex;
  border: 1px solid ${colors.grey35};
  min-height: 2rem;
  max-height: 12rem;
  overflow-y: scroll;
  border-radius: ${radius.medium};
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  padding: 3px;
  background-color: white;
`;

const SelectedItem = styled.span`
  display: flex;
  align-items: center;
  height: 24px;
  gap: 4px;
  background-color: ${colors.grey10};
  padding: 0 0.25rem;
  font-size: 12px;
  &:hover {
    background-color: ${colors.grey2};
  }
  cursor: pointer;
`;

const RemoveButton = styled.span`
  padding: 0 0.25rem;
`;

const InputContainer = styled.div`
  display: flex;
  gap: 0.125rem;
  flex-grow: 1;
  background-color: red;
`;

const Input = styled.input`
  width: 100%;
  border: none;
  font-size: 14px;
  ::placeholder {
    font-size: 14px;
  }
`;

const DropdownMenu = styled.ul`
  position: absolute;
  width: inherit;
  background-color: white;
  margin-top: 0.25rem;
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

  max-height: 12rem;
  overflow-y: scroll;
  padding: 0;
  z-index: 10;
`;

const DropdownItem = styled.li`
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 8px;
  &.highlighted {
    background-color: ${colors.grey10};
  }
  &.selected {
    font-weight: bold;
    background-color: ${colors.grey10};
  }
`;

const DropdownItemButton = styled.button`
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  background-color: white;
  &:disabled {
    color: ${colors.grey40};
    cursor: not-allowed;
  }
  &:hover:enabled {
    text-decoration: underline;
  }
  &:active:enabled {
    font-weight: bold;
    background-color: ${colors.grey10};
    text-decoration: none;
  }
`;

const DropdownText = styled.div`
  display: flex;
  flex-direction: column;
`;

const ItemTitle = styled.span`
  font-size: 12px;
`;

const ItemSubTitle = styled.span`
  font-size: 10px;
  color: ${colors.grey5};
`;

export {
  Container,
  Selection,
  Label,
  SelectedItems,
  SelectedItem,
  RemoveButton,
  InputContainer,
  Input,
  DropdownMenu,
  DropdownItem,
  DropdownItemButton,
  DropdownText,
  ItemTitle,
  ItemSubTitle
};
