// @flow

import React from "react";
import { Portal, Text } from "@chakra-ui/react";
import Privacy from "src/components/Dock/Panels/Privacy";

import type { RoomId } from "src/types";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalClose,
  ModalOverlay
} from "src/components/Modal";
type Props = {
  roomId: RoomId,
  isSrw?: boolean,
  isOpen: boolean,
  closeModal: Function,
  portalRef?: null | React$ElementRef<Portal>
};

const PrivacyModal = ({
  roomId,
  isSrw,
  portalRef,
  isOpen,
  closeModal
}: Props) => {
  return (
    <ModalOverlay isOpen={isOpen} closeModal={closeModal} portalRef={portalRef}>
      <Modal isOpen={isOpen}>
        <ModalHeader>
          <Text as="span">Privacy</Text>
          <ModalClose closeModal={closeModal} />
        </ModalHeader>

        <ModalBody>
          <Privacy room={roomId} isSrw={isSrw} />
        </ModalBody>
      </Modal>
    </ModalOverlay>
  );
};

export default PrivacyModal;
