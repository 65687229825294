// @flow

import { connect, useDispatch, useSelector } from "react-redux";
import * as R from "ramda";
import React, { useCallback, useRef } from "react";
import { useDropzone } from "react-dropzone";
import uuid from "uuid/v4";
import { setChecklistValue } from "src/actions/checklist";
import {
  getChecklistFormValue,
  getFormFieldHidden,
  getFormFieldValueStatus,
  getChecklistFieldDetails
} from "src/reducers";
import { uploadFileToChecklist } from "src/actions/file";
import FieldLabel from "./FieldLabel";
import FieldComponent from "./FieldComponent";
import useFields from "./useFields";
import { FieldContainer } from "src/components/Dock/Checklist/styles";
import { Container } from "./styles";
import type { FieldId, RoomId, ChecklistId } from "src/types";
import { toast } from "react-toastify";
import SkeletonLoader from "src/components/LoadingState";
import { dataStages } from "src/constants";

type Props = {
  roomId: RoomId,
  id: FieldId,
  checklistId?: ChecklistId,
  formId?: ?number,
  isSectionField?: boolean
};

const Field = ({ roomId, id, formId, isSectionField }: Props) => {
  const labelRef = useRef();
  const dispatch = useDispatch();
  const roomFieldFormId = formId ? `${roomId}-${id}-${formId}` : "";

  const details = useSelector(({ app }) =>
    getChecklistFieldDetails(app, `${id}`)
  );
  const valueStatus = useSelector(({ app }) =>
    getFormFieldValueStatus(app, id, formId)
  );
  const checklistValue = useSelector(({ app }) =>
    getChecklistFormValue(app, roomFieldFormId)
  );
  const hidden = useSelector(({ app }) =>
    getFormFieldHidden(app, roomFieldFormId)
  );

  const type = details ? details.get("type") : "";
  const { settings, value } = useFields({
    checklistValue,
    details
  });

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 0) {
        toast.error("Cannot upload multiple files to single file field.");
        return;
      }

      const storageFileName = uuid();

      dispatch(
        uploadFileToChecklist({
          fileData: settings?.multiple ? acceptedFiles : acceptedFiles[0],
          roomId: parseInt(roomId, 10),
          storageFileName,
          fieldId: id,
          value: (value || []).map(file =>
            typeof file === "object" ? file.name : file
          ),
          multiple: settings?.multiple,
          location: "checklist-upload",
          dispatch,
          formId: formId ?? 0
        })
      );
    },
    [roomId, id, value, settings, formId]
  );

  const { getRootProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: settings?.multiple ? 0 : 1
  });
  const dragAndDropProps = type === "file" ? getRootProps() : {};

  if (type !== "section" && R.isNil(valueStatus)) return null;

  if (type !== "section" && valueStatus && valueStatus <= dataStages.fetching)
    return <SkeletonLoader type="checklistField" />;

  return (
    !hidden &&
    (type === "section" ? null : (
      <FieldContainer
        {...dragAndDropProps}
        isDragActive={isDragActive}
        formId={formId}
      >
        <FieldLabel roomId={roomId} fieldId={id} labelRef={labelRef} />
        <Container isSectionField={isSectionField}>
          <FieldComponent
            roomId={roomId}
            formId={formId}
            fieldId={id}
            roomFieldFormId={roomFieldFormId}
          />
        </Container>
      </FieldContainer>
    ))
  );
};

Field.defaultProps = {
  formId: null
};

export default connect(null, {
  _setChecklistValue: setChecklistValue,
  _uploadFileToChecklist: uploadFileToChecklist
})(Field);
