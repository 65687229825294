// @flow

import * as React from "react";
import { useRef, useCallback } from "react";

import useBoolean from "src/hooks/useBoolean";
import { Box, StylesProvider, useMultiStyleConfig } from "@chakra-ui/react";

import Selected from "./Selected";
import Dropdown from "./Dropdown";

type Props = {
  value: string | number | Array<string> | Array<number> | null,
  label?: ?string | ?React.Node,
  placeholder?: string,
  multiple: boolean,
  nullable: boolean,
  dropdownProps?: Object,
  children: React.Node,
  onChange: Function,
  onSelectAll?: Function,
  onClearAll?: Function,
  itemPredicate?: Function,
  customSearchHandler?: Function
};

const Select = ({
  value,
  label,
  placeholder,
  multiple,
  nullable,
  children,
  onChange,
  onSelectAll,
  onClearAll,
  dropdownProps,
  itemPredicate,
  customSearchHandler,
  ...restProps
}: Props) => {
  const styles = useMultiStyleConfig("CustomSelect2");

  const outerRef = useRef(null);
  const {
    value: showDropdown,
    toggleBoolean: toggleDropdown,
    setFalse: hideDropdown
  } = useBoolean();

  const handleSelect = useCallback(
    (newValue: any) => {
      if (!multiple) {
        hideDropdown();
      }

      onChange(newValue);
    },
    [onChange, hideDropdown]
  );

  return (
    <Box ref={outerRef} position="relative" {...restProps}>
      <StylesProvider value={styles}>
        <Selected
          value={label || value}
          placeholder={placeholder}
          isOpen={showDropdown}
          toggleDropdown={toggleDropdown}
          onSelect={handleSelect}
          nullable={nullable}
        />

        {showDropdown && (
          <Dropdown
            multiple={multiple}
            value={value}
            items={children}
            outerRef={outerRef}
            handleSelect={handleSelect}
            handleClose={hideDropdown}
            onSelectAll={onSelectAll}
            onClearAll={onClearAll}
            itemPredicate={itemPredicate}
            customSearchHandler={customSearchHandler}
            {...dropdownProps}
          />
        )}
      </StylesProvider>
    </Box>
  );
};

Select.defaultProps = {
  multiple: false,
  nullable: false
};

export default Select;
