// @flow
import React from "react";

import UserTag from "./UserTag";
import GroupTag from "./GroupTag";
import type { UID, GroupId } from "src/types";
import { VStack } from "@chakra-ui/react";

type UsersAndGroups = {
  id?: GroupId,
  uid?: UID,
  type: string
};
type Props = {
  usersAndGroups: Array<UsersAndGroups>,
  handleRemove: (id: UID | GroupId) => void
};

const MultipleSelection = ({ usersAndGroups, handleRemove }: Props) => {
  return (
    <VStack
      sx={{
        alignItems: "stretch"
      }}
    >
      {usersAndGroups.map((userOrGroup, index) => {
        if (userOrGroup.type === "user") {
          return (
            userOrGroup.uid && (
              <UserTag
                key={`user-${index}`}
                uid={userOrGroup.uid}
                handleRemove={handleRemove}
              />
            )
          );
        } else {
          return (
            userOrGroup.id && (
              <GroupTag
                key={`group-${index}`}
                id={userOrGroup.id}
                handleRemove={handleRemove}
              />
            )
          );
        }
      })}
    </VStack>
  );
};

export default MultipleSelection;
