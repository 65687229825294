// @flow

import React, { Component } from "react";
import * as R from "ramda";
import { HStack } from "@chakra-ui/react";

import Process from "src/containers/chatroom/creator/process";
import Direct from "src/containers/chatroom/creator/direct";
import Feed from "src/containers/chatroom/creator/feed";
import Approval from "src/containers/chatroom/creator/approval";
import Task from "src/containers/chatroom/creator/task";
import Discussion from "src/containers/chatroom/creator/discussion";
import ButtonLoader from "src/components/ButtonLoader";
import { conversationTypes } from "src/constants/display";
import { Error } from "src/styles";
import Icon from "src/icons";
import * as colors from "src/styles/constants/colors";
import { Form } from "src/styles/form";
import ConversationIcon from "./ConversationIcon";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalContent,
  ModalOverlay
} from "@chakra-ui/react";

import * as styles from "./styles";

import type { RoomId, UID, Email, GroupId } from "src/types";

type Member = UID | Email;

type Props = {
  modalType?: string,
  loading: boolean,
  type: string,
  error: ?string,
  templateTitle: ?string,
  position: string,
  currentRoom: RoomId,
  currentUser: UID,
  members: Array<Member>,
  groups: Array<GroupId>,
  settings?: Object,
  setConversationAttribute: Function,
  setConversationAttributes: Function,
  clearConversationDialog: Function,
  createDirectConversation: Function,
  handleClose: Function,
  startConversation: Function,
  createChatroom: Function,
  autoPopulateParent?: boolean,
  workflowSettings: Object,
  dueDate: String
};

type State = {
  advanced: boolean
};

class Creator extends Component<Props, State> {
  static defaultProps = {
    modalType: "group",
    autoPopulateParent: false,
    settings: {}
  };

  state = {
    advanced: false
  };

  componentDidMount() {
    const {
      modalType,
      setConversationAttribute,
      setConversationAttributes,
      currentRoom,
      settings,
      autoPopulateParent,
      groups
    } = this.props;

    if (groups) {
      setConversationAttribute({
        id: "groups",
        value: groups
      });
    }

    if (!R.isEmpty(settings)) {
      if ((settings || {}).workflow) {
        setConversationAttributes({
          type: "workflow",
          templateId: parseInt((settings || {}).workflow, 10)
        });
      } else if ((settings || {}).type) {
        setConversationAttributes({
          type: (settings || {}).type || "group"
        });
      }

      if (autoPopulateParent) {
        setConversationAttribute({
          id: "parent",
          value: parseInt(currentRoom, 10)
        });
      }
      if (!modalType) {
        this.setState({ advanced: true });
      }
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { modalType, setConversationAttribute } = this.props;
    if (modalType !== prevProps.modalType && modalType) {
      setConversationAttribute({ id: "type", value: modalType });
    }

    const { loading, error, handleClose } = this.props;
    if (
      loading !== null &&
      prevProps.loading !== loading &&
      !loading &&
      !error
    ) {
      handleClose();
    }
  }

  componentWillUnmount() {
    const { clearConversationDialog } = this.props;
    clearConversationDialog();
  }

  toggleAdvanced = () => {
    this.setState(prevState => ({ advanced: !prevState.advanced }));
  };

  showAdvanced = () => {
    this.setState({ advanced: true });
  };

  hideAdvanced = () => {
    this.setState({ advanced: false });
  };

  handleOwner = (value: ?UID | ?Email, member: ?boolean = true) => {
    const { members, setConversationAttributes } = this.props;

    if (value && member) {
      setConversationAttributes({
        owner: value,
        members: R.uniq([value, ...(members || [])])
      });
    } else {
      setConversationAttributes({ owner: value });
    }
  };

  handleChange = (id: string, value: string) => {
    const { setConversationAttribute } = this.props;
    setConversationAttribute({ id, value });
  };

  handleGroups = (group: GroupId) => {
    const { groups, setConversationAttribute } = this.props;

    if (R.includes(group, groups)) {
      setConversationAttribute({
        id: "groups",
        value: R.reject(R.equals(group), groups)
      });
    } else {
      setConversationAttribute({
        id: "groups",
        value: R.uniq([...groups, group])
      });
    }
  };

  handleMembers = (member: UID) => {
    const { members, setConversationAttribute } = this.props;

    if (R.includes(member, members)) {
      setConversationAttribute({
        id: "members",
        value: R.reject(R.equals(member), members)
      });
    } else {
      setConversationAttribute({
        id: "members",
        value: R.uniq([...members, member])
      });
    }
  };

  handleParent = (value: RoomId) => {
    const { setConversationAttribute } = this.props;
    setConversationAttribute({
      id: "parent",
      value: parseInt(value, 10)
    });
  };

  handleDueDate = (value: ?string) => {
    const { setConversationAttribute } = this.props;
    setConversationAttribute({ id: "dueDate", value });
  };

  handleClose() {
    const { loading, error, handleClose } = this.props;
    if (loading !== null && !loading && !error) {
      handleClose();
    }
  }

  createChatroom = (e: any) => {
    e.preventDefault();
    const {
      members,
      currentUser,
      startConversation,
      createChatroom,
      createDirectConversation,
      setConversationAttributes,
      setConversationAttribute,
      type,
      workflowSettings,
      dueDate
    } = this.props;
    const { dueIn } = workflowSettings;

    if (startConversation) {
      startConversation();
    } else if (type === "direct") {
      if (members.length > 0) {
        const uid = R.head(members);
        if (uid !== currentUser) {
          setConversationAttributes({ loading: true, error: false });
          createDirectConversation(uid);
        } else {
          setConversationAttributes({ error: "You cant chat with yourself" });
        }
      } else {
        setConversationAttributes({ error: "Choose user to chat with" });
      }
    } else {
      // Set due date if due date mention in process builder and not changed
      // from advanced due date
      if (dueIn && !dueDate) {
        const currentDate = new Date();
        let dueDateAsPerSettings = currentDate.setDate(
          currentDate.getDate() + Number(dueIn || 0)
        );
        dueDateAsPerSettings = new Date(dueDateAsPerSettings);
        setConversationAttribute({
          id: "dueDate",
          value: dueDateAsPerSettings
        });
      }
      createChatroom();
    }
  };

  getFormBody = (type: string) => {
    const props = {
      ...this.state,
      ...this.props,
      showAdvanced: this.showAdvanced,
      hideAdvanced: this.hideAdvanced,
      handleOwner: this.handleOwner,
      handleChange: this.handleChange,
      handleMembers: this.handleMembers,
      handleGroups: this.handleGroups,
      handleParent: this.handleParent,
      handleDueDate: this.handleDueDate
    };

    switch (type) {
      case "workflow":
        return <Process {...props} />;
      case "group":
        return <Feed {...props} />;
      case "approval":
        return <Approval {...props} />;
      case "task":
        return <Task {...props} />;
      case "conversation":
        return <Discussion {...props} />;
      case "direct":
        return <Direct {...props} />;
      default:
        return <Feed {...props} />;
    }
  };

  render() {
    const {
      type,
      error,
      loading,
      position,
      templateTitle,
      handleClose
    } = this.props;
    const heading = templateTitle
      ? `Start New ${templateTitle || ""}`
      : `Start New ${type ? conversationTypes[type][0] || "Feed" : "Feed"}`;

    const modalPosition =
      position === "left"
        ? {
            position: "absolute",
            top: "54px",
            left: "75px"
          }
        : {};

    return (
      <Modal
        size="xl"
        sx={{
          minHeight: "185px",
          position: "fixed",
          top: "0px"
        }}
        onClose={handleClose}
        isOpen={true}
      >
        <ModalOverlay />
        <ModalContent
          sx={{
            padding: "0",
            ...modalPosition
          }}
        >
          <ModalHeader sx={styles.ModalHeader}>
            <ConversationIcon type={type} heading={heading} />
            <Icon
              type="close"
              color={colors.primary}
              handleClick={handleClose}
              size="18px"
              cursor="pointer"
            />
          </ModalHeader>

          <ModalBody
            sx={{
              padding: 0
            }}
          >
            <Form data-cy="createRoom" onSubmit={this.createChatroom}>
              {this.getFormBody(type)}
              <div>{error ? <Error>{error}</Error> : null}</div>

              <HStack p={4} sx={styles.Footer}>
                <ButtonLoader type="submit" isLoading={!error && loading}>
                  {type === "direct" ? "Start Direct Conversation" : "Create"}
                </ButtonLoader>
              </HStack>
            </Form>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }
}

export default Creator;
