// @flow

import styled from "@emotion/styled";

import * as colors from "src/styles/constants/colors";
import { FullScreen } from "src/styles/modal";

const Contacts = styled(FullScreen)`
  .ReactModal__Content--after-open {
    > h2:first-of-type {
      padding-left: 0.8em;
      margin-bottom: 1.3em;
      svg {
        display: inline-block;
        margin-right: 0.8em;
      }
    }
  }
`;

const Header = styled.div`
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: 1fr auto;
  grid-gap: 730px;
  margin-bottom: 1.4em;
  margin-top: 3em;
  padding-left: 1em;
`;

const Row = styled.tr`
  > td {
    background: ${props =>
      props.selected ? colors.grey0 : colors.brightLight};
  }
  > td:first-of-type {
    padding-left: 2em;
  }
`;

export { Contacts, Header, Row };
