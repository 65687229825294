// @flow

import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import {
  Portal,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  HStack,
  Text
} from "@chakra-ui/react";

import { getFilePreview } from "src/actions/file";
import {
  getFileSize,
  getFilePreviewSupport,
  getFileSourceDisplayName
} from "src/utils/file";
import { getFilePreviewURL } from "src/reducers";

import * as styles from "./styles";
import PreviewUnavailable from "./PreviewUnavailable";
import FilePreviewWrapper from "./FilePreviewWrapper";

import type { UnifizeFile, RoomId } from "src/types";

type Props = {
  file: UnifizeFile,
  triggerMode?: "hover" | "click",
  children: React$Element<*>,
  roomId: RoomId,
  settings: string
};

const HoverPreview = ({ file, triggerMode = "hover", children, roomId, settings }: Props) => {
  const dispatch = useDispatch();
  const fileName = file?.originalName;
  const fileSource = file?.source;
  const settingsJSON = useMemo(() => {
    try {
      return JSON.parse(settings);
    } catch (err) {
      console.error(err);
      return {};
    }
  }, [settings])

  const isPreviewEnabled = settingsJSON?.preview ?? false;

  const filePreview = useSelector(({ app }) =>
    getFilePreviewURL(app, file?.name)
  );

  const hasPreviewSupport = getFilePreviewSupport(file);

  // Fetch the file preview
  useEffect(() => {
    if (isPreviewEnabled && hasPreviewSupport && (!filePreview || moment().diff(filePreview?.date, "minutes") > 10 && file.name)) {
      dispatch(getFilePreview({ name: file.name, roomId }))
    }
  }, []);

  const fileUploadedTime = file?.uploadTime
    ? moment(file?.uploadTime).format("MMM DD, YYYY")
    : "";
  const fileSourceDisplayName = getFileSourceDisplayName(fileSource);

  return (
    <Popover trigger={triggerMode}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <Portal>
        <PopoverContent {...styles.popover.content}>
          <PopoverBody {...styles.popover.body}>
            <Text {...styles.popover.fileName}>{fileName}</Text>

            <PreviewUnavailable hasPreviewSupport={hasPreviewSupport} isPreviewEnabled={isPreviewEnabled} />
            <FilePreviewWrapper isPreviewEnabled={isPreviewEnabled} file={file} />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default HoverPreview;
