// @flow

import React from "react";

import { Item as StyledItem } from "src/components/Dock/Checklist/Conversation/styles";
import Name from "src/components/Form/Name";

type Props = {
  id: number,
  selectForm: Function
};

const Item = ({ id, selectForm }: Props) => (
  <li onClick={() => selectForm(id)}>
    <StyledItem>
      <Name id={id} />
    </StyledItem>
  </li>
);

export default Item;
