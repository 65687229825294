// @flow

import { connect } from "react-redux";
import React from "react";
import {
  getChatroomTitle,
  getChatroomType,
  getSequenceNo,
  getProcessTitle,
  getCanceled,
  getChatroomAddress,
  getTemplateId
} from "src/reducers";
import { Name as StyledName } from "./styles";
import type { AppState } from "src/types";
import Link from "redux-first-router-link";
import * as atypes from "src/constants/actionTypes";
import { openConversationModal } from "src/actions/chatroom";

type Props = {
  id: number,
  templateId: ?number,
  name: string,
  type: string,
  seqNo: number,
  processTitle: ?string,
  address: string,
  canceled: boolean,
  noTitle?: boolean,
  children?: any,
  isBreadcrumb?: boolean,
  _openConversationModal: Function,
  handleClose: Function,
  hideLink?: boolean
};

const Name = ({
  id,
  templateId,
  name,
  type,
  processTitle,
  canceled,
  seqNo,
  address,
  noTitle,
  _openConversationModal,
  handleClose,
  hideLink
}: Props) => {
  let heading = name;
  const titleAndSeq = `${seqNo ? processTitle || "" : ""} ${
    seqNo ? `#${seqNo}:` : ""
  }`;

  if (type === "workflow") {
    heading = `${titleAndSeq}${name ? ` ${name}` : ""}`;
  }

  const handleConversation = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    _openConversationModal(id);
    handleClose();
    return false;
  };

  if (hideLink) {
    return (
      <StyledName canceled={canceled} title={heading}>
        <a>
          {heading}
          {type === "workflow" && (heading || "").length === 0 && noTitle ? (
            <>
              &nbsp;
              <i>No title</i>
            </>
          ) : null}
        </a>
      </StyledName>
    );
  }

  return (
    <StyledName canceled={canceled} title={heading}>
      <Link
        onClick={handleConversation}
        to={{
          type: atypes.SET_CURRENT_CHATROOM_REQUEST,
          payload: {
            id: address
          },
          meta: {
            query: {
              templateId
            }
          }
        }}
        title={heading}
      >
        {heading}
        {type === "workflow" && (heading || "").length === 0 && noTitle ? (
          <>
            &nbsp;
            <i>No title</i>
          </>
        ) : null}
      </Link>
    </StyledName>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => {
  return {
    address: getChatroomAddress(app, `${props.id}`),
    name: getChatroomTitle(app, `${props.id}`),
    type: getChatroomType(app, `${props.id}`),
    seqNo: getSequenceNo(app, `${props.id}`),
    processTitle: getProcessTitle(app, `${props.id}`),
    canceled: getCanceled(app, `${props.id}`),
    templateId: getTemplateId(app, props.id)
  };
};

Name.defaultProps = {
  hideLink: false
};

export default connect(mapStateToProps, {
  _openConversationModal: openConversationModal
})(Name);
