// @flow

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setWorkflowBuiderAttributes } from "src/actions/workflows";
import { Input } from "@chakra-ui/react";
import * as styles from "./styles";

type Props = {
  processTitle: string,
  setProcessTitle: Function
};

const Title = ({ processTitle, setProcessTitle }: Props) => {
  const { title } = useSelector(store => store.app.workflow.builderDialog);
  const dispatch = useDispatch();

  useEffect(() => {
    setProcessTitle(title);
  }, [title]);

  const handleChange = e => {
    setProcessTitle(e.target.value);
  };

  const handleBlur = () => {
    if (title !== processTitle) {
      dispatch(
        setWorkflowBuiderAttributes({
          title: processTitle.trim(),
          numberingScheme: `${processTitle.trim()} ###`
        })
      );
    }
  };

  const handleKeyPress = e => {
    if (e.key === "Escape" || e.keyCode === 27) {
      setProcessTitle(title);
    }
  };

  return (
    <Input
      onChange={handleChange}
      value={processTitle}
      onBlur={handleBlur}
      onKeyDown={handleKeyPress}
      autoFocus={!title}
      sx={styles.processTitleInput}
      placeholder="Untitled"
      size="lg"
      variant="unstyled"
    />
  );
};

export default Title;
