// @flow

import React from "react";

type Props = {
  color?: string,
  handleClick?: Function
};

const Send = ({ color, handleClick }: Props) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: "pointer" }}
      onClick={handleClick}
      onTouchEnd={handleClick}
      data-cy="sendMessage"
    >
      <path
        d="M5.004 9.503L13 15.5L16 0.5L1 6.5L3.002 8.001"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.13 11.848L5 15.5L5.004 9.503L9.5 6.5"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

Send.defaultProps = {
  color: "#888888",
  handleClick: () => null
};

export default Send;
